import React, { Component, Fragment } from "react";
import ReactDatatable from "@ashvin27/react-datatable";

class ListOfProducts extends Component {
  constructor(props) {
    super(props);
    this.columns = [
      {
        key: "group",
        text: "Grupa",
        sortable: true,
      },
      {
        key: "subGroup",
        text: "Podgrupa",
        sortable: true,
      },
      {
        key: "name",
        text: "Nazwa towaru",
        className: "name",
        sortable: true,
      },

      {
        key: "producer",
        text: "Producent",
        sortable: true,
      },
      {
        key: "code",
        text: "Kod towaru",
        sortable: true,
      },

      {
        key: "action",
        text: "Akcja",
        cell: (doc) => {
          return (
            <Fragment>
              <button
                className="btn btn-primary btn-sm"
                onClick={() => this.selectedDocument(doc, "edit")}
                style={{ marginRight: "5px", width: "4vw" }}
              >
                Edytuj
              </button>
              <button
                className="btn btn-danger btn-sm"
                style={{ width: "4vw" }}
                onClick={() => this.selectedDocument(doc, "delete")}
              >
                Usuń
              </button>
            </Fragment>
          );
        },
      },
    ];
    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50],
      show_filter: true,
      show_pagination: true,
      button: {
        excel: false,
        print: false,
      },
    };
    this.selectedDocument = this.selectedDocument.bind(this);
  }

  selectedDocument(document, mode) {
    if (mode === "edit") {
      this.props.toggleModal();
      this.props.handleCurrentDocument(document, mode);
    } else if (mode === "delete") {
      this.props.firebaseAction(document, mode);
    }
  }

  render() {
    return (
      <ReactDatatable
        config={this.config}
        records={this.props.documents}
        columns={this.columns}
      />
    );
  }
}

export default ListOfProducts;
