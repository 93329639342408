import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import {
  updateFilters,
  removeFilters,
  loadFilters,
  removeFilter,
} from "../../../services/filters/actions";
import { fetchProducts } from "../../../services/shelf/actions";
import Checkbox from "../../Checkbox";
import ButtonFilter from "../../ButtonFilter";
import ProducersDropdown from "../../DropDown/index";
import arrowFilter from "./../../../images/noun_Arrow.svg";

import "./style.scss";

//const availableSizes = ['Lakiery', 'Podłogi', 'Narzędzia', 'Farby', 'Okucia'];
//const availableSizes = ['XS', 'S', 'M', 'ML', 'L', 'XL', 'XXL'];
const groups = [
  "Lakiery, farby, oleje",
  "Kleje",
  "Silikony",
  "Bejce, patyny",
  "Uszczelki drzwiowe",
  "Narzędzia",
  "Materiały ścierne",
  "Okleiny, obłogi, lamele",
  "Obrzeża naturalne",
  "Elementy złączne",
  "Płyty",
  "Okucia budowlane",
  "Krzesła",
  "Podłogi",
];
const units = ["szt", "litr", "kg", "opak", "m2", "mb"];
const apply = ["Wewnętrzny", "Zewnętrzny"];
const subGroups = [];

function getSubGroup(group) {
  if (
    group === "Lakiery, farby, oleje" &&
    subGroups.includes("Lakier podkładowy") === false
  ) {
    subGroups.push(
      "Lakier podkładowy",
      "Utwardzacz",
      "Lakier nawierzchniowy",
      "Farba nawierzchniowa",
      "Farba podkładowa",
      "Dodatek",
      "Impregnaty",
      "Lazury",
      "Oleje"
    );
  } else if (
    group === "Kleje" &&
    subGroups.includes("Kleje montażowe") === false
  ) {
    subGroups.push(
      "Klasa wodoodporności D3",
      "Klasa wodoodporności D4",
      "Kleje poliuretanowe",
      "Kleje mocznikowe",
      "Kleje kontaktowe",
      "Kleje do parkietu",
      "Kleje montażowe"
    );
  } else if (group === "Silikony") {
  } else if (
    group === "Bejce, patyny" &&
    subGroups.includes("Nitro") === false
  ) {
    subGroups.push("Nitro", "Rozcieńczalnikowe", "Wodne", "Pastelowe");
  } else if (
    group === "Uszczelki drzwiowe" &&
    subGroups.includes("Uszczelki do drzwi zewnętrznych") === false
  ) {
    subGroups.push(
      "Uszczelki do drzwi wewnętrznych bez przylgi",
      "Uszczelki do drzwi wewnętrznych z przylgą",
      "Uszczelki do drzwi zewnętrznych"
    );
  } else if (
    group === "Narzędzia" &&
    subGroups.includes("Narzędzia do obróbki drewna") === false
  ) {
    subGroups.push(
      "Narzędzia do obróbki drewna",
      "Akcesoria lakiernicze",
      "Wałki do nanoszenia kleju",
      "Spajarki do forniru",
      "Sztyfty i gwoździe"
    );
  } else if (
    group === "Materiały ścierne" &&
    subGroups.includes("Krążki ścierne") === false
  ) {
    subGroups.push(
      "Krążki ścierne",
      "Papiery na sucho",
      "Taśmy lakiernicze",
      "Materiały polerskie",
      "Pasy bezkońcowe"
    );
  } else if (group === "Okleiny, obłogi, lamele") {
  } else if (group === "Obrzeża naturalne") {
  } else if (
    group === "Elementy złączne" &&
    subGroups.includes("Kołki rozporowe") === false
  ) {
    subGroups.push(
      "Wkręty do drewna",
      "Kołki rozporowe",
      "Kołki do montażu schodów",
      "Dible",
      "Lamelo"
    );
  } else if (
    group === "Płyty" &&
    subGroups.includes("Sklejka antypoślizgowa") === false
  ) {
    subGroups.push(
      "Płyty stolarskie surowe, fornirowane",
      "Sklejki wodoodporne od 4mm w wym. 2,5m x 1,25m",
      "Sklejki suchotrwałe od 3mm",
      "Sklejka antypoślizgowa",
      "Lite sklejki dębowe",
      "Klejonka sosnowa od 30mm do 60mm",
      "Klejonka dębowa 38mm",
      "Płyta kanałowa (otworowa)"
    );
  } else if (
    group === "Okucia budowlane" &&
    subGroups.includes("Zamki stolarskie") === false
  ) {
    subGroups.push(
      "Zawiasy do drzwi przylgowych",
      "Zawiasy do drzwi bezprzylgowych",
      "Zawiasy do drzwi z odwróconą przylgą",
      "Zamki stolarskie",
      "Zamki magnetyczne",
      "Klamki",
      "Osłonki na zawiasy",
      "Systemy drzwiowe",
      "Zamknięcia ewakuacyjne",
      "Zamykacze drzwiowe"
    );
  } else if (group === "Krzesła") {
  } else if (group === "Podłogi") {
  }
}

function removeSubGroups(group) {
  var filterSubGroups = [];
  if (group === "Lakiery, farby, oleje") {
    filterSubGroups.push(
      "Lakier podkładowy",
      "Utwardzacz",
      "Lakier nawierzchniowy",
      "Farba nawierzchniowa",
      "Farba podkładowa",
      "Dodatek",
      "Impregnaty",
      "Lazury",
      "Oleje"
    );
  } else if (group === "Kleje") {
    filterSubGroups.push(
      "Klasa wodoodporności D3",
      "Klasa wodoodporności D4",
      "Kleje poliuretanowe",
      "Kleje mocznikowe",
      "Kleje kontaktowe",
      "Kleje do parkietu",
      "Kleje montażowe"
    );
  } else if (group === "Silikony") {
  } else if (group === "Bejce, patyny") {
    filterSubGroups.push("Nitro", "Rozcieńczalnikowe", "Wodne", "Pastelowe");
  } else if (group === "Uszczelki drzwiowe") {
    filterSubGroups.push(
      "Uszczelki do drzwi wewnętrznych bez przylgi",
      "Uszczelki do drzwi wewnętrznych z przylgą",
      "Uszczelki do drzwi zewnętrznych"
    );
  } else if (group === "Narzędzia") {
    filterSubGroups.push(
      "Narzędzia do obróbki drewna",
      "Akcesoria lakiernicze",
      "Wałki do nanoszenia kleju",
      "Spajarki do forniru",
      "Sztyfty i gwoździe"
    );
  } else if (group === "Materiały ścierne") {
    filterSubGroups.push(
      "Krążki ścierne",
      "Papiery na sucho",
      "Taśmy lakiernicze",
      "Materiały polerskie",
      "Pasy bezkońcowe"
    );
  } else if (group === "Okleiny, obłogi, lamele") {
  } else if (group === "Obrzeża naturalne") {
  } else if (group === "Elementy złączne") {
    filterSubGroups.push(
      "Wkręty do drewna",
      "Kołki rozporowe",
      "Kołki do montażu schodów",
      "Dible",
      "Lamelo"
    );
  } else if (group === "Płyty") {
    filterSubGroups.push(
      "Płyty stolarskie surowe, fornirowane",
      "Sklejki wodoodporne od 4mm w wym. 2,5m x 1,25m",
      "Sklejki suchotrwałe od 3mm",
      "Sklejka antypoślizgowa",
      "Lite sklejki dębowe",
      "Klejonka sosnowa od 30mm do 60mm",
      "Klejonka dębowa 38mm",
      "Płyta kanałowa (otworowa)"
    );
  } else if (group === "Okucia budowlane") {
    filterSubGroups.push(
      "Zawiasy do drzwi przylgowych",
      "Zawiasy do drzwi bezprzylgowych",
      "Zawiasy do drzwi z odwróconą przylgą",
      "Zamki stolarskie",
      "Zamki magnetyczne",
      "Klamki",
      "Osłonki na zawiasy",
      "Systemy drzwiowe",
      "Zamknięcia ewakuacyjne",
      "Zamykacze drzwiowe"
    );
  } else if (group === "Krzesła") {
  } else if (group === "Podłogi") {
  }

  return filterSubGroups;
}

class Filter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isGroup: true,
      isSubGroup: false,
      isPrice: false,
      isProducer: false,
      isPurpose: false,
      isUnit: false,
      priceFrom: 0,
      priceTo: 0,
      isApply: false,
    };
    this.handlePriceChange = this.handlePriceChange.bind(this);
    this.removeFilters = this.removeFilters.bind(this);
    this.changeArrowPos = this.changeArrowPos.bind(this);
    this.getGroupQuantity = this.getGroupQuantity.bind(this);
    //this.getSubGroup = this.getSubGroup.bind(this);
  }
  static propTypes = {
    updateFilters: PropTypes.func.isRequired,
    filters: PropTypes.array.isRequired,
    //   handleFilter: PropTypes.func.isRequired,
    // removeFilters: PropTypes.func.isRequired,
    //  removeFilter: PropTypes.func.isRequired,
    //loadFilters: PropTypes.func.isRequired,
    fetchProducts: PropTypes.func.isRequired,
    //   newFilter: PropTypes.array.isRequired,
    sort: PropTypes.string,
    priceFrom: PropTypes.number,
    filterQuantity: PropTypes.number,
    productsList: PropTypes.array.isRequired,
    productsListUF: PropTypes.array.isRequired,
  };

  getPriceVal = (objName) => {
    const { filters } = this.props;
    var price = 0.0;
    var priceFil = filters.find((f) => f[0] === objName);
    if (priceFil !== undefined) {
      price = parseFloat(priceFil[1]);
    }
    return price;
  };

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {
    if (nextProps.newFilter !== this.props.newFilter) {
      //this.addFilter(nextProps.newFilter);
    }
    if (nextProps.filterToRemove !== this.props.filterToRemove) {
      this.removeFilter(nextProps.filterToRemove);
    }
  }

  getGroupQuantity(group) {
    //where f[0] is a group for which we want to get sum
    let filteredItems = 0;

    //const arrFiltered = this.props.productsListUF.filter(f => f.group.find(size => size === group ))
    const arrFiltered = this.props.productsListUF.filter(
      (f) => f.group === group
    );

    if (!!arrFiltered) {
      filteredItems = arrFiltered.length;
    }

    return filteredItems;
  }

  handleFetchProducts = (
    filters = this.props.filters,
    sort = this.props.sort,
    filterQuantity = this.props.filterQuantity,
    productsMariusz = this.props.productsListUF
  ) => {
    this.setState({ isLoading: true });
    this.props.fetchProducts(
      filters,
      sort,
      () => {
        this.setState({ isLoading: false });
      },
      filterQuantity,
      1,
      productsMariusz
    );
  };

  handleObjState = (name) => {
    const objShow = !this.state[name];
    this.setState({ [name]: objShow });
  };

  removeFilter = (objName) => {
    const { filters, updateFilters } = this.props;

    var index = filters.findIndex((f) => f[0] === objName);
    if (index >= 0) {
      filters.splice(index, 1);
      updateFilters(filters);
    }

    var filterSubGroups = removeSubGroups(objName);

    for (let i = 0; i < filterSubGroups.length; i++) {
      objName = filterSubGroups[i];
      index = filters.findIndex((f) => f[0] === objName);
      if (index >= 0) {
        filters.splice(index, 1);
      }
      var subGroup = true;
    }
    if (subGroup) {
      updateFilters(filters);
    }
  };

  handleFilter = (objName, filterVal, doFetch) => {
    const { filters } = this.props;

    if (filterVal === false || filterVal === 0) {
      const index = filters.findIndex((f) => f[0] === objName);
      if (index >= 0) {
        filters.splice(index, 1);

        this.removeFilter(objName);
      }
    } else {
      filters.push([objName, filterVal]);
    }
    this.props.updateFilters(filters);
    if (doFetch) {
      this.handleFetchProducts();
    }
  };
  //objName, filterVal
  handlePriceChange(event) {
    // this.addPriceToFilters();
    if (event.target.value !== 0) {
      this.setState({ [event.target.name]: event.target.value });
    }
  }

  addPriceToFilters = () => {
    this.removeFilter("priceFrom");
    this.removeFilter("priceTo");
    this.handleFilter("priceFrom", this.state.priceFrom, false);
    this.handleFilter("priceTo", this.state.priceTo, true);
  };

  removeFilters() {
    const { filters } = this.props;
    filters.length = 0;
    removeFilters(filters);

    this.props.updateFilters(filters);
    this.handleFetchProducts();
    //closing respective sections
    this.setState({
      isPrice: false,
      isGroup: true,
      isProducer: false,
      isUnit: false,
      isApply: false,
      isSubGroup: false,
    });
    this.createCheckboxes();
    // console.log("stan filtr", this.props.filters);
  }

  createCheckbox = (label) => {
    const isFiltered = Array.isArray(
      this.props.filters.find((filt) => filt[0] === label)
    );
    // console.log("stan filtra przed", label, isFiltered);
    return (
      <Checkbox
        classes="filters-available-size"
        objName={label}
        handleFilter={this.handleFilter}
        key={label}
        isFiltered={isFiltered}
        filters={this.props.filters}
        filterItems={this.getGroupQuantity(label)}
      />
    );
  };

  createBtnFilter = (label) => {
    const isFiltered = Array.isArray(
      this.props.filters.find((filt) => filt[0] === label)
    );

    return (
      <ButtonFilter
        objName={label}
        handleFilter={this.handleFilter}
        key={label}
        isFiltered={isFiltered}
      />
    );
  };

  createCheckboxes = () => groups.map(this.createCheckbox);
  createBtns = () => units.map(this.createBtnFilter);
  createSubCheckboxes = (filters) => {
    subGroups.length = 0;
    filters.map((f) => getSubGroup(f[0]));
    return subGroups.map(this.createCheckbox);
  };
  createApplyCheckboxes = () => apply.map(this.createCheckbox);

  //changing classname for images due to arrow position
  changeArrowPos(objFilterName) {
    return this.state[objFilterName] === false ? (
      <img
        className="filter-arrow-down"
        onClick={() => this.handleObjState(objFilterName)}
        src={arrowFilter}
      />
    ) : (
      <img
        className="filter-arrow-up"
        onClick={() => this.handleObjState(objFilterName)}
        src={arrowFilter}
      />
    );
  }

  render() {
    return (
      <div className="filters">
        <h4 className="title">Filtry:</h4>
        <button className="filter-button" onClick={() => this.removeFilters()}>
          Wyczysc filtry
        </button>
        <div>
          <div className="filter-div">
            <button
              className="filter-btn"
              onClick={() => this.handleObjState("isGroup")}
            >
              Rodzaj produktu
            </button>

            {/* {this.changeArrowPos("isGroup")}
            {this.state.isGroup && ( */}
            <div className="filter-div-items">{this.createCheckboxes()}</div>
            {/* )} */}
          </div>
          {
            <div className="filter-div">
              <button
                className="filter-btn"
                onClick={() => this.handleObjState("isSubGroup")}
              >
                Podgrupa produktu
              </button>
              {this.changeArrowPos("isSubGroup")}
              {this.state.isSubGroup && (
                <div className="filter-div-items">
                  {this.createSubCheckboxes(this.props.filters)}
                </div>
              )}
            </div>
          }

          <div className="filter-div-price">
            <button
              className="filter-btn"
              name="isPrice"
              onClick={() => this.handleObjState("isPrice")}
            >
              Cena
            </button>
            {this.changeArrowPos("isPrice")}
            {this.state.isPrice && (
              <div className="filter-div-price-btns">
                <div>
                  Cena od
                  <input
                    defaultValue={this.getPriceVal("priceFrom")}
                    name="priceFrom"
                    type="number"
                    min="0.00"
                    max="25000.00"
                    step="50.0"
                    onChange={this.handlePriceChange}
                  />
                </div>
                <div>
                  Cena do
                  <input
                    defaultValue={this.getPriceVal("priceTo")}
                    name="priceTo"
                    type="number"
                    min="0.00"
                    max="25000.00"
                    step="50.0"
                    onChange={this.handlePriceChange}
                  />
                </div>
                <button
                  className="filter-button"
                  onClick={this.addPriceToFilters}
                >
                  Zastosuj
                </button>
              </div>
            )}
          </div>
          <div className="filter-div">
            <button
              className="filter-btn"
              onClick={() => this.handleObjState("isProducer")}
            >
              Producent
            </button>
            {this.changeArrowPos("isProducer")}
            {this.state.isProducer && (
              <div className="filter-producer-dd">
                {
                  <ProducersDropdown
                    handleFilter={this.handleFilter}
                    filters={this.props.filters}
                  />
                }
              </div>
            )}
          </div>
          <div className="filter-div">
            <button
              className="filter-btn"
              onClick={() => this.handleObjState("isUnit")}
            >
              Pojemność
            </button>
            {this.changeArrowPos("isUnit")}
            {this.state.isUnit && (
              <div className="filter-div-items">{this.createBtns()}</div>
            )}
          </div>

          <div className="filter-div">
            <button
              className="filter-btn"
              onClick={() => this.handleObjState("isApply")}
            >
              Zastosowanie
            </button>
            {this.changeArrowPos("isApply")}
            {this.state.isApply && (
              <div className="filter-div-items">
                {this.createApplyCheckboxes()}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  newFilter: state.filterObj.filterToAdd,
  filterToRemove: state.filterObj.filterToRemove,
  sort: state.sort.type,
  products: state.shelf.products,
  filters: state.filterObj.filters,
  filterQuantity: state.filterQuantity.type,
  productsList: state.apiProducts.productsList,
  productsListUF: state.apiProductsUF.productsListUF,
});

export default connect(mapStateToProps, {
  updateFilters,
  removeFilter,
  fetchProducts,
})(Filter);
