import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import Navbar from "./Navbar/Navbar";
import Navbar_shop from "./Navbar/Navbar-shop";
import IntroPage from "./IntroPage/IntroPage";
import MainPage from "./MainPage/MainPage";
import ContactPage from "./ContactPage/ContactPage";
import History from "./HistoryPage/HistoryPage";
import Shop_Demo from "./ShopPages/Main";
import CategoriesPage from "./CategoriesPage/CategoriesPage";
import CategoriesDetailsPage from "./CategoriesPage/CategoriesDetailsPage";
import Login from "./admin/login-page";
import ShopLogin from "./ShopPages/Login/Login";
import PassRemainder from "./ShopPages/PassRemainder/PassRemainder";
import Register from "./ShopPages/Register/Register";
import Cart from "./Cart";
import ShippingPt1 from "./ShopPages/Shipping/Pt1";
import ProductDetail from "../src/pages/ProductDetail";
import AccountDetails from "./ShopPages/Account/Account";
import { useLocation } from "react-router-dom";

function Router() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <React.Fragment>
      <Switch>
        <Route
          path={[
            "/Sklep/Produkt",
            "/Sklep/Rejestracja",
            "/Sklep/PrzypomnijHaslo",
            "/Sklep/Konto",
            "/Sklep/Wysylka",
            "/Sklep/Logowanie",
            "/Sklep/Glowna",
            "/Sklep-Demo",
          ]}
          component={Navbar_shop}
        />
        <Route path="/" component={Navbar} />
      </Switch>
      <Switch>
        <Route path="/" exact component={IntroPage} />
        <Route path="/Kategorie" component={CategoriesPage} />
        <Route path="/Kategoria/:name" component={CategoriesDetailsPage} />
        <Route path="/StronaGlowna" component={MainPage} />
        <Route path="/Kontakt" component={ContactPage} />
        <Route path="/Historia" component={History} />
        <Route path="/Sklep/Rejestracja" component={Register} />
        <Route path="/Sklep/PrzypomnijHaslo" component={PassRemainder} />
        <Route path="/Sklep/Konto" component={AccountDetails} />
        <Route path="/Sklep/Wysylka" component={ShippingPt1} />
        <Route path="/Sklep/Logowanie" component={ShopLogin} />
        <Route path="/Sklep/Glowna" component={Cart} />
        <Route path="/Sklep/Produkt" component={ProductDetail} />
        <Route path="/Sklep" component={Shop_Demo} />
        <Route path="/Admin" component={Login} />
      </Switch>
    </React.Fragment>
  );
}

export default Router;
