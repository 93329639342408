import React from "react";
// import iMoveToShop from "../../images/btnZrobZakupy.png";
import parImg from "../../images/newlineText.png";
import styles from "./CategoriesDetails.module.scss";
import { withRouter } from "react-router-dom";

export default withRouter(function EleventhCategory(props) {
  return (
    <div>
      <div className={styles.text_box_obrzeza}>
        <h1 className={styles.text_box_title}>Obrzeża naturalne</h1>
        <h2 className={styles.text_box_subtitle}>Zakres sprzedaży:</h2>
        {window.innerWidth >= 651 ? (
          <h3 className={styles.text_box_subtitle_second}>
            Obrzeża naturalne z klejem gr. 0,6mm – szer. 22mm ÷ 147mm
          </h3>
        ) : (
          <h3 className={styles.text_box_subtitle_second}>
            Obrzeża naturalne z klejem gr. 0,6mm –{" "}
            <div> szer. 22mm ÷ 147mm</div>
          </h3>
        )}
        {window.innerWidth >= 651 ? (
          <div className={styles.text_box_item}>
            <p>
              <img src={parImg} className={styles.text_img} alt="img" />
              dąb
            </p>

            <p>
              <img src={parImg} className={styles.text_img} alt="img" />
              buk
            </p>
            <p>
              <img src={parImg} className={styles.text_img} alt="img" />
              jesion
            </p>
            <p>
              <img src={parImg} className={styles.text_img} alt="img" />
              olcha
            </p>
            <p>
              <img src={parImg} className={styles.text_img} alt="img" />
              sosna
            </p>
            <p>
              <img src={parImg} className={styles.text_img} alt="img" />
              orzech
            </p>
          </div>
        ) : (
          <div className={styles.text_box_item}>
            <p>
              <img src={parImg} className={styles.text_img} alt="img" />
              dąb, buk, jesion, olcha, sosna, orzech
            </p>
          </div>
        )}
        {window.innerWidth >= 651 ? (
          <h2 className={styles.text_box_subtitle_second}>
            Obrzeża warstwowo klejone gr. 1,5mm ÷ 2,0mm – szer. 22mm{" "}
          </h2>
        ) : (
          <h2 className={styles.text_box_subtitle_second}>
            Obrzeża warstwowo klejone gr. 1,5mm{" "}
            <div>÷ 2,0mm – szer. 22mm ÷ 42mm</div>
          </h2>
        )}

        {window.innerWidth >= 651 ? (
          <div className={styles.text_box_item}>
            <p>
              <img src={parImg} className={styles.text_img} alt="img" />
              dąb
            </p>
            <p>
              <img src={parImg} className={styles.text_img} alt="img" />
              buk
            </p>
            <p>
              <img src={parImg} className={styles.text_img} alt="img" />
              jesion
            </p>
            <p>
              <img src={parImg} className={styles.text_img} alt="img" />
              brzoza
            </p>
          </div>
        ) : (
          <div className={styles.text_box_item}>
            <p>
              <img src={parImg} className={styles.text_img} alt="img" />
              dąb, buk, jesion, brzoza
            </p>
          </div>
        )}
        {props.shpButton}
        {/* <div className={styles.moveTo_sec}>
          <img
            className={styles.moveTo_img}
            src={iMoveToShop}
            alt="img"
            onClick={() => nextPath("/shop")}
          ></img>
        </div> */}
      </div>
    </div>
  );
});
