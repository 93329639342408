import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import Router from "./Router";
import Root from "./Root";

//style
import "bootstrap/dist/css/bootstrap.min.css";
// import "react-widgets/dist/css/react-widgets.css";
import "./index.scss";

ReactDOM.render(
  <Root>
    <BrowserRouter>
      <Router />
    </BrowserRouter>
  </Root>,
  document.getElementById("root")
);

serviceWorker.unregister();
