import React from "react";
import parImg from "../../images/newlineText.png";
// import iMoveToShop from "../../images/btnZrobZakupy.png";
import styles from "./CategoriesDetails.module.scss";
import { withRouter } from "react-router-dom";

export default withRouter(function FourthCategory(props) {
  return (
    <div>
      <div className={styles.text_box}>
        <h1 className={styles.text_box_title}>Płyty</h1>
        <h2 className={styles.text_box_subtitle}>Zakres sprzedaży:</h2>

        <div className={styles.text_box_item}>
          <p>
            <img src={parImg} className={styles.text_img} alt="" />
            Płyty stolarskie surowe, fornirowane
          </p>
          {window.innerWidth >= 651 ? (
            <p>
              <img src={parImg} className={styles.text_img} alt="" />
              Sklejki wodoodporne od 4mm w wym. 2,5m x 1,25m
            </p>
          ) : (
            <p>
              <img src={parImg} className={styles.text_img} alt="" />
              Sklejki wodoodporne od 4mm w wym. 2,5m x
            </p>
          )}{" "}
          {window.innerWidth <= 651 ? (
            <p className={styles.text_space}> 1,25m</p>
          ) : null}
          <p>
            <img src={parImg} className={styles.text_img} alt="" />
            Sklejki suchotrwałe od 3mm
          </p>
          <p>
            <img src={parImg} className={styles.text_img} alt="" />
            Sklejka antypoślizgowa
          </p>
          <p>
            <img src={parImg} className={styles.text_img} alt="" />
            Lite sklejki dębowe
          </p>
          <p>
            <img src={parImg} className={styles.text_img} alt="" />
            Klejonka sosnowa od 30mm do 60mm
          </p>
          <p>
            <img src={parImg} className={styles.text_img} alt="" />
            Klejonka dębowa 38mm
          </p>
          <p>
            <img src={parImg} className={styles.text_img} alt="" />
            Płyta kanałowa (otworowa)
          </p>
        </div>
        {props.shpButton}
        {/* <div className={styles.moveTo_sec}>
          <img
            className={styles.moveTo_img}
            src={iMoveToShop}
            alt=""
            onClick={() => nextPath("/shop")}
          ></img>
        </div> */}
        {window.innerWidth >= 651 ? (
          <footer className={styles.entp_imgs}>
            <div></div>
          </footer>
        ) : null}
      </div>
    </div>
  );
});
