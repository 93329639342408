import React from "react";
import styles from "./HistoryPage.module.css";
import CssModules from "react-css-modules";

const Story1 = () => {
  return (
    <div styleName="story1">
      <h4 styleName="story1-year">2004</h4>
      <h4 styleName="story1-title">
        Rozpoczęcie działalności w branży stolarskiej
      </h4>
      <p>
        W 2004 roku pojawił się pomysł na stworzenie firmy, która będzie
        oferowała produkty dla branży stolarskiej. Początkowo nasza działalność
        obejmowała niewielką ilość klientów, która z czasem powiększała się.
        Chcąc być identyfikowalny na rynku opracowaliśmy nasze logo, które do
        niedawna stanowiło wizerunek naszej firmy. Tak zaczęła się nasza
        przygoda z produktami do drewna.
      </p>
    </div>
  );
};

export default CssModules(Story1, styles, { allowMultiple: true });
