import React from "react";
import CategoryCard from "./CategoryCard";
import styles from "./CategoryCard.module.css";

export function GetCards() {
  return Cards;
}

const Cards = [
  {
    id: 1,
    name: "Lakiery, farby, oleje",
    className: "",
    src: "card_lakiery_farby_oleje.png",
    detailSrc: "Lakier.jpg",
    detailNo: 1,
    pathname: "/Kategoria/Lakiery, farby, oleje",
    srcMobile: "mob_lakiery_farby_oleje.png",
  },

  {
    id: 2,
    name: "Kleje",
    className: "",
    src: "card_kleje.png",
    detailSrc: "Kleje.jpg",
    detailNo: 2,
    pathname: "/Kategoria/Kleje",
    srcMobile: "mob_kleje.png",
  },
  {
    id: 3,
    name: "Silikony",
    className: "",
    src: "card_silikony.png",
    detailSrc: "Silikony.jpg",
    detailNo: 3,
    pathname: "/Kategoria/Silikony",
    srcMobile: "mob_silikony.png",
  },
  {
    id: 4,
    name: "Bejce, patyny",
    className: "",
    src: "card_bejce_patyny.png",
    detailSrc: "Bejca.jpg",
    detailNo: 4,
    pathname: "/Kategoria/Bejce, patyny",
    srcMobile: "mob_bejce.png",
  },
  {
    id: 5,
    name: "Uszczelki drzwiowe",
    className: "",
    src: "card_uszczelki_drzwiowe.png",
    detailSrc: "Uszczelki-drzwiowe.jpg",
    detailNo: 5,
    pathname: "/Kategoria/Uszczelki drzwiowe",
    srcMobile: "mob_uszczelki.png",
  },

  {
    id: 6,
    name: "Narzędzia",
    className: "",
    src: "card_narzędzia.png",
    detailSrc: "Narzędzia.jpg",
    detailNo: 6,
    pathname: "/Kategoria/Narzędzia",
    srcMobile: "mob_narzedzia.png",
  },
  {
    id: 7,
    name: "Materiały ścierne",
    className: "",
    src: "card_materiały_ścierne.png",
    detailSrc: "Materialy_scierne.jpg",
    detailNo: 7,
    pathname: "/Kategoria/Materiały ścierne",
    srcMobile: "mob_materialy_sc.png",
  },
  {
    id: 8,
    name: "Okleiny, obłogi, lamele",
    className: "",
    src: "card_okleiny.png",
    detailSrc: "Okleiny.jpg",
    detailNo: 8,
    pathname: "/Kategoria/Okleiny, obłogi, lamele",
    srcMobile: "mob_okleiny.png",
  },
  {
    id: 9,
    name: "Obrzeża naturalne",
    className: "",
    src: "card_obrz_nat.png",
    detailSrc: "Obrzeza_naturalne.jpg",
    detailNo: 9,
    pathname: "/Kategoria/Obrzeża naturalne",
    srcMobile: "mob_obrzeza.png",
  },
  {
    id: 10,
    name: "Elementy złączne",
    className: "",
    src: "card_elementy_zl.png",
    detailSrc: "elementy zlaczne.jpg",
    detailNo: 10,
    pathname: "/Kategoria/Elementy złączne",
    srcMobile: "mob_elementy.png",
  },
  {
    id: 11,
    name: "Płyty",
    className: "",
    src: "card_plyty.png",
    detailSrc: "plyty.jpg",
    detailNo: 11,
    pathname: "/Kategoria/Płyty",
    srcMobile: "mob_plyty.png",
  },

  {
    id: 12,
    name: "Okucia budowlane",
    className: "",
    src: "card_okucia.png",
    detailSrc: "okucia.jpg",
    detailNo: 12,
    pathname: "/Kategoria/Okucia budowlane",
    srcMobile: "mob_okucia.png",
  },

  {
    id: 13,
    name: "Krzesła",
    className: "",
    src: "card_krzesla.png",
    detailSrc: "Krzeslo.jpg",
    detailNo: 13,
    pathname: "/Kategoria/Krzesła",
    srcMobile: "mob_krzesla.png",
  },
  {
    id: 14,
    name: "Podłogi",
    className: "",
    src: "card_podlogi.png",
    detailSrc: "Podlogi.jpg",
    detailNo: 14,
    pathname: "/Kategoria/Podłogi",
    srcMobile: "mob_podlogi.png",
  },
  // {
  //   id: 15,
  //   name: "Sklep",
  //   className: "",
  //   src: "card_sklep.png",
  //   detailSrc: "Sklep.png",
  //   detailNo: 15,
  //   pathname: "/contact",
  //   srcMobile: "Sklep.png",
  // },
];

function CardListing(props) {
  return (
    <div id="cards" className={styles.cards_section}>
      {Cards.map((card) => (
        <CategoryCard
          key={card.id}
          pathname={card.pathname}
          name={card.name}
          className={card.className}
          src={card.src}
          detailSrc={card.detailSrc}
          id={card.id}
          detailNo={card.detailNo}
          cards={Cards}
        />
      ))}
    </div>
  );
}

class CategoriesContainer extends React.Component {
  render() {
    return <div>{CardListing()}</div>;
  }
}

export default CategoriesContainer;
