import React from "react";
import styles from "./Main.module.css";
import CssModules from "react-css-modules";
import { withRouter } from "react-router-dom";

const RedirectSection = (props) => {
  const nextPath = (path) => {
    props.history.push(path);
  };
  return (
    <div styleName="redirect-section">
      <div styleName="redirect-section-about">
        <h2>O nas</h2>
        <button onClick={() => nextPath("/Historia")}>ZAPRASZAMY</button>
        <img src="./ShopPics/o_nas.jpg" alt="" />
      </div>
      <div styleName="redirect-section-contact">
        <h2>Masz pytania?</h2>
        <button onClick={() => nextPath("/Kontakt")}>SKONTAKTUJ SIĘ</button>
        <img src="./ShopPics/masz_pytania.jpg" alt="" />
      </div>
    </div>
  );
};

export default withRouter(
  CssModules(RedirectSection, styles, {
    allowMultiple: true,
  })
);
