import { UPDATE_CART } from "./actionTypes";

export const updateCart = (cartProducts) => (dispatch) => {
  let productQuantity = 0;
  let totalPrice = 0;

  productQuantity = cartProducts.reduce((sum, p) => {
    sum += p.quantity;
    return sum;
  }, 0);

  totalPrice = cartProducts.reduce((sum, p) => {
    sum += p.price * p.quantity;
    return sum;
  }, 0);

  let installments = cartProducts.reduce((greater, p) => {
    greater = p.installments > greater ? p.installments : greater;
    return greater;
  }, 0);

  let cartTotal = {
    productQuantity,
    installments,
    totalPrice,
    currencyId: "PLN",
    currencyFormat: "zł",
  };
  dispatch({
    type: UPDATE_CART,
    payload: cartTotal,
  });
};
