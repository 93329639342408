export const groups = [
  "Lakiery, farby, oleje",
  "Kleje",
  "Silikony",
  "Bejce, patyny",
  "Uszczelki drzwiowe",
  "Narzędzia",
  "Materiały ścierne",
  "Okleiny, obłogi, lamele",
  "Obrzeża naturalne",
  "Elementy złączne",
  "Płyty",
  "Okucia budowlane",
  "Krzesła",
  "Podłogi",
];
export const units = ["szt", "litr", "kg", "opak", "m2", "mb"];
export const apply = ["Wewnętrzny", "Zewnętrzny"];
export const subGroups = [
  "Lakier podkładowy",
  "Utwardzacz",
  "Lakier nawierzchniowy",
  "Farba nawierzchniowa",
  "Farba podkładowa",
  "Dodatek",
  "Impregnaty",
  "Lazury",
  "Oleje",
  "Klasa wodoodporności D3",
  "Klasa wodoodporności D4",
  "Kleje poliuretanowe",
  "Kleje mocznikowe",
  "Kleje kontaktowe",
  "Kleje do parkietu",
  "Kleje montażowe",

  "Nitro",
  "Rozcieńczalnikowe",
  "Wodne",
  "Pastelowe",
  "Uszczelki do drzwi wewnętrznych bez przylgi",
  "Uszczelki do drzwi wewnętrznych z przylgą",
  "Uszczelki do drzwi zewnętrznych",
  "Narzędzia do obróbki drewna",
  "Akcesoria lakiernicze",
  "Wałki do nanoszenia kleju",
  "Spajarki do forniru",
  "Sztyfty i gwoździe",
  "Krążki ścierne",
  "Papiery na sucho",
  "Taśmy lakiernicze",
  "Materiały polerskie",
  "Pasy bezkońcowe",
  "Wkręty do drewna",
  "Kołki rozporowe",
  "Kołki do montażu schodów",
  "Dible",
  "Lamelo",
  "Płyty stolarskie surowe, fornirowane",
  "Sklejki wodoodporne od 4mm w wym. 2,5m x 1,25m",
  "Sklejki suchotrwałe od 3mm",
  "Sklejka antypoślizgowa",
  "Lite sklejki dębowe",
  "Klejonka sosnowa od 30mm do 60mm",
  "Klejonka dębowa 38mm",
  "Płyta kanałowa (otworowa)",
  "Zawiasy do drzwi przylgowych",
  "Zawiasy do drzwi bezprzylgowych",
  "Zawiasy do drzwi z odwróconą przylgą",
  "Zamki stolarskie",
  "Zamki magnetyczne",
  "Klamki",
  "Osłonki na zawiasy",
  "Systemy drzwiowe",
  "Zamknięcia ewakuacyjne",
  "Zamykacze drzwiowe",
];
export const producers = [
  "Hesse Lignal",
  "Sivam",
  "Sikkens",
  "Alcea",
  "Libra",
  "Lakma",
  "Sopur",
  "Deventer",
  "AiB",
  "Schlegel",
  "Ottensten",
  "3M",
  "Kuper",
  "CMT",
  "Kovax",
  "SIA",
  "Indasa",
  "Wkręt-met",
  "Gerda",
  "Simonswerk",
  "Koblenz",
  "Otlav",
];

export const formatPrice = (x, currency) => {
  // console.log(x);
  switch (currency) {
    case "BRL":
      return x.toFixed(2).replace(".", ",");
    default:
      try {
        return x.toFixed(2);
      } catch {
        return "0.00";
      }
  }
};

export const prefixToAdd =
  "https://firebasestorage.googleapis.com/v0/b/slaskie-centrum-stolarskie.appspot.com/o/";

export const productsAPI =
  "https://slaskie-centrum-stolarskie.firebaseio.com/products.json";
