import React from "react";
// import iMoveToShop from "../../images/btnZrobZakupy.png";
import parImg from "../../images/newlineText.png";
import styles from "./CategoriesDetails.module.scss";
import { withRouter } from "react-router-dom";

export default withRouter(function TwelvethCategory(props) {
  return (
    <div>
      <div className={styles.text_box}>
        <h1 className={styles.text_box_title}>Podłogi</h1>
        <h2 className={styles.text_box_subtitle}>Zakres sprzedaży:</h2>

        <div className={styles.text_box_item}>
          <p>
            <img src={parImg} className={styles.text_img} alt="img" />
            Deska trójwarstwowa dębowa
          </p>
          <p>
            <img src={parImg} className={styles.text_img} alt="img" />
            Deska dwuwarstwowa dębowa
          </p>
          <p>
            <img src={parImg} className={styles.text_img} alt="img" />
            Deska trójwarstwowa jesionowa
          </p>
          <p>
            <img src={parImg} className={styles.text_img} alt="img" />
            Klejowe systemy do montażu desek podłogowych
          </p>
          <p>
            <img src={parImg} className={styles.text_img} alt="img" />
            Oleje parkietowe
          </p>
          <p>
            <img src={parImg} className={styles.text_img} alt="img" />
            Środki do pielęgnacji podłóg
          </p>
        </div>
        {props.shpButton}
        {/* <div className={styles.moveTo_sec}>
          <img
            className={styles.moveTo_img}
            src={iMoveToShop}
            alt=""
            onClick={() => nextPath("/shop")}
          ></img>
        </div> */}
      </div>
    </div>
  );
});
