import React, { useState } from "react";
import styles from "./Navbar.module.css";
import CssModules from "react-css-modules";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import logo from "../images/logo.svg";
import Button from "../Animations/Button";

const Navbar = (props) => {
  const nextPath = (path) => {
    props.history.push(path);
  };

  const [show, setHamburger] = useState("");

  const handleHamburgerClick = () => {
    if (show === "" || show === "close") {
      setHamburger("open");
    } else {
      setHamburger("close");
    }
  };

  const hideBurger = () => {
    document.querySelector("[class*='burger-squeeze']").click();
  };

  return (
    <div styleName="all">
      <div styleName="container">
        <div styleName="logo_phone">
          <div onClick={() => nextPath("/")} styleName="logo">
            <Button
              whileHover={1.1}
              whileTap={0.8}
              src={logo}
              id="input"
              type="image"
            />
          </div>
        </div>
        <a href="tel:887499299">
          <div styleName="phone">
            <div styleName="center">
              <button styleName="btn">
                <svg width="180px" height="30px" viewBox="0 0 180 30">
                  <polyline points="179,1 179,29 1,29 1,1 179,1" />
                  <polyline points="179,1 179,29 1,29 1,1 179,1" />
                </svg>
                <span style={{ color: "#e97a41" }}>
                  <strong>Zapytaj o produkt: </strong>
                </span>
                <span>
                  <strong>887 499 299</strong>
                </span>
              </button>
            </div>
          </div>
        </a>
        <div styleName="navbar">
          <div styleName={`navbar_links ${show}`}>
            <Link onClick={() => hideBurger()} to="/StronaGlowna">
              Strona główna
            </Link>
            <Link
              onClick={() => hideBurger()}
              to="/Kategoria/Lakiery,%20farby,%20oleje"
            >
              Asortyment
            </Link>

            <Link onClick={() => hideBurger()} to="/Sklep">
              Sklep internetowy
            </Link>
            <Link onClick={() => hideBurger()} to="/Historia">
              O nas
            </Link>
            <Link onClick={() => hideBurger()} to="/Kontakt">
              Kontakt
            </Link>
          </div>
        </div>
        <div styleName="my_burger">
          <div
            onClick={handleHamburgerClick}
            styleName={`burger burger-squeeze ${show}`}
          >
            <div styleName="burger-lines"></div>
          </div>
        </div>
      </div>
      <div styleName={`navbar_links__overlay ${show}`}></div>
    </div>
  );
};

export default withRouter(CssModules(Navbar, styles, { allowMultiple: true }));
