import React, { useState, useEffect } from "react";
import styles from "./Pt1.module.css";
import CssModules from "react-css-modules";
import { withRouter } from "react-router-dom";
import firebase from "../../services/firebase/firebase";
import axios from "axios";
import shipping from "../../images/shipping-active.svg";
import payment from "../../images/payment-not-active.svg";
import arrowReturn from "../../images/noun_Arrow.svg";
import ShippingPt2 from "./Pt2";

const Pt1 = (props) => {
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [street, setStreet] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [company, setCompany] = useState("");
  const [city, setCity] = useState("");
  const [phone, setPhone] = useState("");
  const [nip, setNip] = useState("");
  const [userDataResponse, setUserDataResponse] = useState("");
  const [email, setEmail] = useState("");
  const [selectedShipment, setSelectedShipment] = useState("1");
  const [summaryScreen, setSummaryScreen] = useState(false);
  const [shippingProps, setShippingProps] = useState({});

  const [activeShipment, setActiveShipment] = useState({
    one: "active",
    two: "",
    three: "",
  });

  function goToLastScreen() {
    const user = firebase.auth().currentUser;
    if (user) {
      setShippingProps({
        selectedShipment: selectedShipment,
        firstname: firstname === "" ? userDataResponse.firstname : firstname,
        lastname: lastname === "" ? userDataResponse.lastname : lastname,
        phone: phone === "" ? userDataResponse.phone : phone,
        street: street === "" ? userDataResponse.street : street,
        postalCode:
          postalCode === "" ? userDataResponse.postalCode : postalCode,
        city: city === "" ? userDataResponse.city : city,
        company: company === "" ? userDataResponse.company : company,
        nip: nip === "" ? userDataResponse.nip : nip,
        email: email === "" ? userDataResponse.email : email,
      });
      setSummaryScreen(true);
    } else {
      alert("Załóż konto i zaloguj się aby kontynuować");
    }
  }

  useEffect(() => {
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        setEmail(user.email);
        axios
          .get(`https://slaskie-centrum-stolarskie.firebaseio.com/users.json`)
          .then((res) => {
            Object.keys(res.data).map((ele) => {
              if (user.email === res.data[ele][`email`]) {
                var myNIP = res.data[ele][`nip`];
                var outString =
                  myNIP +
                  user.email.replace(
                    /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
                    ""
                  );
                setNip(myNIP);
                setUserDataResponse(res.data[outString]);
              }
            });
          });
      }
    });
  }, []);

  const handleClick = (number) => {
    setSelectedShipment(number);
    if (number === "1") {
      setActiveShipment({
        one: "active",
        two: "",
        three: "",
      });
    } else if (number === "2") {
      setActiveShipment({
        one: "",
        two: "active",
        three: "",
      });
    } else if (number === "3") {
      setActiveShipment({
        one: "",
        two: "",
        three: "active",
      });
    }
  };

  function returnFromPt2() {
    setSummaryScreen(false);
  }

  return (
    <div>
      {summaryScreen === false ? (
        <div styleName="shipping">
          <div styleName="shipping-top">
            <h3>Adres dostawy i rodzaj wysyłki</h3>
            <div styleName="shipping-icons">
              <img src={shipping} alt="" />
              <div styleName="horizontal-line"></div>
              <img src={payment} alt="" />
            </div>
          </div>

          <div styleName="show-flex">
            <div styleName="shipping-fields">
              <div>
                <label>Imię</label>
                <input
                  type="text"
                  defaultValue={userDataResponse.firstname}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
              <div>
                <label>Nazwisko</label>
                <input
                  type="text"
                  defaultValue={userDataResponse.lastname}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
              <div>
                <label>Ulica i numer</label>
                <input
                  type="text"
                  defaultValue={userDataResponse.street}
                  onChange={(e) => setStreet(e.target.value)}
                />
              </div>
              <div>
                <label>Miasto</label>
                <input
                  type="text"
                  defaultValue={userDataResponse.city}
                  onChange={(e) => setCity(e.target.value)}
                />
              </div>

              <div>
                <label>Kod pocztowy</label>
                <input
                  type="text"
                  defaultValue={userDataResponse.postalCode}
                  onChange={(e) => setPostalCode(e.target.value)}
                />
              </div>
              <div>
                <label>Numer telefonu</label>
                <input
                  type="text"
                  defaultValue={userDataResponse.phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
              <div>
                <label>Nazwa firmy</label>
                <input
                  type="text"
                  defaultValue={userDataResponse.company}
                  onChange={(e) => setCompany(e.target.value)}
                />
              </div>
              <div>
                <label>NIP</label>
                <input
                  type="text"
                  defaultValue={userDataResponse.nip}
                  onChange={(e) => setNip(e.target.value)}
                />
              </div>
              <div>
                <label>E-mail</label>
                <input
                  type="text"
                  defaultValue={userDataResponse.email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
            <div styleName="shipping-options">
              <div
                onClick={() => handleClick("1")}
                styleName={"add-border-" + activeShipment.one}
              >
                <h5>Sklep</h5>
                <h3>0zł</h3>
                <p>Odbiór osobisty</p>
              </div>
              <div
                onClick={() => handleClick("2")}
                styleName={"add-border-" + activeShipment.two}
              >
                <h5>apaczka</h5>
                <h3>25zł</h3>
                <p>Przelew</p>
              </div>
              <div
                onClick={() => handleClick("3")}
                styleName={"add-border-" + activeShipment.three}
              >
                <h5>apaczka</h5>
                <h3>25zł</h3>
                <p>Za pobraniem</p>
              </div>
            </div>
          </div>

          <div styleName="navigation">
            <div
              onClick={() => props.history.push("/Sklep/Glowna")}
              styleName="navigation-return"
            >
              <img src={arrowReturn} alt="" />
              <p>Powrót</p>
            </div>
            <div styleName="navigation-forward">
              <button
                onClick={() => props.history.push("/Sklep/Glowna")}
                styleName="navigation-forward-continue"
              >
                KONTYNUUJ ZAKUPY
              </button>
              <button
                onClick={() => goToLastScreen()}
                styleName="navigation-forward-laststep"
              >
                OSTATNI KROK
              </button>
            </div>
          </div>
        </div>
      ) : (
        <ShippingPt2 shippingProps={shippingProps} goBack={returnFromPt2} />
      )}
    </div>
  );
};

export default withRouter(
  CssModules(Pt1, styles, {
    allowMultiple: true,
  })
);
