import React, { useState, useEffect } from "react";
import styles from "./ContactForm.module.css";
import CssModules from "react-css-modules";
import Button from "../../Animations/Button";

const ContactForm = ({ handleClose, show }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [phone, setPhone] = useState("");

  const showHideClassName = show ? "modal display-block" : "modal display-none";

  const [submitButtonState, setSubmitButtonState] = useState("gray");

  const [alert, setAlert] = useState("hide");

  const handleSubmitButton = () => {
    var inputFields = document.querySelectorAll("[id*='others']"),
      i;
    var statementCheck = document.getElementById("statementCheck").checked;
    let submit = true;
    for (i = 0; i < inputFields.length; ++i) {
      if (inputFields[i].value.length === 0) {
        inputFields[i].setCustomValidity("Uzupełnij puste pole");
        submit = false;
      } else {
        inputFields[i].setCustomValidity("");
      }
      if (inputFields[i].id === "others_2" && inputFields[i].value.length > 0) {
        handleEmailInput(inputFields[i]);
      }
    }
    if (statementCheck && submit) {
      setSubmitButtonState("blue");
      setAlert("hide");
    } else {
      setSubmitButtonState("gray");
    }
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://smtpjs.com/v3/smtp.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  function handleSubmit(e) {
    e.preventDefault();
    if (submitButtonState === "blue") {
      window.Email.send({
        Host: "smtp.elasticemail.com",
        Username: "biuro@scs-rybnik.pl",
        Password: "6C44CF254C6F1832676B5FB879E467EC8AB8",
        To: "biuro@scs-rybnik.pl",
        Cc: email,
        From: "biuro@scs-rybnik.pl",
        Subject: "Wiadomość od " + name,
        Body: message + "<br><b>Telefon do klienta:<b>" + phone,
      });

      handleClose(true);
    } else {
      setAlert("show");
    }
  }

  const handleEmailInput = (inputField) => {
    var re = /\S+@\S+\.\S+/;
    if (re.test(inputField.value) === false) {
      inputField.setCustomValidity(
        "Niepoprawny format e-mail, przykład: aaa@gmail.com"
      );
    } else {
      inputField.setCustomValidity("");
    }
  };

  return (
    <div>
      {show && (
        <div styleName={showHideClassName}>
          <form
            id="submitForm"
            onSubmit={handleSubmit}
            onInput={handleSubmitButton}
            styleName="modal-main"
            method="POST"
          >
            <div styleName="modal-main-picture">
              <input type="image" src="./ContactPagePics/machine.png" alt="" />
            </div>
            <div styleName="modal-main-picture-mobile">
              <input
                type="image"
                src="./ContactPagePics/machine_mobile.png"
                alt=""
              />
            </div>
            <div styleName="form-title">
              <p>Formularz</p>
            </div>
            <div styleName="modal-main-details">
              <label>Imię i Nazwisko</label>
              <input
                id="others_1"
                type="text"
                required
                onChange={(e) => setName(e.target.value)}
              />
              <label>Adres-email</label>
              <input
                id="others_2"
                type="email"
                required
                onChange={(e) => setEmail(e.target.value)}
              />
              <label>Numer telefonu</label>
              <input
                id="phone"
                type="text"
                onChange={(e) => setPhone(e.target.value)}
              />
              <label>Wiadomość</label>
              <textarea
                id="others_3"
                styleName="message-textarea"
                required
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
              <div styleName="modal-main-buttons">
                <div
                  style={{ width: "100%" }}
                  styleName={`submit ${submitButtonState}`}
                >
                  <Button
                    whileHover={1.2}
                    whileTap={0.8}
                    id="input"
                    type="submit"
                    value="WYŚLIJ"
                  />
                </div>
                <div style={{ width: "100%" }} styleName="reject">
                  <Button
                    whileHover={1.2}
                    whileTap={0.8}
                    id="input"
                    type="submit"
                    data-dismiss="modal"
                    onClick={handleClose}
                    value="Anuluj"
                  />
                </div>
              </div>
              <div styleName="statement">
                <input id="statementCheck" type="checkbox" />
                <p>
                  Oświadczam, że zapoznałęm się z
                  <strong> Polityką prywatności</strong> oraz
                  <strong> Klauzulą RODO</strong>
                </p>
              </div>
              <div styleName={`alert ${alert}`}>
                <p>Zaakceptuj Politykę prywatności i Klauzulę RODO</p>
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default CssModules(ContactForm, styles, {
  allowMultiple: true,
});
