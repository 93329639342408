import React, { Component } from "react";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";

class GMap extends Component {
  render() {
    return (
      <div style={{ height: "100%", width: "100%" }}>
        <Map
          google={this.props.google}
          zoom={14}
          initialCenter={{
            lat: this.props.lat,
            lng: this.props.lng,
          }}
        >
          <Marker></Marker>
        </Map>
      </div>
    );
  }
}
export default GoogleApiWrapper({
  apiKey: "AIzaSyAMrKvvYBziy8JXJiLjdkxZXWiALY3J0pw",
})(GMap);
