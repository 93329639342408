import React, { useState } from "react";
import styles from "./PassRemainder.module.css";
import CssModules from "react-css-modules";
import firebase from "../../services/firebase/firebase";

const PassRemainder = () => {
  const [email, setEmail] = useState("");

  const resetPass = () => {
    var auth = firebase.auth();
    var emailAddress = email;
    auth
      .sendPasswordResetEmail(emailAddress)
      .then(function () {
        alert("Link do zresetowania hasła został wysłany na adres: " + email);
      })
      .catch(function (error) {
        // An error happened.
      });
  };

  const handleInputChange = (event) => {
    setEmail(event.target.value);
  };

  return (
    <div styleName="remainder">
      <h3>Nie pamiętasz hasła?</h3>
      <div>
        <input type="text" placeholder="E-mail" onChange={handleInputChange} />
      </div>
      <button onClick={resetPass}>Resetuj hasło</button>
    </div>
  );
};

export default CssModules(PassRemainder, styles, {
  allowMultiple: true,
});
