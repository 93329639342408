import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import styles from "./Pt2.module.css";
import CssModules from "react-css-modules";
import shipping from "../../images/shipping-not-active.svg";
import payment from "../../images/payment-active.svg";
import arrowReturn from "../../images/noun_Arrow.svg";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { updateCart } from "../../services/total/actions";
import { removeProduct } from "../../services/cart/actions";
import firebase from "../../services/firebase/firebase";

const Pt2 = (props) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://smtpjs.com/v3/smtp.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  const nextPath = (path) => {
    props.history.push(path);
  };

  const myAddress = {
    street: "Ulica i numer: " + props.shippingProps.street,
    city: props.shippingProps.city,
    postalCode: props.shippingProps.postalCode,
  };

  const myShipping = props.cartProducts;

  function confirmOrder() {
    let shippingMethod = "";

    switch (props.shippingProps.selectedShipment) {
      case "1":
        shippingMethod = "Odbiór osobisty - 0zł";
      case "2":
        shippingMethod = "apaczka przelew - 25zł";
      case "3":
        shippingMethod = "apaczka za pobraniem - 25zł";
    }

    let order = [];

    myShipping.map((product) =>
      order.push(
        "<strong>Nazwa produktu: </strong>" +
          product.name +
          " " +
          "<strong>Cena: </strong>" +
          product.price +
          " " +
          "<strong>Ilość: </strong>" +
          product.quantity +
          "<br>"
      )
    );

    var total = 0;

    if (props.shippingProps.selectedShipment === "1") {
      total =
        Math.round((+props.cartTotal.totalPrice + Number.EPSILON) * 100) / 100;
    } else {
      total =
        Math.round((+props.cartTotal.totalPrice + Number.EPSILON) * 100) / 100 +
        25;
    }

    var orderToString = order.toString();
    var pureOrder1 = orderToString.replaceAll("<strong>", "");
    var pureOrder2 = pureOrder1.replaceAll("</strong>", "");
    var pureOrder3 = pureOrder2.replaceAll("<br>", "");

    var newPostKey = firebase.database().ref().child("orders").push().key;

    var d = Date.now();

    firebase
      .database()
      .ref("orders/" + newPostKey)
      .set({
        order: pureOrder3,
        email: props.shippingProps.email,
        date: d,
        total: total,
      });

    order.push("<strong>Łączna wartość zamówienia: </strong>" + total);

    const message =
      "<strong>Dane kontrahenta:</strong><br>" +
      "<br>Imię i nazwisko: " +
      props.shippingProps.firstname +
      " " +
      props.shippingProps.lastname +
      "<br>Telefon: " +
      props.shippingProps.phone +
      "<br>Email: " +
      props.shippingProps.email +
      "<br><br><strong>Adres wysyłki: </strong> <br>" +
      "<br>" +
      "Ulica i numer: " +
      props.shippingProps.street +
      "<br>" +
      "Kod pocztowy: " +
      props.shippingProps.postalCode +
      "<br>" +
      "Miasto: " +
      props.shippingProps.city +
      "<br>" +
      "Firma: " +
      props.shippingProps.company +
      "<br>" +
      "NIP: " +
      props.shippingProps.nip +
      "<br>" +
      "<strong><br>Zamówienie: </strong><br><br>" +
      order +
      "<strong><br><br>Metoda przesyłki: <strong><br>" +
      shippingMethod;

    window.Email.send({
      Host: "smtp.elasticemail.com",
      Username: "biuro@scs-rybnik.pl",
      Password: "6C44CF254C6F1832676B5FB879E467EC8AB8",
      To: "biuro@scs-rybnik.pl",
      Cc: props.shippingProps.email,
      From: "biuro@scs-rybnik.pl",
      Subject:
        "Zamówienie od " +
        props.shippingProps.firstname +
        " " +
        props.shippingProps.lastname,
      Body: message,
    });
    const { cartProducts } = props;

    //update karty
    cartProducts.map((p) => removeProduct(p));
    cartProducts.length = 0;
    props.updateCart(cartProducts);
    nextPath("/Sklep/Glowna");
    alert(
      "Twoje zamówienie zostało wysłane do sklepu. Skontaktujemy się z Państwem najszybciej jak to możliwe. Dziękujemy."
    );
  }

  return (
    <div styleName="shipping">
      <div styleName="shipping-top">
        <h3>Podsumowanie</h3>
        <div styleName="shipping-icons">
          <img src={shipping} alt="" />
          <div styleName="horizontal-line"></div>
          <img src={payment} alt="" />
        </div>
      </div>
      <div styleName="shipping-method">
        <h1>Metoda przesyłki</h1>
      </div>
      <div styleName="shipping-options">
        {(() => {
          switch (props.shippingProps.selectedShipment) {
            case "1":
              return (
                <div>
                  <h5>Sklep</h5>
                  <h3>0zł</h3>
                  <p>Odbiór osobisty</p>
                </div>
              );
            case "2":
              return (
                <div>
                  <h5>apaczka</h5>
                  <h3>25zł</h3>
                  <p>Przelew</p>
                </div>
              );
            case "3":
              return (
                <div>
                  <h5>apaczka</h5>
                  <h3>25zł</h3>
                  <p>Za pobraniem</p>
                </div>
              );
            default:
              return null;
          }
        })()}
      </div>
      <div styleName="shipping-address">
        <h1>Adres wysyłki</h1>
        <p>{myAddress.street}</p>
        <p>
          {myAddress.postalCode} {myAddress.city}
        </p>
      </div>
      <div styleName="shipping-order">
        <h1>Twoje zamówienie</h1>
        {myShipping.map((product) => (
          <div styleName="product">
            {/* <img src={product.mainImage} alt="" /> */}
            <h5>{product.name}</h5>
            <p>{product.uom}</p>
            <p>{product.price}</p>
            <p>{product.quantity}</p>
          </div>
        ))}
      </div>

      <div styleName="navigation">
        <div onClick={() => props.goBack()} styleName="navigation-return">
          <img src={arrowReturn} alt="" />
          <p>Powrót</p>
        </div>
        <div styleName="navigation-forward">
          <button
            onClick={() => nextPath("/Sklep/Glowna")}
            styleName="navigation-forward-continue"
          >
            KONTYNUUJ ZAKUPY
          </button>
          <div>
            <button
              onClick={() => confirmOrder()}
              styleName="navigation-forward-laststep"
            >
              POTWIERDŹ ZAMÓWIENIE
            </button>
            <div styleName="summary">
              Całkowity koszt &nbsp;
              {props.shippingProps.selectedShipment === "1"
                ? Math.round(
                    (+props.cartTotal.totalPrice + Number.EPSILON) * 100
                  ) / 100
                : Math.round(
                    (+props.cartTotal.totalPrice + Number.EPSILON) * 100
                  ) /
                    100 +
                  25}
              zł
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  cartProducts: state.cart.products,
  cartTotal: state.total.data,
});

Pt2.propTypes = {
  cartProducts: PropTypes.array.isRequired,
  cartTotal: PropTypes.object,
  updateCart: PropTypes.func.isRequired,
  removeProduct: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  updateCart,
  removeProduct,
})(
  withRouter(
    CssModules(Pt2, styles, {
      allowMultiple: true,
    })
  )
);
