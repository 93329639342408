import React from "react";
import fifthCatOtten from "../../images/otten_logo.png";
import fifthCat3M from "../../images/3m_logo.png";
import fifthKuper from "../../images/kuper_logo.png";
import fifthOrange from "../../images/orange tools.png";
// import iMoveToShop from "../../images/btnZrobZakupy.png";
import parImg from "../../images/newlineText.png";
import styles from "./CategoriesDetails.module.scss";
import { withRouter } from "react-router-dom";

export default withRouter(function FifthCategory(props) {
  return (
    <div>
      <div className={styles.text_box}>
        <h1 className={styles.text_box_title}>Narzędzia</h1>
        <h2 className={styles.text_box_subtitle}>Zakres sprzedaży:</h2>

        <div className={styles.text_box_item}>
          <p>
            <img src={parImg} className={styles.text_img} alt="" />
            Narzędzia do obróbki drewna
          </p>
          <p>
            <img src={parImg} className={styles.text_img} alt="" />
            Akcesoria lakiernicze
          </p>
          <p>
            <img src={parImg} className={styles.text_img} alt="" />
            Wałki do nanoszenia kleju
          </p>
          <p>
            <img src={parImg} className={styles.text_img} alt="" />
            Spajarki do forniru
          </p>
          <p>
            <img src={parImg} className={styles.text_img} alt="" />
            Sztyfty i gwoździe
          </p>
        </div>
        {props.shpButton}
        {/* <div className={styles.moveTo_sec}>
          <img
            className={styles.moveTo_img}
            src={iMoveToShop}
            alt=""
            onClick={() => nextPath("/shop")}
          ></img>
        </div> */}

        {window.innerWidth >= 651 ? (
          <footer className={styles.entp_imgs}>
            <div>
              <img
                className={styles.entp_img}
                src={fifthCatOtten}
                alt="banner"
              />
            </div>
            <div>
              <img className={styles.entp_img} src={fifthCat3M} alt="banner" />
            </div>
          </footer>
        ) : null}
        {window.innerWidth >= 651 ? (
          <div className={styles.entp_imgs_down}>
            <div>
              <img
                className={styles.entp_img_down}
                src={fifthKuper}
                alt="banner"
              />
            </div>
            <div>
              <img
                className={styles.entp_img_down}
                src={fifthOrange}
                alt="banner"
              />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
});
