import React from "react";
import ProductDet from "./../components/Shelf/ProductList/Product/ProductDetail/productDetail";
import FloatCart from "./../components/FloatCart";
import FrequentlyBought from "../ShopPages/FrequentlyBought";
import CheckOffer from "../ShopPages/CheckOffer";
import Newsletter from "../ShopPages/Newsletter";
import Footer from "../ShopPages/Footer";

class ProductDetail extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    var product;
    if (this.props.location.state !== undefined) {
      product = this.props.location.state.product;
    }
    return (
      <div>
        <ProductDet
          product={product}
          prodLocation={this.props.location.pathname}
        />
        <div>
          <FrequentlyBought />
        </div>
        <div>
          <CheckOffer />
        </div>
        <div>
          <Newsletter />
        </div>
        <div>
          <Footer />
        </div>
        <FloatCart />
      </div>
    );
  }
}
export default ProductDetail;
