import React, { useState } from "react";
import styles from "./Register2.module.css";
import CssModules from "react-css-modules";
import firebase from "../../services/firebase/firebase";
import { withRouter } from "react-router-dom";
import arrowReturn from "../../images/noun_Arrow.svg";
import { connect } from "react-redux";

const Register2 = (props) => {
  const [street, setStreet] = useState(props.street);
  const [postalCode, setPostalCode] = useState(props.postalCode);
  const [company, setCompany] = useState(props.company);
  const [city, setCity] = useState(props.city);
  const [phone, setPhone] = useState(props.phone);
  const [nip, setNip] = useState(props.nip);

  const nextPath = (path) => {
    props.history.push(path);
  };

  function createNewUser() {
    firebase
      .auth()
      .createUserWithEmailAndPassword(props.email, props.pass)
      .then(() => {
        writeCompanyDetails();
        nextPath("/Sklep/Glowna");
      })
      .catch(function (error) {
        switch (error.code) {
          case "auth/weak-password":
            alert("Hasło jest zbyt słabe. Wprowadź minimum 6 znaków");
            break;
          case "auth/email-already-in-use":
            alert("Podany e-mail jest już zarejestrowany");
            break;
          default:
            alert(error.code);
            break;
        }
      });
  }

  // function createNewUser() {
  //   //check if user with given email exists
  //   firebase
  //     .auth()
  //     .createUserWithEmailAndPassword(props.email, props.pass)
  //     .then(() => {
  //       //check if user with given nip exists
  //       var nipExists = firebase.database().ref("users/" + nip);
  //       nipExists.on("value", function (snapshot) {
  //         if (snapshot.val() !== null) {
  //           if (
  //             snapshot.val().nip === nip &&
  //             firebase.auth().currentUser !== null
  //           ) {
  //             //delete created user with email and password function
  //             // document.getElementById("logout2").click();
  //             // props.history.push("/Sklep/Glowna");
  //             var user = firebase.auth().currentUser;
  //             user
  //               .delete()
  //               .then(function () {
  //                 alert("deleted");
  //               })
  //               .catch(function (error) {
  //                 alert(error);
  //               });

  //             alert("Podany NIP istnieje w bazie");
  //           }
  //         } else {
  //           writeCompanyDetails();
  //           nextPath("/Sklep/Glowna");
  //         }
  //       });
  //     })
  //     .catch(function (error) {
  //       switch (error.code) {
  //         case "auth/weak-password":
  //           alert("Hasło jest zbyt słabe. Wprowadź minimum 6 znaków");
  //           break;
  //         case "auth/email-already-in-use":
  //           alert("Podany e-mail jest już zarejestrowany");
  //           break;
  //         default:
  //       }
  //     });
  // }

  function writeCompanyDetails() {
    var outString = props.email.replace(
      /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
      ""
    );

    firebase
      .database()
      .ref("users/" + nip + outString)
      .set({
        firstname: props.firstname,
        lastname: props.lastname,
        email: props.email,
        street: street,
        postalCode: postalCode,
        company: company,
        city: city,
        phone: phone,
        nip: nip,
      });
  }
  const registerUser = () => {
    if (
      street === undefined ||
      street === "" ||
      postalCode === undefined ||
      postalCode === "" ||
      company === undefined ||
      company === "" ||
      city === undefined ||
      city === "" ||
      phone === undefined ||
      phone === "" ||
      nip === undefined ||
      nip === ""
    ) {
      alert("Proszę wypełnij wszystkie pola obowiązkowe");
    } else {
      createNewUser();
    }
  };

  return (
    <div>
      <div
        onClick={() =>
          props.callback(
            props.firstname,
            props.lastname,
            props.email,
            props.pass,
            props.confirmEmail,
            props.confirmPass,
            street,
            postalCode,
            company,
            city,
            phone,
            nip
          )
        }
        styleName="register-return"
      >
        <img src={arrowReturn} alt="" />
        <p>Powrót</p>
      </div>
      <div styleName="register">
        <div styleName="register-fields">
          <div>
            <label>Ulica i numer*</label>
            <input
              type="text"
              onChange={(e) => setStreet(e.target.value)}
              value={street}
            />
          </div>
          <div>
            <label>Miasto*</label>
            <input
              type="text"
              onChange={(e) => setCity(e.target.value)}
              value={city}
            />
          </div>
          <div>
            <label>Kod pocztowy*</label>
            <input
              type="text"
              onChange={(e) => setPostalCode(e.target.value)}
              value={postalCode}
            />
          </div>
          <div>
            <label>Numer telefonu*</label>
            <input
              type="text"
              onChange={(e) => setPhone(e.target.value)}
              value={phone}
            />
          </div>
          <div>
            <label>Nazwa firmy*</label>
            <input
              type="text"
              onChange={(e) => setCompany(e.target.value)}
              value={company}
            />
          </div>
          <div>
            <label>NIP*</label>
            <input
              type="text"
              onChange={(e) => setNip(e.target.value)}
              value={nip}
            />
          </div>
        </div>
        <p>* Pola obowiązkowe</p>
        <button onClick={() => registerUser()}>ZAREJESTRUJ SIĘ</button>
      </div>
    </div>
  );
};

export default withRouter(
  CssModules(Register2, styles, {
    allowMultiple: true,
  })
);
