import React, { useState } from "react";
import styles from "./Main.module.css";
import CssModules from "react-css-modules";
import logo from "../images/logo.svg";
import fbLogo from "../images/facebook-alt.svg";

const Footer = () => {
  const [email, setEmail] = useState("");
  const handleSubmit = () => {
    window.Email.send({
      Host: "smtp.elasticemail.com",
      Username: "biuro@scs-rybnik.pl",
      Password: "6C44CF254C6F1832676B5FB879E467EC8AB8",
      To: "biuro@scs-rybnik.pl",
      Cc: email,
      From: "biuro@scs-rybnik.pl",
      Subject: "Zapis na newsletter od -  " + email,
      Body: "Gratulacje! Zapisałeś się do newslettera.",
    }).then(() =>
      alert(
        "Zapisałeś się na newsletter! Kopię wiadomości otrzymasz na podanego e-maila."
      )
    );
    document.getElementById("clear-me2").value = "";
  };

  const openFacebook = () => {
    window.open(
      "https://www.facebook.com/pages/category/Local-Business/%C5%9Al%C4%85skie-Centrum-Stolarskie-Artdrew-108852797174642/",
      "_blank"
    );
  };
  return (
    <div styleName="footer">
      <div styleName="footer-newsletter">
        <div styleName="footer-newsletter-logo">
          <img src={logo} alt="" />
        </div>
        <div styleName="footer-newsletter-items">
          <input
            id="clear-me2"
            type="text"
            placeholder="Wpisz swój e-mail"
            onChange={(e) => setEmail(e.target.value)}
          />
          <button onClick={() => handleSubmit()}>ZAPISZ SIĘ</button>
        </div>
        <div style={{ marginTop: "2vh" }} onClick={() => openFacebook()}>
          <img src={fbLogo} alt="" />
        </div>
      </div>
      <div styleName="footer-info">
        <h3>Informacje</h3>
        {/* <p>Koszty dostawy</p>
        <p>Zwroty</p> */}
        <a
          style={{ fontWeight: "bold", color: "black" }}
          href="./ContactPagePics/Regulamin sklepu internetowego.pdf"
          download
        >
          Regulamin
        </a>

        <a
          style={{ fontWeight: "bold", color: "black" }}
          href="./ContactPagePics/polityka_prywatnosci.doc"
          download
        >
          Polityka prywatności
        </a>
        <a
          style={{ fontWeight: "bold", color: "black" }}
          href="./ContactPagePics/RODO.doc"
          download
        >
          Klauzula RODO
        </a>
        {/* <p>Metody płatności</p> */}
      </div>
      {/* <div styleName="footer-account">
        <h3>Twoje konto</h3>
        <p>Kupony</p>
        <p>Twoje konto</p>
        <p>Historia zamówień</p>
      </div> */}
      <div styleName="footer-contact">
        <h3>Kontakt</h3>
        <div>
          <p
            style={{ marginBottom: "0", height: "6vmin", lineHeight: "6vmin" }}
          >
            Nr tel:&nbsp;
          </p>
          <a
            style={{
              height: "6vmin",
              lineHeight: "6vmin",
              fontWeight: "bold",
              color: "black",
            }}
            href="tel:887499299"
          >
            +48 887 499 299
          </a>
        </div>
        <div>
          <p>Adres:&nbsp;</p>
          <div styleName="footer-address">
            <p>Zebrzydowicka 103</p>
            <p>44-217 Rybnik</p>
          </div>
        </div>
        <p>NIP: 642 252 16 93</p>
        {/* <p>Mapa firmy</p> */}
      </div>
    </div>
  );
};

export default CssModules(Footer, styles, {
  allowMultiple: true,
});
