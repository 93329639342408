import React from "react";
import iCalif from "../../images/calif_logo.png";
// import iMoveToShop from "../../images/btnZrobZakupy.png";
import parImg from "../../images/newlineText.png";
import styles from "./CategoriesDetails.module.scss";
import { withRouter } from "react-router-dom";
// import Button from "../../Animations/Button";

export default withRouter(function EigthCategory(props) {
  return (
    <div>
      <div className={styles.text_box_okleiny}>
        <h1 className={styles.text_box_title}>Okleiny, obłogi, lamele</h1>
        <h2 className={styles.text_box_subtitle}>Zakres sprzedaży:</h2>
        <h3 className={styles.text_box_subtitle_second_okleiny}>
          Okleiny, obłogi naturalne – 0,6 mm ÷ 2,0 mm
        </h3>
        {window.innerWidth >= 651 ? (
          <div className={styles.text_box_item_okleiny}>
            <div>
              <img src={parImg} className={styles.text_img} alt="" />
              dąb
            </div>
            <div>
              <img src={parImg} className={styles.text_img} alt="" />
              buk
            </div>
            <div>
              <img src={parImg} className={styles.text_img} alt="" />
              jesion
            </div>
            <div>
              <img src={parImg} className={styles.text_img} alt="" />
              olcha
            </div>
            <div>
              <img src={parImg} className={styles.text_img} alt="" />
              sosna
            </div>
            <div>
              <img src={parImg} className={styles.text_img} alt="" />
              brzoza
            </div>
            <div>
              <img src={parImg} className={styles.text_img} alt="" />
              jawor
            </div>
            <div>
              <img src={parImg} className={styles.text_img} alt="" />
              orzech
            </div>
            <div>
              <img src={parImg} className={styles.text_img} alt="" />
              jabłoń indyjska
            </div>
            <div>
              <img src={parImg} className={styles.text_img} alt="" />
              czereśnia
            </div>
            <div>
              <img src={parImg} className={styles.text_img} alt="" />
              meranti, merbau, okume
            </div>
          </div>
        ) : (
          <div className={styles.text_box_item_okleiny}>
            <div>
              <img src={parImg} className={styles.text_img} alt="" />
              dąb, buk, jesion, olcha, sosna, brzoza, jawor,
            </div>
            <div className={styles.text_div_down}>
              orzech, jabłoń indyjska, czereśnia, meranti,
            </div>
            <div className={styles.text_div_down_end}>merbau, okume</div>
          </div>
        )}
        <h2 className={styles.text_box_subtitle_second_okleiny}>
          Lamele – 4,0 mm
        </h2>
        {window.innerWidth >= 651 ? (
          <div className={styles.text_box_item_okleiny}>
            <div>
              <img src={parImg} className={styles.text_img} alt="" />
              dąb
            </div>
            <div>
              <img src={parImg} className={styles.text_img} alt="" />
              jesion
            </div>
            <div>
              <img src={parImg} className={styles.text_img} alt="" />
              sosna
            </div>
          </div>
        ) : (
          <div className={styles.text_box_item_okleiny}>
            <div>
              <img src={parImg} className={styles.text_img} alt="" />
              dąb, jesion, sosna
            </div>
          </div>
        )}
        <h3 className={styles.text_box_subtitle_second_okleiny}>
          Okleiny modyfikowane
        </h3>

        <footer>
          {props.shpButton}
          <div className={styles.okleiny_california_img}>
            {window.innerWidth >= 651 ? <img src={iCalif} alt="" /> : null}
          </div>

          {/* <img
              className={styles.moveTo_img_second}
              onClick={() => nextPath("/shop")}
              src={iMoveToShop}
              alt=""
            ></img> */}
        </footer>
      </div>
    </div>
  );
});
