import React, { useState, useEffect } from "react";
import styles from "./Main.module.css";
import CssModules from "react-css-modules";

const Newsletter = () => {
  const [email, setEmail] = useState("");
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://smtpjs.com/v3/smtp.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  const handleSubmit = () => {
    window.Email.send({
      Host: "smtp.elasticemail.com",
      Username: "biuro@scs-rybnik.pl",
      Password: "6C44CF254C6F1832676B5FB879E467EC8AB8",
      To: "biuro@scs-rybnik.pl",
      Cc: email,
      From: "biuro@scs-rybnik.pl",
      Subject: "Zapis na newsletter od -  " + email,
      Body: "Gratulacje! Zapisałeś się do newslettera.",
    }).then(
      (err) => console.log(err),
      alert(
        "Zapisałeś się na newsletter! Kopię wiadomości otrzymasz na podanego e-maila."
      )
    );
    document.getElementById("clear-me").value = "";
  };
  return (
    <div styleName="newsletter">
      <div styleName="newsletter-items">
        <h3>Zapisz się na newsletter i bądź zawsze z Nami na bieżąco</h3>
        <input
          id="clear-me"
          type="text"
          placeholder="Wpisz swój e-mail"
          onChange={(e) => setEmail(e.target.value)}
        />
        <button onClick={() => handleSubmit()}>ZAPISZ SIĘ</button>
      </div>
    </div>
  );
};

export default CssModules(Newsletter, styles, {
  allowMultiple: true,
});
