import React, { useState } from "react";
import styles from "./HistoryPage.module.css";
import CssModules from "react-css-modules";
import { briefStory } from "./Brief";
import Story1 from "./Story1";
import Story2 from "./Story2";
import Story3 from "./Story3";

const History = () => {
  const [state, setState] = useState(1);

  const handleChange = (e) => {
    setState(e);
  };

  const Story = () => {
    switch (state) {
      case 1:
        return <Story1 />;
      case 2:
        return <Story2 />;
      case 3:
        return <Story3 />;
      default:
        return null;
    }
  };

  return (
    <div styleName="container">
      <div styleName="background-desktop">
        <input type="image" src="./HistoryPagePics/history.png" alt="" />
      </div>
      <div styleName="background-mobile">
        <input type="image" src="./HistoryPagePics/history_mobile.png" alt="" />
      </div>
      <div styleName="brief-history">
        <h1>Historia firmy</h1>
        <h5>
          W naszej pracy główny nacisk kładziemy na rozwój, co pozwala nam w
          pełni zaspokajać potrzeby naszych klientów.
        </h5>
        <p>{briefStory()}</p>
      </div>
      <div styleName="timeline">
        <p>O NAS</p>
        <h3>Historia marki</h3>
      </div>
      <div styleName="timeline-arrow">
        <div styleName="timeline-arrow-desktop">
          <input type="image" src="./HistoryPagePics/arrow.png" alt="" />
        </div>
        <div styleName="timeline-arrow-mobile">
          <input type="image" src="./HistoryPagePics/arrow_mobile.png" alt="" />
        </div>
        <div styleName="timeline-arrow-dots">
          <div onClick={() => handleChange(1)} styleName="dot-1">
            <input
              type="image"
              src={`./HistoryPagePics/dot-${state === 1 ? 1 : 0}.png`}
              alt=""
            />
            <h5>2004</h5>
          </div>
          <div onClick={() => handleChange(2)} styleName="dot-2">
            <input
              type="image"
              src={`./HistoryPagePics/dot-${state === 2 ? 1 : 0}.png`}
              alt=""
            />
            <h5>2013</h5>
          </div>
          <div onClick={() => handleChange(3)} styleName="dot-3">
            <input
              type="image"
              src={`./HistoryPagePics/dot-${state === 3 ? 1 : 0}.png`}
              alt=""
            />
            <h5>2020</h5>
          </div>
        </div>
      </div>
      <footer styleName="footer">
        <div styleName="footer-image">
          <input
            type="image"
            src={`./HistoryPagePics/image${state}.jpg`}
            alt=""
          />
        </div>
        <div styleName="footer-text">{Story()}</div>
      </footer>
    </div>
  );
};
export default CssModules(History, styles, { allowMultiple: true });
