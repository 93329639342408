import React, { useState, useEffect } from "react";
import styles from "./Account.module.css";
import CssModules from "react-css-modules";
import { withRouter } from "react-router-dom";
import firebase from "../../services/firebase/firebase";
import axios from "axios";
import { useDragControls } from "framer-motion";

const Account = () => {
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [street, setStreet] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [company, setCompany] = useState("");
  const [city, setCity] = useState("");
  const [phone, setPhone] = useState("");
  const [nip, setNip] = useState("");
  const [userDataResponse, setUserDataResponse] = useState("");
  const [ordersResponse, setOrdersResponse] = useState([]);
  const [email, setEmail] = useState("");

  useEffect(() => {
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        setEmail(user.email);
        axios
          .get(`https://slaskie-centrum-stolarskie.firebaseio.com/users.json`)
          .then((res) => {
            Object.keys(res.data).map((ele) => {
              if (user.email === res.data[ele][`email`]) {
                var myNIP = res.data[ele][`nip`];
                var outString =
                  myNIP +
                  user.email.replace(
                    /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
                    ""
                  );
                setNip(myNIP);
                setUserDataResponse(res.data[outString]);
              }
            });
          });
      }
    });
  }, []);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        axios
          .get(`https://slaskie-centrum-stolarskie.firebaseio.com/orders.json`)
          .then((res) => {
            var orders = [];
            Object.keys(res.data).map((ele) => {
              if (user.email === res.data[ele][`email`]) {
                orders.push(res.data[ele]);
              }
            });
            setOrdersResponse(orders);
          });
      }
    });
  }, []);

  function saveChanges() {
    const postData = {
      firstname: firstname === "" ? userDataResponse.firstname : firstname,
      lastname: lastname === "" ? userDataResponse.lastname : lastname,
      street: street === "" ? userDataResponse.street : street,
      postalCode: postalCode === "" ? userDataResponse.postalCode : postalCode,
      email: email === "" ? userDataResponse.email : email,
      company: company === "" ? userDataResponse.company : company,
      city: city === "" ? userDataResponse.city : city,
      phone: phone === "" ? userDataResponse.phone : phone,
      nip: nip === "" ? userDataResponse.nip : nip,
    };
    let updates = {};
    var outString = email.replace(
      /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
      ""
    );
    updates["/users/" + nip + outString] = postData;
    firebase.database().ref().update(updates);
    alert("Zmiany zapisane pomyślnie!");
  }

  return (
    <div styleName="account">
      <h3>Moje dane</h3>
      <div styleName="account-fields">
        <div>
          <label>Imię</label>
          <input
            type="text"
            defaultValue={userDataResponse.firstname}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </div>
        <div>
          <label>Nazwisko</label>
          <input
            type="text"
            defaultValue={userDataResponse.lastname}
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>
        <div>
          <label>Ulica i numer</label>
          <input
            type="text"
            defaultValue={userDataResponse.street}
            onChange={(e) => setStreet(e.target.value)}
          />
        </div>

        <div>
          <label>Kod pocztowy</label>
          <input
            type="text"
            defaultValue={userDataResponse.postalCode}
            onChange={(e) => setPostalCode(e.target.value)}
          />
        </div>
        <div>
          <label>Miasto</label>
          <input
            type="text"
            defaultValue={userDataResponse.city}
            onChange={(e) => setCity(e.target.value)}
          />
        </div>
        <div>
          <label>Nazwa firmy</label>
          <input
            type="text"
            defaultValue={userDataResponse.company}
            onChange={(e) => setCompany(e.target.value)}
          />
        </div>
        <div>
          <label>Numer telefonu</label>
          <input
            type="text"
            defaultValue={userDataResponse.phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </div>
        <button onClick={() => saveChanges()}>Zatwierdź zmiany</button>
      </div>
      <div styleName="account-purchase-history">
        <h3>Historia zamówień</h3>
        <table style={{ width: "90vw" }}>
          <thead>
            <tr>
              <th>Lp.</th>
              <th>Szczegóły zamówienia</th>
              <th>Wartość zamówienia</th>
              <th>Data zamówienia</th>
            </tr>
          </thead>
          <tbody>
            {ordersResponse !== undefined
              ? ordersResponse.map((item, i) => {
                  var miliDate = new Date(item.date);
                  var formattedDate = miliDate.toLocaleString();
                  return (
                    <tr>
                      <td>{i}</td>
                      <td>{item.order}</td>
                      <td>{item.total}</td>
                      <td>{formattedDate}</td>
                    </tr>
                  );
                })
              : ""}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default withRouter(
  CssModules(Account, styles, {
    allowMultiple: true,
  })
);
