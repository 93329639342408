import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Thumb from "../../../Thumb";
import { formatPrice } from "../../../../services/util";
import { addProduct } from "../../../../services/cart/actions";
import ProductLink from "./ProductDetail/productLink";

const Product = ({ product, addProduct }) => {
  let formattedPrice = formatPrice(product.price, product.currencyFormat);
  let productInstallment;
  const productDirty = product.name;
  const productClean = productDirty.replace("%", "");
  const directTo = {
    pathname: `/Sklep/Produkt/${
      product.group +
      "/" +
      product.producer +
      "/" +
      productClean +
      "/" +
      product.docID
    }`,
    prodId: product.name,
  };

  if (!!product.title) {
    const installmentPrice = "";
    productInstallment = <div className="installment"></div>;
  }

  return (
    <div className="shelf-item" code={product.code}>
      <ProductLink tag="div" product={product} params={product} to={directTo}>
        <Thumb
          classes="shelf-item__thumb"
          src={product.mainImage}
          alt={product.name}
        />

        <p className="shelf-item__title">{product.name}</p>
        <div className="shelf-item__price">
          <div className="val">
            <b>{formattedPrice.substr(0, formattedPrice.length - 3)}</b>
            <span>{formattedPrice.substr(formattedPrice.length - 3, 3)}</span>
            <small> {" " + product.currencyFormat}</small>
            <small> brutto</small>
          </div>
          {productInstallment}
        </div>
      </ProductLink>
      {window.innerWidth >= 600 ? (
        <div
          className="shelf-item__buy-btn"
          onClick={() => addProduct(product)}
        >
          Dodaj do koszyka
        </div>
      ) : (
        <div
          className="shelf-item-buy_button"
          onClick={() => addProduct(product)}
        >
          Dodaj do koszyka
        </div>
      )}
    </div>
  );
};

Product.propTypes = {
  product: PropTypes.object.isRequired,
  addProduct: PropTypes.func.isRequired,
};

export default connect(null, { addProduct })(Product);
