import React from "react";
// import iMoveToShop from "../../images/btnZrobZakupy.png";
import iLibra from "../../images/libra_logo.png";
import parImg from "../../images/newlineText.png";
import styles from "./CategoriesDetails.module.scss";
import { withRouter } from "react-router-dom";

export default withRouter(function TenthCategory(props) {
  return (
    <div>
      <div className={styles.text_box}>
        <div className={styles.text_box_title}>Kleje</div>
        <div className={styles.text_box_subtitle}>W sprzedaży posiadamy:</div>

        <div className={styles.text_box_subtitle_second}>
          Kleje dla przemysłu meblarskiego:
        </div>
        <div className={styles.text_box_item}>
          {window.innerWidth >= 651 ? (
            <p>
              <img src={parImg} className={styles.text_img} alt="img" />
              kleje do drewna litego oraz montażu elementów drewnianych
            </p>
          ) : (
            <p>
              <img src={parImg} className={styles.text_img} alt="img" />
              kleje do drewna litego oraz montażu elementów
            </p>
          )}

          {window.innerWidth >= 651 ? (
            <p className={styles.text_space}>
              i drewnopochodnych w klasach wodoodporności D3 i D4
            </p>
          ) : (
            <p className={styles.text_space}>drewnianych i drewnopochodnych</p>
          )}
          {window.innerWidth <= 651 ? (
            <p className={styles.text_space}>
              w klasach wodoodporności D3 i D4
            </p>
          ) : null}

          <p>
            <img src={parImg} className={styles.text_img} alt="" />
            kleje mocznikowe
          </p>
          <p>
            <img src={parImg} className={styles.text_img} alt="" />
            kleje poliuretanowe
          </p>
          <p>
            <img src={parImg} className={styles.text_img} alt="" />
            kleje kontaktowe
          </p>
        </div>
        <div className={styles.text_box_subtitle_second}>Kleje do parkietu</div>
        {props.shpButton}
        {/* <div className={styles.moveTo_sec}>
          <img
            className={styles.moveTo_img}
            src={iMoveToShop}
            alt=""
            onClick={() => nextPath("/shop")}
          ></img>
        </div> */}
        {window.innerWidth >= 651 ? (
          <footer className={styles.entp_imgs_libra}>
            <div>
              <img className={styles.entp_img} src={iLibra} alt="" />
            </div>
          </footer>
        ) : null}
      </div>
    </div>
  );
});
