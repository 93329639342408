import React from "react";
import CategoryLink from "./CategoryLink";
import styles from "./CategoryCard.module.css";
import { motion } from "framer-motion";

//<div className="card_img_div">

class CategoryCard extends React.Component {
  render() {
    return (
      <div className="card_img_div">
        <CategoryLink
          tag="div"
          props={this.props}
          to={{
            pathname: this.props.pathname,
            ...this.props,
          }}
        >
          <motion.img
            transition={{ duration: 0.95 }}
            whileHover={{ rotateY: "30deg", rotateX: "-20deg" }}
            whileTap={{ rotateY: 0 }}
            className={styles.card_img}
            src={`./CategoriesPics/${this.props.src}`}
          ></motion.img>
        </CategoryLink>
      </div>
    );
  }
}

export default CategoryCard;
