import React, { useState, useEffect } from "react";
import styles from "./Navbar-shop.module.css";
import CssModules from "react-css-modules";
import { withRouter } from "react-router-dom";
import logo from "../images/logo.svg";
import Button from "../Animations/Button";
import firebase from "../services/firebase/firebase";
import account from "../images/konto.svg";
import hotline from "../images/infolinia.svg";
import facebook from "../images/facebook.svg";
import basket from "../images/basket.svg";
import { Link } from "react-router-dom";

const Navbar = (props) => {
  const nextPath = (path) => {
    props.history.push(path);
  };

  const [show, setHamburger] = useState("");
  const [loggedInUser, setLoggedInUser] = useState(null);

  useEffect(() => {
    checkIfUserLoggedIn();
  }, []);

  function checkIfUserLoggedIn() {
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        setLoggedInUser(user);
      }
    });
  }

  const showUserAccount = () => {
    if (loggedInUser !== null) {
      nextPath("/Sklep/Konto");
    } else if (loggedInUser === null) {
      nextPath("/Sklep/Logowanie");
    }
  };

  function logout() {
    firebase
      .auth()
      .signOut()
      .then(function () {
        setLoggedInUser(null);
      })
      .catch(function (error) {});
  }

  const handleHamburgerClick = () => {
    if (show === "" || show === "close") {
      setHamburger("open");
    } else {
      setHamburger("close");
    }
  };

  const openFacebook = () => {
    window.open(
      "https://www.facebook.com/pages/category/Local-Business/%C5%9Al%C4%85skie-Centrum-Stolarskie-Artdrew-108852797174642/",
      "_blank"
    );
  };

  const hideBurger = () => {
    document.querySelector("[class*='burger-squeeze']").click();
  };

  return (
    <div styleName="all">
      <div styleName="container">
        <div styleName="logo_phone">
          <div onClick={() => nextPath("/")} styleName="logo">
            <Button
              whileHover={1.1}
              whileTap={0.8}
              src={logo}
              id="input"
              type="image"
            />
          </div>
        </div>
        <div styleName="navbar">
          <div styleName={`navbar_links ${show}`}>
            <div styleName="navbar-flex">
              <Link onClick={() => hideBurger()}>
                <Button
                  whileHover={1.1}
                  whileTap={0.8}
                  src={account}
                  id="input"
                  type="image"
                  onClick={() => showUserAccount()}
                />
              </Link>
              <div styleName="navbar-block">
                <p>
                  <strong>Twoje konto</strong>
                </p>
                {loggedInUser !== null ? (
                  <div>
                    <p>Witaj {loggedInUser.email}</p>
                    <p
                      id="logout2"
                      style={{ textDecoration: "underline" }}
                      onClick={() => logout()}
                    >
                      Wyloguj
                    </p>
                  </div>
                ) : (
                  <p>Zaloguj/Zarejestruj</p>
                )}
              </div>
            </div>

            <div styleName="navbar-flex">
              <Link onClick={() => hideBurger()} to="/Sklep">
                <Button
                  whileHover={1.1}
                  whileTap={0.8}
                  src={basket}
                  id="input"
                  type="image"
                />
              </Link>
              <div styleName="navbar-block">
                <p>
                  <strong>Sklep</strong>
                </p>
                <p>Sprawdź</p>
              </div>
            </div>

            <div
              styleName="navbar-flex"
              onClick={() => window.open("tel:887499299", "_self")}
            >
              <Link onClick={() => hideBurger()}>
                <Button
                  whileHover={1.1}
                  whileTap={0.8}
                  src={hotline}
                  id="input"
                  type="image"
                />
              </Link>
              <div styleName="navbar-block">
                <p>
                  <strong>Infolinia:</strong>
                </p>
                <p>Doradzimy</p>
              </div>
            </div>
            <div styleName="navbar-flex" onClick={() => openFacebook()}>
              <Link onClick={() => hideBurger()}>
                <Button
                  whileHover={1.1}
                  whileTap={0.8}
                  src={facebook}
                  id="input"
                  type="image"
                />
              </Link>
            </div>
          </div>
        </div>
        <div styleName="my_burger">
          <div
            onClick={() => handleHamburgerClick()}
            styleName={`burger burger-squeeze ${show}`}
          >
            <div styleName="burger-lines"></div>
          </div>
        </div>
      </div>
      <div styleName={`navbar_links__overlay ${show}`}></div>
    </div>
  );
};

export default withRouter(CssModules(Navbar, styles, { allowMultiple: true }));
