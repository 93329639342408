import React, { useState } from "react";
import styles from "./Register.module.css";
import CssModules from "react-css-modules";
import { withRouter } from "react-router-dom";
import Register2 from "./Register2";
import axios from "axios";

const Register = () => {
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [street, setStreet] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [company, setCompany] = useState("");
  const [city, setCity] = useState("");
  const [phone, setPhone] = useState("");
  const [nip, setNip] = useState("");
  const [proceed, setProceed] = useState(false);
  const [statement, setStatement] = useState(false);

  function continueRegistration() {
    let emailAlreadyExists = false;
    axios
      .get(`https://slaskie-centrum-stolarskie.firebaseio.com/users.json`)
      .then((res) => {
        Object.keys(res.data).map((ele) => {
          if (email === res.data[ele][`email`]) {
            emailAlreadyExists = true;
          }
        });
      });
    if (emailAlreadyExists) {
      alert("Podany email już istnieje!");
    } else {
      formValidation();
    }
  }

  function formValidation() {
    if (
      firstname === undefined ||
      firstname === "" ||
      lastname === undefined ||
      lastname === "" ||
      email === undefined ||
      email === "" ||
      confirmEmail === undefined ||
      confirmEmail === "" ||
      pass === undefined ||
      pass === "" ||
      confirmPass === undefined ||
      confirmPass === ""
    ) {
      alert("Proszę wypełnij wszystkie pola obowiązkowe");
    } else if (confirmEmail !== email) {
      alert("Podane e-maile są różne");
    } else if (pass !== confirmPass) {
      alert("Podane hasła są różne");
    } else if (statement === false) {
      alert("Zaakceptuj regulamin i politykę prywatności");
    } else {
      setProceed(true);
    }
  }

  function registerCallback(
    firstname,
    lastname,
    email,
    pass,
    confirmEmail,
    confirmPass,
    street,
    postalCode,
    company,
    city,
    phone,
    nip
  ) {
    setFirstName(firstname);
    setLastName(lastname);
    setEmail(email);
    setPass(pass);
    setConfirmEmail(confirmEmail);
    setConfirmPass(confirmPass);
    setStatement(true);
    setProceed(false);
    setStreet(street);
    setPostalCode(postalCode);
    setCompany(company);
    setCity(city);
    setPhone(phone);
    setNip(nip);
  }

  return (
    <div>
      {proceed === false ? (
        <div styleName="register">
          <h3>Załóż konto, aby móc w pełni korzystać ze sklepu</h3>
          <div styleName="register-fields">
            <div>
              <label>Imię*</label>
              <input
                tabIndex="1"
                type="text"
                value={firstname}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            <div>
              <label>Nazwisko*</label>
              <input
                tabIndex="2"
                type="text"
                value={lastname}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            <div>
              <label>E-mail*</label>
              <input
                tabIndex="3"
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div>
              <label>Powtórz e-mail*</label>
              <input
                tabIndex="4"
                type="text"
                value={confirmEmail}
                onChange={(e) => setConfirmEmail(e.target.value)}
              />
            </div>
            <div>
              <label>Hasło*</label>
              <input
                tabIndex="5"
                type="password"
                value={pass}
                onChange={(e) => setPass(e.target.value)}
              />
            </div>
            <div>
              <label>Powtórz Hasło*</label>
              <input
                type="password"
                tabIndex="6"
                value={confirmPass}
                onChange={(e) => setConfirmPass(e.target.value)}
              />
            </div>
          </div>
          <p>* Pola obowiązkowe</p>
          <div styleName="statement">
            <input
              onChange={() => setStatement(true)}
              type="checkbox"
              checked={statement}
            />
            <p>Oświadczam, że akceptuję&nbsp;</p>
            <a
              style={{ fontWeight: "bold", color: "black" }}
              href="./ContactPagePics/Regulamin sklepu internetowego.pdf"
              download
            >
              Regulamin
            </a>
            <p>&nbsp;i&nbsp;</p>
            <a
              style={{ fontWeight: "bold", color: "black" }}
              href="./ContactPagePics/polityka_prywatnosci.doc"
              download
            >
              Politykę prywatności
            </a>
          </div>
          <button onClick={() => continueRegistration()}>DALEJ</button>
        </div>
      ) : (
        <Register2
          firstname={firstname}
          lastname={lastname}
          email={email}
          pass={pass}
          confirmEmail={confirmEmail}
          confirmPass={confirmPass}
          postalCode={postalCode}
          company={company}
          city={city}
          phone={phone}
          nip={nip}
          street={street}
          callback={registerCallback}
        />
      )}
    </div>
  );
};

export default withRouter(
  CssModules(Register, styles, {
    allowMultiple: true,
  })
);
