import React from "react";
import firstCatSopur from "../../images/Sopur-logo-RGB.png";
import imgGallery from "../../images/galeria.png";
import bejca_1 from "../../images/Bejca-antyczna-wodna-buk-1.jpg";
import bejca_2 from "../../images/Bejca-antyczna-wodna-buk-2.jpg";
import bejca_3 from "../../images/bejcanitrososna1.jpg";
import bejca_4 from "../../images/bejcanitrososna2.jpg";
import bejca_5 from "../../images/bejcanitrososna3.jpg";
import bejca_6 from "../../images/paleta-bejca-nitro-dab-1.jpg";
import bejca_7 from "../../images/paleta-bejca-nitro-dab-2.jpg";
import bejca_8 from "../../images/paleta-bejca-nitro-dab-3.jpg";
import bejca_9 from "../../images/Paleta-bejca-pastelowa-dab-1.jpg";
import bejca_10 from "../../images/Paleta-bejca-pastelowa-dab-2.jpg";
import bejca_11 from "../../images/Paleta-bejca-pastelowa-dab-3.jpg";
import bejca_12 from "../../images/bejcarozpuszczalnikowabuk1.jpg";
import bejca_13 from "../../images/bejcarozpuszczalnikowabuk2.jpg";
import bejca_14 from "../../images/bejcarozpuszczalnikowabuk3.jpg";
// import iMoveToShop from "../../images/btnZrobZakupy.png";
import parImg from "../../images/newlineText.png";
import styles from "./CategoriesDetails.module.scss";
import Carousel, { Modal, ModalGateway } from "react-images";
import { withRouter } from "react-router-dom";
import Button from "../../Animations/Button";

const images = [
  { src: bejca_1 },
  { src: bejca_2 },
  { src: bejca_3 },
  { src: bejca_4 },
  { src: bejca_5 },
  { src: bejca_6 },
  { src: bejca_7 },
  { src: bejca_8 },
  { src: bejca_9 },
  { src: bejca_10 },
  { src: bejca_11 },
  { src: bejca_12 },
  { src: bejca_13 },
  { src: bejca_14 },
];

export default withRouter(
  class ThirdCategory extends React.Component {
    constructor(props) {
      super(props);
      this.state = { modalIsOpen: false };
      this.toggleModal = this.toggleModal.bind(this);
    }

    toggleModal() {
      this.setState((state) => ({ modalIsOpen: !state.modalIsOpen }));
    }
    nextPath(p) {
      this.props.history.push(p);
    }
    render() {
      return (
        <div>
          <div className={styles.text_box}>
            <h1 className={styles.text_box_title}>Bejce, patyny</h1>
            <h2 className={styles.text_box_subtitle}>
              W sprzedaży posiadamy bejce:
            </h2>

            <div className={styles.text_box_item}>
              <p>
                <img src={parImg} className={styles.text_img} alt="" />
                Nitro
              </p>
              <p>
                <img src={parImg} className={styles.text_img} alt="" />
                Rozcieńczalnikowe
              </p>
              <p>
                <img src={parImg} className={styles.text_img} alt="" />
                Pastelowe
              </p>
              <p>
                <img src={parImg} className={styles.text_img} alt="" />
                Wodne
              </p>
              <p>
                <img src={parImg} className={styles.text_img} alt="" />
                oraz patyny.
              </p>
            </div>
            {this.props.shpButton}
            {/* <div className={styles.moveTo_sec}>
              <img
                className={styles.moveTo_img}
                src={iMoveToShop}
                alt=""
                onClick={() => this.nextPath("/shop")}
              ></img>
            </div> */}
            {window.innerWidth >= 651 ? (
              <footer>
                <div>
                  <ModalGateway id="modalForm">
                    {this.state.modalIsOpen ? (
                      <Modal
                        show={this.state.modalIsOpen}
                        onClose={this.toggleModal}
                      >
                        <Carousel
                          showArrows={true}
                          views={images}
                          styles={{
                            container: (base) => ({
                              ...base,
                              backgroundColor: "transparent",
                              boxShadow:
                                "0 1px 10px -1px rgba(0,0,0,0.2), 0 0 0 1px rgba(0,0,0,0.04), 0 1px 0 rgba(0,0,0,0.04)",
                              height: "100%",
                            }),
                            footer: (base, state) => ({
                              ...base,
                              color: "black",
                              minHeight: 42,
                              paddingBottom: 0,

                              "& a": {
                                color: state.interactionIsIdle
                                  ? "black"
                                  : "#00d7ff",
                                transition: "color 300ms",
                              },
                              "& strong": { textTransform: "uppercase" },
                            }),
                            navigationItem: (base) => ({
                              ...base,
                              backgroundColor: "black",

                              ":hover": {
                                backgroundColor: "#00d7ff",
                              },
                              ":active": {
                                backgroundColor: "#00d7ff",
                                transform: "translateY(2px)",
                              },
                            }),
                            view: (base, state) => ({
                              overflow: "hidden",
                              height: "95vh",
                              width: "50%",
                              marginLeft: "25%",
                              marginRight: "25%",
                            }),
                          }}
                        />
                      </Modal>
                    ) : null}
                  </ModalGateway>
                </div>
                <div className={styles.entp_imgs_bejca}>
                  <div>
                    <img
                      className={styles.entp_img}
                      src={firstCatSopur}
                      alt="Banner"
                    />
                  </div>
                </div>
                <div className={styles.color_pallet}>
                  <p> Paleta kolorów do podglądu</p>
                  <Button
                    whileHover={1.2}
                    whileTap={0.8}
                    onClick={this.toggleModal}
                    src={imgGallery}
                    id="input"
                    type="image"
                  />
                  {/* <img
                    onClick={this.toggleModal}
                    src={imgGallery}
                    alt="Gallery"
                  /> */}
                </div>
              </footer>
            ) : (
              <footer>
                {/* <div>
                  <ModalGateway id="modalForm">
                    {this.state.modalIsOpen ? (
                      <Modal
                        show={this.state.modalIsOpen}
                        onClose={this.toggleModal}
                      >
                        <Carousel
                          showArrows={true}
                          views={images}
                          styles={{
                            container: (base) => ({
                              ...base,
                              backgroundColor: "transparent",
                              boxShadow:
                                "0 1px 10px -1px rgba(0,0,0,0.2), 0 0 0 1px rgba(0,0,0,0.04), 0 1px 0 rgba(0,0,0,0.04)",
                              height: "100%",
                            }),
                            footer: (base, state) => ({
                              ...base,
                              color: "black",
                              minHeight: 42,
                              paddingBottom: 0,

                              "& a": {
                                color: state.interactionIsIdle
                                  ? "black"
                                  : "#00d7ff",
                                transition: "color 300ms",
                              },
                              "& strong": { textTransform: "uppercase" },
                            }),
                            navigationItem: (base) => ({
                              ...base,
                              backgroundColor: "black",

                              ":hover": {
                                backgroundColor: "#00d7ff",
                              },
                              ":active": {
                                backgroundColor: "#00d7ff",
                                transform: "translateY(2px)",
                              },
                            }),
                            view: (base, state) => ({
                              overflow: "hidden",
                              height: "100vh",
                              width: "90%",
                              marginTop: "70%",
                              marginLeft: "5%",
                              marginRight: "5%",
                            }),
                          }}
                        />
                      </Modal>
                    ) : null}
                  </ModalGateway>
                </div>
                <div className={styles.color_pallet}>
                  <p> Paleta kolorów do podglądu</p>
                  <img
                    onClick={this.toggleModal}
                    src={imgGallery}
                    alt="Gallery"
                  />
                </div> */}
              </footer>
            )}
          </div>
        </div>
      );
    }
  }
);
