import React, { useState, useEffect } from "react";
import styles from "./Login.module.css";
import CssModules from "react-css-modules";
import { withRouter } from "react-router-dom";
import firebase from "../../services/firebase/firebase";

const Login = (props) => {
  const nextPath = (path) => {
    props.history.push(path);
  };

  const [login, setLogin] = useState("");
  const [pass, setPass] = useState("");

  useEffect(() => {
    if (
      props.location.myEmail !== undefined &&
      props.location.myEmail !== "" &&
      props.location.myPass !== undefined &&
      props.location.myPass !== ""
    ) {
      setLogin(props.location.myEmail);
      setPass(props.location.myPass);
    }
  }, []);

  function signIn() {
    firebase
      .auth()
      .signInWithEmailAndPassword(login, pass)
      .then(() => props.history.push("/Sklep/Glowna"))
      .catch(function (error) {
        alert("Niepoprawny login lub hasło");
      });
  }

  return (
    <div styleName="login">
      <h3>Zaloguj się</h3>
      <div styleName="login-input">
        <input
          type="text"
          placeholder="E-mail"
          name="login"
          defaultValue={login}
          onChange={(e) => setLogin(e.target.value)}
        />
        <input
          placeholder="Hasło"
          type="password"
          name="pass"
          defaultValue={pass}
          onChange={(e) => setPass(e.target.value)}
        />
      </div>
      <div styleName="save-my-pass">
        <div
          onClick={() => nextPath("/Sklep/PrzypomnijHaslo")}
          styleName="save-my-pass-right"
        >
          <p styleName="pass-remainder">Przypomnieć hasło?</p>
        </div>
      </div>
      <button onClick={() => signIn()}>ZALOGUJ SIĘ</button>
      <div onClick={() => nextPath("/Sklep/Rejestracja")} styleName="register">
        <p>Nie masz konta?</p>
        <p style={{ fontWeight: "bold" }}>Zarejestruj się</p>
      </div>
    </div>
  );
};

export default withRouter(
  CssModules(Login, styles, {
    allowMultiple: true,
  })
);
