import React, { useState } from "react";
import styles from "./ContactPage.module.css";
import CssModules from "react-css-modules";
import GMap from "./Map/Map";
import { withRouter } from "react-router-dom";
import ContactForm from "../ContactPage/ContactForm/ContactForm";
import Button from "../Animations/Button";

const Contactpage = (props) => {
  const [formVisibility, setModal] = useState(false);

  const showModal = () => {
    setModal(true);
  };

  const hideModal = (success) => {
    setModal(false);
    if (success) {
      alert(
        "Wiadomość została wysłana! Kopię wiadomości otrzymasz na e-maila podanego w formularzu."
      );
    }
  };

  const openGoogleMaps = () => {
    window.open(
      "https://www.google.com/maps/place/Zebrzydowicka+103,+44-200+Rybnik/@50.0981199,18.5092083,17z/data=!3m1!4b1!4m5!3m4!1s0x47114f47fb59527b:0x6f600140ca297d3b!8m2!3d50.0981199!4d18.511397",
      "_blank"
    );
  };
  const openFacebook = () => {
    window.open(
      "https://www.facebook.com/pages/category/Local-Business/%C5%9Al%C4%85skie-Centrum-Stolarskie-Artdrew-108852797174642/",
      "_blank"
    );
  };

  return (
    <div styleName="container">
      <div styleName="photo-desktop">
        <input type="image" src="./ContactPagePics/main.jpg" alt="" />
      </div>
      <div styleName="photo-mobile">
        <input type="image" src="./ContactPagePics/main_mobile.png" alt="" />
      </div>

      <div styleName="white-foreground">
        <div styleName="form-button">
          <input type="image" src="./ContactPagePics/form-button.png" alt="" />
          <div styleName="form-button-edit">
            <Button
              whileHover={1.15}
              whileTap={0.8}
              id="input"
              type="image"
              onClick={showModal}
              src="./ContactPagePics/form-button-edit.png"
            />
          </div>
          <p>Formularz</p>
        </div>

        <div styleName="map">
          <GMap lat="50.09816" lng="18.51157" />
        </div>
        <div styleName="info-section">
          <div styleName="phone-section">
            <a href="tel:887499299">
              <Button
                whileHover={1.2}
                whileTap={0.8}
                id="input"
                type="image"
                src="./ContactPagePics/phone.png"
                style={{ display: "flex" }}
              />
            </a>
            <div>
              <p style={{ fontWeight: "bold" }}>Telefon:</p>
              <p>+48 887 499 299</p>
              <p>+48 603 499 299</p>
            </div>
          </div>
          <div styleName="address-section">
            <input type="image" src="./ContactPagePics/address.png" alt="" />
            <div>
              <p style={{ fontWeight: "bold" }}>Adres:</p>
              <p>
                Zebrzydowicka 103
                <br />
                44-217 Rybnik
              </p>
            </div>
          </div>
          <div styleName="email-section">
            <a href="mailto: fhu.artdrew@gmail.com">
              <Button
                whileHover={1.2}
                whileTap={0.8}
                id="input"
                type="image"
                src="./ContactPagePics/e-mail.png"
                style={{ display: "flex" }}
              />
            </a>
            <div>
              <p style={{ fontWeight: "bold" }}>E-mail:</p>
              <p>fhu.artdrew@gmail.com</p>
            </div>
          </div>
          <div styleName="hours-section">
            <input
              type="image"
              src="./ContactPagePics/opening-hours.png"
              alt=""
            />
            <div>
              <p style={{ fontWeight: "bold" }}>Godziny otwarcia:</p>
              <p>
                Poniedziałek - piątek: 8.00 - 16.00
                <br />
                Sobota/niedziela: nieczynne
              </p>
            </div>
          </div>
          <div styleName="facebook-section">
            <Button
              whileHover={1.2}
              whileTap={0.8}
              id="input"
              type="image"
              onClick={openFacebook}
              src="./ContactPagePics/facebook.png"
              style={{ display: "flex" }}
            />
            <div styleName="facebook-section-desktop">
              <p style={{ fontWeight: "bold" }}>Dołącz do nas</p>
              <p>/Śląskie Centrum Stolarskie Artdrew</p>
            </div>
            <div styleName="facebook-section-mobile">
              <p style={{ fontWeight: "bold" }}>Dołącz do nas</p>
              <p>/Śląskie Centrum Stolarskie Artdrew</p>
            </div>
          </div>
        </div>
        <div styleName="form-button-mobile">
          <Button
            whileHover={1.2}
            whileTap={0.8}
            id="input"
            type="image"
            onClick={showModal}
            src="./ContactPagePics/form-button-edit.png"
            style={{ display: "flex" }}
          />
          <p>Formularz</p>
        </div>
        <div onClick={() => openGoogleMaps()} styleName="map-mobile">
          <p>Mapa dojazdu</p>
        </div>
        <ContactForm show={formVisibility} handleClose={hideModal} />
      </div>
      <footer styleName="footer">
        <a
          style={{ fontWeight: "bold" }}
          href="./ContactPagePics/polityka_prywatnosci.doc"
          download
        >
          Polityka prywatności
        </a>
        <a
          style={{ fontWeight: "bold" }}
          href="./ContactPagePics/RODO.doc"
          download
        >
          Klauzula RODO
        </a>
        <p>Prawa zastrzeżone przez Artdrew</p>
        <div>
          <p>Realizacja:</p>
          <p style={{ fontWeight: "bold" }}>Jaźwiec.art</p>
        </div>
      </footer>
    </div>
  );
};

export default withRouter(
  CssModules(Contactpage, styles, {
    allowMultiple: true,
  })
);
