import React, { Component } from "react";
import PropTypes, { object } from "prop-types";
import ProductCarousel from "./carousel";
import { connect } from "react-redux";
import {
  loadCart,
  removeProduct,
  changeProductQuantity,
  addProduct,
} from "../../../../../services/cart/actions";
import { updateCart } from "../../../../../services/total/actions";
import axios from "axios";
import "./style.scss";
import { prefixToAdd } from "../../../../../services/util";
import ReactHtmlParser from "react-html-parser";

class ProductDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      product: undefined,
      images: [],
      quantity: 0,
      isLoading: true,
    };

    this.getCartQuantity = this.getCartQuantity.bind(this);
    this.getSupply = this.getSupply.bind(this);
    this.openLink = this.openLink.bind(this);
    this.initProduct = this.initProduct.bind(this);
  }

  static propTypes = {
    product: PropTypes.object,
    prodLocation: PropTypes.string.isRequired,
    loadCart: PropTypes.func.isRequired,
    updateCart: PropTypes.func.isRequired,
    cartProducts: PropTypes.array.isRequired,
    // newProduct: PropTypes.object,
    removeProduct: PropTypes.func,
    productToRemove: PropTypes.object,
    changeProductQuantity: PropTypes.func,
    productToChange: PropTypes.object,
  };

  initProduct(nextProps) {
    var nxProdLoc = "";
    var prodLoc = "";

    if (nextProps !== undefined) {
      nxProdLoc = nextProps.prodLocation;
    }

    if (this.props !== undefined) {
      prodLoc = this.props.prodLocation;
    }

    if (this.props.product === undefined || prodLoc !== nxProdLoc) {
      if (nxProdLoc !== prodLoc && nxProdLoc !== "") {
        prodLoc = nxProdLoc;
      }
      let ids = prodLoc.split("/");
      let id = ids[ids.length - 1];

      axios
        .get(
          "https://slaskie-centrum-stolarskie.firebaseio.com/products/" +
            id +
            ".json"
        )

        .then((res) => {
          var downloadedProduct = res.data;
          var imgs = [];
          downloadedProduct.price =
            Math.round(
              (+(downloadedProduct.price * 0.23) +
                +downloadedProduct.price +
                Number.EPSILON) *
                100
            ) / 100;
          downloadedProduct.quantity = 1;
          imgs.push(prefixToAdd + downloadedProduct.mainImage);
          if (downloadedProduct.otherImages !== undefined) {
            for (let i = 0; i < downloadedProduct.otherImages.length; i++) {
              imgs.push(prefixToAdd + downloadedProduct.otherImages[i]);
            }
          }
          downloadedProduct.mainImage =
            prefixToAdd + downloadedProduct.mainImage;
          downloadedProduct.docID = id;
          this.setState({
            product: downloadedProduct,
            isLoading: false,
            images: imgs,
          });
        })
        .catch((error) => {
          alert("Nie można pobrać listy produktów - odśwież stronę");
        });
    } else if (this.props.product !== undefined) {
      var imgs = [];
      // console.log(this.props.product);
      imgs.push(this.props.product.mainImage);
      if (this.props.product.otherImages !== undefined) {
        for (let i = 0; i < this.props.product.otherImages.length; i++) {
          imgs.push(prefixToAdd + this.props.product.otherImages[i]);
        }
      }

      this.setState({
        product: this.props.product,
        images: imgs,
        isLoading: false,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    //console.log("will receive", this.props.product, nextProps.prodLocation);
    this.initProduct(nextProps);
    //console.log(this.state.product);
  }

  componentDidMount(nextProps) {
    //   console.log("did mount", this.props.product, nextProps);
    this.initProduct(nextProps);
    //console.log(this.state.product);
  }

  openLink(url) {
    window.open(url, "_blank");
  }

  getSupply(quantity) {
    const red = "#980000";
    const green = "#6aa84f";
    const yellow = "#f1c232";
    var supplyCol = yellow;

    if (quantity !== undefined) {
      if (quantity === "pomarańczowy") {
        supplyCol = yellow;
      } else if (quantity === "zielony") {
        supplyCol = green;
      } else if (quantity === "czerwony") {
        supplyCol = red;
      }
    }

    return (
      <div
        style={{ width: "20px ", height: "20px", backgroundColor: supplyCol }}
      ></div>
    );
  }

  getCartQuantity(product) {
    const { cartProducts } = this.props;
    var quantity = 0;
    cartProducts.forEach((cp) => {
      if (cp.docID === product.docID) {
        quantity = cp.quantity;
      }
    });
    return quantity;
  }

  addProd = (product) => {
    //modified 20.10.13
    const { updateCart, cartProducts } = this.props;
    let productAlreadyInCart = false;
    cartProducts.forEach((cp) => {
      if (cp.docID === product.docID) {
        cp.quantity += 1;
        productAlreadyInCart = true;
      }
    });
    if (!productAlreadyInCart) {
      this.state.product.quantity = 1;
      cartProducts.push(this.state.product);
    }
    updateCart(cartProducts);
    //loadCart(cartProducts);
  };

  removeProd = (product) => {
    const { cartProducts, updateCart } = this.props;
    // console.log(cartProducts);
    var delProd = false;
    cartProducts.forEach((cp) => {
      if (cp.docID === product.docID) {
        // console.log(cp.name, cp.docID, product.docID, cp.quantity);
        if (cp.quantity > 1) {
          cp.quantity -= 1;
        } else if (cp.quantity == 1) {
          delProd = true;
        }
        // console.log(cartProducts);
      }
    });

    if (delProd === true) {
      const index = cartProducts.findIndex((p) => p.docID === product.docID);
      if (index >= 0) {
        cartProducts.splice(index, 1);
        updateCart(cartProducts);
      }
    } else {
      updateCart(cartProducts);
    }
  };

  render() {
    let product = this.state.product;

    const isLoading = this.state.isLoading;
    if (isLoading) {
      return <div className="App">Ładuje dane...</div>;
    }

    return (
      <div style={{ marginTop: "5vh" }}>
        <div className="product-detail-container" id="product-detail-container">
          {window.innerWidth < 650 ? (
            <ProductCarousel props={this.state.images} />
          ) : null}
          <div className="product-detail-container-leftview">
            {window.innerWidth >= 650 ? (
              <div className="product-detail-container-leftview-header">
                <div>{product.group + "/" + product.producer + "/"}</div>

                <div className="product-detail-container-leftview-header-product">
                  {product.name}
                </div>
              </div>
            ) : null}
            {window.innerWidth >= 650 ? (
              <div className="product-detail-container-leftview-gallery">
                {<ProductCarousel props={this.state.images} />}
              </div>
            ) : null}
          </div>

          <div className="product-detail-container-rightview">
            {window.innerWidth >= 650 ? (
              <div className="product-detail-container-rightview-header">
                <div className="product-detail-container-rightview-header-elem">
                  Termin wysyłki
                  <div className="product-detail-container-rightview-header-ship">
                    Wysyłka w ciągu 2 dni roboczych
                  </div>
                </div>
                <div className="product-detail-container-rightview-header-elem">
                  Szybka wysyłka
                  <div className="product-detail-container-rightview-header-ship">
                    Zapytaj w sklepie
                  </div>
                </div>
              </div>
            ) : null}
            <div className="product-detail-container-rightview-body">
              <div className="product-detail-container-rightview-body-product">
                {product.name}
              </div>
              <div className="product-detail-container-rightview-body-product-price">
                {product.price + " " + product.currencyFormat + " " + "brutto"}
              </div>

              <div className="product-detail-container-rightview-body-product-props">
                <div>KOD TOWARU</div>
                <div className="product-detail-container-rightview-body-product-props-desc">
                  {product.code}
                </div>
                <div>DOSTĘPNOŚĆ</div>
                <div className="product-detail-container-rightview-body-product-props-desc">
                  {this.getSupply(product.availability)}
                </div>
                <div>JEDNOSTKA MIARY</div>
                <div className="product-detail-container-rightview-body-product-props-desc">
                  {product.uom}
                </div>
                <div>PRODUCENT</div>
                <div className="product-detail-container-rightview-body-product-props-desc">
                  {product.producer}
                </div>
                <div>TYP</div>
                <div className="product-detail-container-rightview-body-product-props-desc">
                  {product.type}
                </div>
                <div>ZASTOSOWANIE</div>
                <div className="product-detail-container-rightview-body-product-props-desc">
                  {product.application}
                </div>
                <div>ZAŁĄCZNIKI</div>
                <div className="product-detail-container-rightview-body-product-props-desc">
                  {product.other !== undefined
                    ? product.other.map((item, i) => {
                        return (
                          <p
                            onClick={() => this.openLink(prefixToAdd + item)}
                            key={i}
                            style={{
                              margin: "0",
                              marginTop: "2vmin",
                              textDecoration: "underline",
                              cursor: "pointer",
                              color: "#E97A41",
                              width: "11vmin",
                            }}
                          >
                            Załącznik nr {i + 1}
                          </p>
                        );
                      })
                    : ""}
                </div>
              </div>

              {window.innerWidth >= 650 ? (
                <div className="product-detail-container-rightview-footer">
                  <div className="product-detail-container-rightview-footer-inc-btn">
                    <button
                      onClick={() => this.removeProd(product)}
                      className="product-detail-container-rightview-footer-inc-btn-btn"
                    >
                      -
                    </button>
                    <div className="product-detail-container-rightview-footer-inc-btn-quantity">
                      {this.getCartQuantity(product)}
                    </div>
                    <button
                      onClick={() => this.addProd(product)}
                      className="product-detail-container-rightview-footer-inc-btn-btn"
                    >
                      +
                    </button>
                  </div>

                  <button className="product-detail-container-rightview-footer-buy-btn">
                    Ilość sztuk w koszyku
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        {window.innerWidth < 650 ? (
          <div className="product-detail-container-rightview-header">
            <div className="product-detail-container-rightview-header-elem">
              Termin wysyłki
              <div className="product-detail-container-rightview-header-ship">
                Wysyłka w ciągu 2 dni roboczych
              </div>
            </div>
            <div className="product-detail-container-rightview-header-elem">
              Szybka wysyłka
              <div className="product-detail-container-rightview-header-ship">
                Zapytaj w sklepie
              </div>
            </div>
          </div>
        ) : null}
        {window.innerWidth < 650 ? (
          <div className="product-detail-container-rightview-footer">
            <div className="product-detail-container-rightview-footer-inc-btn">
              <button
                onClick={() => this.removeProd(product)}
                className="product-detail-container-rightview-footer-inc-btn-btn"
              >
                -
              </button>
              <div className="product-detail-container-rightview-footer-inc-btn-quantity">
                {this.getCartQuantity(product)}
              </div>
              <button
                onClick={() => this.addProd(product)}
                className="product-detail-container-rightview-footer-inc-btn-btn"
              >
                +
              </button>
            </div>

            <button className="product-detail-container-rightview-footer-buy-btn">
              Ilość sztuk w koszyku
            </button>
          </div>
        ) : null}

        <div className="product-detail-specification">
          <span>{ReactHtmlParser(product.description)}</span>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  cartProducts: state.cart.products,
  newProduct: state.cart.productToAdd,
  productToRemove: state.cart.productToRemove,
  productToChange: state.cart.productToChange,
  cartTotal: state.total.data,
});

export default connect(mapStateToProps, {
  loadCart,
  updateCart,
  removeProduct,
  changeProductQuantity,
})(ProductDetail);
