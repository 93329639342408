import React from "react";
import styles from "./HistoryPage.module.css";
import CssModules from "react-css-modules";

const Story3 = () => {
  return (
    <div styleName="story3">
      <h4 styleName="story3-year">2020</h4>
      <h4 styleName="story3-title">Zmiana wizerunku firmy i dalszy rozwój</h4>
      <p>
        Zmiany, zmiany, zmiany ! Z początkiem roku 2020 uruchomiliśmy mieszalnię
        farb i lakierów wodnych która pozwala nam uzyskiwać kolory z palet NCS,
        RAL i innych. Kolejnym ważnym dla nas zadaniem na rok 2020 była zmiana
        wizerunkowa naszej firmy. Zmieniono logo, ale przede wszystkim została
        utworzona nowa strona, na której mogą Państwo podejrzeć nasz asortyment
        i lepiej nas poznać. Wychodząc naprzeciw naszym klientom został otwarty
        sklep internetowy gdzie można zrobić zakupy o każdej porze.
      </p>
    </div>
  );
};

export default CssModules(Story3, styles, { allowMultiple: true });
