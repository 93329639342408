import React from "react";
import firstCatSchleg from "../../images/Schlegel-logo-RGB-HiRes.png";
import firstCatDev from "../../images/dev_logo.png";
import firstCatAIB from "../../images/AIB_logo.png";
// import iMoveToShop from "../../images/btnZrobZakupy.png";
import parImg from "../../images/newlineText.png";
import styles from "./CategoriesDetails.module.scss";
import { withRouter } from "react-router-dom";

export default withRouter(function SecCategory(props) {
  return (
    <div>
      <div className={styles.text_box}>
        <h1 className={styles.text_box_title}>Uszczelki drzwiowe</h1>
        {window.innerWidth >= 651 ? (
          <h2 className={styles.text_box_subtitle}>
            W sprzedaży posiadamy uszczelki drzwiowe
          </h2>
        ) : (
          <div className={styles.text_box_subtitle}>
            <div>W sprzedaży posiadamy</div>
            <div>uszczelki drzwiowe</div>
          </div>
        )}
        <div className={styles.text_box_item}>
          <p>
            <img src={parImg} className={styles.text_img} alt="" />
            Uszczelki do drzwi wewnętrznych bez przylgi
          </p>
          <p>
            <img src={parImg} className={styles.text_img} alt="" />
            Uszczelki do drzwi wewnętrznych z przylgą
          </p>
          <p>
            <img src={parImg} className={styles.text_img} alt="" />
            Uszczelki do drzwi zewnętrznych
          </p>
        </div>

        {props.shpButton}

        {window.innerWidth >= 651 ? (
          <footer className={styles.entp_imgs}>
            <div>
              <img className={styles.entp_img} src={firstCatDev} alt="" />
            </div>
            <div>
              <img className={styles.entp_img} src={firstCatSchleg} alt="" />
            </div>
            <div>
              <img className={styles.entp_img} src={firstCatAIB} alt="" />
            </div>
          </footer>
        ) : null}
      </div>
    </div>
  );
});
