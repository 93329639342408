import React from "react";
// import iMoveToShop from "../../images/btnZrobZakupy.png";
import iLakma from "../../images/lakma_logo.png";
import parImg from "../../images/newlineText.png";
import styles from "./CategoriesDetails.module.scss";
import { withRouter } from "react-router-dom";

export default withRouter(function ThirthCategory(props) {
  return (
    <div>
      <div className={styles.text_box}>
        <h1 className={styles.text_box_title}>Silikony</h1>
        {window.innerWidth >= 651 ? (
          <div className={styles.text_box_subtitle}>
            <div>
              W sprzedaży posiadamy silikony profesjonalne w różnych kolorach
            </div>
            <div>firmy LAKMA</div>
          </div>
        ) : (
          <div>
            <div className={styles.text_box_subtitle}>
              W sprzedaży posiadamy:
            </div>
            <div className={styles.text_box_item}>
              <p>
                <img src={parImg} className={styles.text_img} alt="img" />
                Silikony profesjonalne w różnych
              </p>
              <p className={styles.text_space}>kolorach firmy LAKMA</p>
            </div>
          </div>
        )}
        {props.shpButton}
        {/* <div className={styles.moveTo_sec}>
          <img
            className={styles.moveTo_img}
            src={iMoveToShop}
            onClick={() => nextPath("/shop")}
            alt="Move to shop"
          ></img>
        </div> */}
        {window.innerWidth >= 651 ? (
          <footer>
            <div className={styles.entp_imgs}>
              <img className={styles.entp_img} src={iLakma} alt="banner" />
            </div>
          </footer>
        ) : null}
      </div>
    </div>
  );
});
