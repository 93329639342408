import React, { Component } from "react";
import Form from "./product-modal-form";
import Modal from "react-modal";
import Products from "./products-grid";
import "./product-modal.css";
import firebase from "../services/firebase/firebase";
import { productsAPI } from "../services/util";
import axios from "axios";
import { storage } from "../services/firebase/firebase";

class MainView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      document: {},
      documents: [],
      mode: "",
    };
    this.handleCurrentDocument = this.handleCurrentDocument.bind(this);
    this.firebaseAction = this.firebaseAction.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.getData = this.getData.bind(this);
    this.updateDocuments = this.updateDocuments.bind(this);
  }

  toggleModal = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  handleCurrentDocument(document, mode) {
    this.setState({ mode: mode, document: document });
  }

  updateDocuments(document, mode) {
    if (mode === "new") {
      const documents = [...this.state.documents];
      documents.push(document);
      this.setState({ documents: documents });
    } else if (mode === "edit") {
      const documents = [...this.state.documents];
      const index = documents.indexOf(this.state.document);
      if (index !== -1) {
        documents.splice(index, 1);
        documents.push(document);
        this.setState({ documents: documents });
      }
    } else if (mode === "delete") {
      const documents = [...this.state.documents];
      const index = documents.indexOf(document);
      if (index !== -1) {
        documents.splice(index, 1);
        this.setState({ documents: documents });
      }
    }
  }

  firebaseAction(document, mode) {
    if (mode === "new") {
      //Dodaj nowy dokument z id wygenerowanym przez firebase
      const postData = {
        name:
          document.name === undefined || document.name === "Wybierz..."
            ? ""
            : document.name,
        producer:
          document.producer === undefined || document.producer === "Wybierz..."
            ? ""
            : document.producer,
        application:
          document.application === undefined ||
          document.application === "Wybierz..."
            ? ""
            : document.application,
        code:
          document.code === undefined || document.code === "Wybierz..."
            ? ""
            : document.code,
        glossDegree:
          document.glossDegree === undefined ||
          document.glossDegree === "Wybierz..."
            ? ""
            : document.glossDegree,
        group:
          document.group === undefined || document.group === "Wybierz..."
            ? ""
            : document.group,
        subGroup:
          document.subGroup === undefined || document.subGroup === "Wybierz..."
            ? ""
            : document.subGroup,
        type:
          document.type === undefined || document.type === "Wybierz..."
            ? ""
            : document.type,
        uom:
          document.uom === undefined || document.uom === "Wybierz..."
            ? ""
            : document.uom,
        otherImages:
          document.otherImages === undefined ? [] : document.otherImages,
        other: document.other === undefined ? [] : document.other,
        mainImage: document.mainImage === undefined ? "" : document.mainImage,
        price: document.price === undefined ? "" : document.price,
        currencyFormat:
          document.currencyFormat === undefined ||
          document.currencyFormat === "Wybierz..."
            ? "zł"
            : "zł",
        vat:
          document.vat === undefined || document.vat === "Wybierz..."
            ? "23%"
            : "23%",
        description:
          document.description === undefined ? "" : document.description,
        availability:
          document.availability === undefined ||
          document.availability === "Wybierz..."
            ? "czerwony"
            : document.availability,
      };

      let updates = {};

      var newID = firebase.database().ref().child("products").push().key;

      updates["/products/" + newID] = postData;

      firebase
        .database()
        .ref()
        .update(updates, (error) => {
          if (error) {
            //blad
            alert("Wpis do bazy nie powiódł się. Skontaktuj się z adminem.");
          } else {
            //wpisujemy do bazy
            document.id = newID;
            this.updateDocuments(document, mode);

            alert("Wpis do bazy powiódł się.");
          }
        });
    } else if (mode === "delete") {
      try {
        if (document.mainImage !== "" && document.mainImage !== undefined) {
          let splitted2 = document.mainImage.split("?alt")[0];
          var docRef = storage.child(splitted2);

          docRef
            .delete()
            .then(function () {})
            .catch(function (error) {
              console.log(error);
            });
        }
        if (document.otherImages !== "" && document.otherImages !== undefined) {
          for (let i = 0; i < document.otherImages.length; i++) {
            let splitted2 = document.otherImages[i].split("?alt")[0];

            var docRef = storage.child(splitted2);
            docRef
              .delete()
              .then(function () {})
              .catch(function (error) {
                console.log(error);
              });
          }
        }
        if (document.other !== "" && document.other !== undefined) {
          for (let i = 0; i < document.other.length; i++) {
            let splitted2 = document.other[i].split("?alt")[0];

            var docRef = storage.child(splitted2);
            docRef
              .delete()
              .then(function () {})
              .catch(function (error) {
                console.log(error);
              });
          }
        }
        //Usuwanie istniejącego dokumentu
        firebase
          .database()
          .ref("products/" + document.id)
          .remove();
        this.updateDocuments(document, mode);
        alert("Usuwanie wpisu powiodło się.");
      } catch (e) {
        console.error(
          alert("Usuwanie wpisu nie powiodło się. Skontaktuj się z adminem.")
        );
      }
    } else if (mode === "edit") {
      const postData = {
        name:
          document.name === undefined || document.name === "Wybierz..."
            ? ""
            : document.name,
        producer:
          document.producer === undefined || document.producer === "Wybierz..."
            ? ""
            : document.producer,
        application:
          document.application === undefined ||
          document.application === "Wybierz..."
            ? ""
            : document.application,
        code:
          document.code === undefined || document.code === "Wybierz..."
            ? ""
            : document.code,
        glossDegree:
          document.glossDegree === undefined ||
          document.glossDegree === "Wybierz..."
            ? ""
            : document.glossDegree,
        group:
          document.group === undefined || document.group === "Wybierz..."
            ? ""
            : document.group,
        subGroup:
          document.subGroup === undefined || document.subGroup === "Wybierz..."
            ? ""
            : document.subGroup,
        type:
          document.type === undefined || document.type === "Wybierz..."
            ? ""
            : document.type,
        uom:
          document.uom === undefined || document.uom === "Wybierz..."
            ? ""
            : document.uom,
        otherImages:
          document.otherImages === undefined ? [] : document.otherImages,
        other: document.other === undefined ? [] : document.other,
        mainImage: document.mainImage === undefined ? "" : document.mainImage,
        price: document.price === undefined ? "" : document.price,
        currencyFormat:
          document.currencyFormat === undefined ||
          document.currencyFormat === "Wybierz..."
            ? "zł"
            : "zł",
        vat:
          document.vat === undefined || document.vat === "Wybierz..."
            ? "23%"
            : "23%",
        description:
          document.description === undefined ? "" : document.description,
        availability:
          document.availability === undefined ||
          document.availability === "Wybierz..."
            ? "czerwony"
            : document.availability,
      };
      let updates = {};
      updates["/products/" + document.id] = postData;
      firebase
        .database()
        .ref()
        .update(updates, (error) => {
          if (error) {
            alert("Edycja wpisu nie powiodła się. Skontaktuj się z adminem.");
          } else {
            this.updateDocuments(document, mode);
            alert("Edycja wpisu powiodła się.");
          }
        });
    }
  }

  getData() {
    return axios
      .get(productsAPI)
      .then((res) => {
        var products = Object.values(res.data);
        var myKeys = Object.keys(res.data);
        for (let i = 0; i < products.length; i++) {
          if (products[i] === null) {
            products.splice(i, 1);
            myKeys.splice(i, 1);
            i = i - 1;
          } else {
            products[i].id = myKeys[i];
          }
        }
        return products;
      })
      .then((prod) => {
        this.setState({ documents: prod });
      })
      .catch((error) => {
        alert("Nie można pobrać listy produktów - odśwież stronę");
      });
  }

  componentDidMount() {
    this.getData();
  }

  render() {
    return (
      <div style={{ marginTop: "5vh" }}>
        <button
          style={{ marginBottom: "2vh" }}
          id="add-product"
          onClick={() => (window.location.href = "Sklep/Glowna")}
        >
          Podejrzyj w sklepie
        </button>
        <button
          id="add-product"
          onClick={() => {
            this.toggleModal();
            this.handleCurrentDocument({}, "new");
          }}
        >
          Dodaj produkt
        </button>

        <Modal
          id="modal_with_forms"
          isOpen={this.state.isOpen}
          closeTimeoutMS={150}
          ariaHideApp={false}
          contentLabel="modalA"
          shouldCloseOnOverlayClick={true}
          className="modal-form"
          aria={{
            labelledby: "heading",
            describedby: "fulldescription",
          }}
        >
          <div id="fulldescription" tabIndex="0" role="document">
            <Form
              document={this.state.document}
              handleCurrentDocument={this.handleCurrentDocument}
              firebaseAction={this.firebaseAction}
              toggleModal={this.toggleModal}
              mode={this.state.mode}
            />
          </div>
        </Modal>
        <div className="products-list">
          <Products
            documents={this.state.documents}
            handleCurrentDocument={this.handleCurrentDocument}
            firebaseAction={this.firebaseAction}
            toggleModal={this.toggleModal}
          />
        </div>
      </div>
    );
  }
}

export default MainView;
