import React from "react";
import styles from "./IntroPage.module.css";
import { withRouter } from "react-router-dom";
import CssModules from "react-css-modules";
import Button from "../Animations/Button";

const IntroPageDesktop = (props) => {
  const nextPath = (path) => {
    props.history.push(path);
  };

  return (
    <div styleName="intro_page">
      <div styleName="contact">
        <h1>Szybki kontakt</h1>
        <h5>
          Skontaktuj się bezpośrednio z nami.<br></br> Wystarczy kliknąć w
          przycisk poniżej.
        </h5>
        <img src="./IntroPagePics/contact.jpg" alt="" />
        <div onClick={() => nextPath("/Kontakt")} styleName="contact_button">
          <Button
            whileHover={1.2}
            whileTap={0.8}
            id="input"
            type="image"
            src="./IntroPagePics/contact_button.png"
          />
        </div>
      </div>
      <div styleName="shop_welcome">
        <div styleName="shop">
          <h1>Sklep internetowy</h1>
          <h5>
            Skontaktuj się bezpośrednio z nami.<br></br> Wystarczy kliknąć w
            przycisk poniżej.
          </h5>
          <img src="./IntroPagePics/shop.jpg" alt="" />
          <div onClick={() => nextPath("/Sklep")} styleName="shop_button">
            <Button
              whileHover={1.2}
              whileTap={0.8}
              src="./IntroPagePics/shop_button.png"
              id="input"
              type="image"
            />
          </div>
        </div>
        <div styleName="welcome">
          <h1>Przedstawiamy się</h1>
          <h5>
            Wejdź na stronę i zobacz co możemy<br></br> zaoferować Tobie i
            Twojej firmie.
          </h5>
          <img src="./IntroPagePics/welcome.jpg" alt="" />
          <div
            onClick={() => nextPath("/StronaGlowna")}
            styleName="welcome_button"
          >
            <Button
              whileHover={1.2}
              whileTap={0.8}
              src="./IntroPagePics/welcome_button.png"
              id="input"
              type="image"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(
  CssModules(IntroPageDesktop, styles, { allowMultiple: true })
);
