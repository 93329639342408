import React from "react";
import styles from "./HistoryPage.module.css";
import CssModules from "react-css-modules";

const Story2 = () => {
  return (
    <div styleName="story2">
      <h4 styleName="story2-year">2013</h4>
      <h4 styleName="story2-title">Otwarcie punktu handlowego</h4>
      <p>
        Po 9 latach przyszedł czas na stworzenie punktu handlowego w Rybniku
        przy ul. Zebrzydowickiej 103. Umożliwiło nam to poszerzenie asortymentu,
        jak i stworzenie mieszalni lakierów i farb poliuretanowych do drewna.
        Tym samym postawiliśmy na rozwój i kompleksowe zaopatrzenie stolarzy.
      </p>
    </div>
  );
};

export default CssModules(Story2, styles, { allowMultiple: true });
