import React from "react";
import styles from "./Main.module.css";
import CssModules from "react-css-modules";
import { withRouter } from "react-router-dom";
import Button from "../Animations/Button";
import "react-awesome-slider/dist/styles.css";
import CheckOffer from "./CheckOffer";
import Newsletter from "./Newsletter";
import RedirectSection from "./RedirectSection";
import Footer from "./Footer";
import { updateFilters, removeFilters } from "../services/filters/actions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import firebase from "../services/firebase/firebase";
import FrequentlyBought from "./FrequentlyBought";

const Shop = (props) => {
  const goToShop = (path, group) => {
    const filters = props.filters;
    filters.length = 0;
    props.removeFilters([filters]);
    filters.push([group, true]);
    props.updateFilters(filters);
    props.history.push(path);
  };

  const goToLoginPage = () => {
    const user = firebase.auth().currentUser;
    if (user) {
      props.history.push("/Sklep/Glowna");
    } else {
      props.history.push("/Sklep/Logowanie");
    }
  };

  return (
    <div styleName="container">
      <div styleName="main-section">
        <img src="./IntroPagePics/shop.jpg" alt="" />
        <h3>Kupuj wygodniej</h3>
        <h4>przez sklep internetowy</h4>
        <div styleName="display-flex">
          <div onClick={() => goToLoginPage()}>
            <Button
              whileHover={1.1}
              whileTap={0.8}
              src="./ShopPics/zaloguj.svg"
              id="input"
              type="image"
            />
          </div>
          <p>ZALOGUJ SIĘ</p>
        </div>
        <section styleName="square">
          <div styleName="assets">
            <div styleName="free-shipping">
              <img src="./ShopPics/dostawa.svg" alt="" />
              <div styleName="display-block">
                <p>
                  <strong>
                    Darmowa dostawa przy zakupie powyżej 1000 zł brutto
                  </strong>
                </p>
              </div>
            </div>
            <div styleName="customer-satisfaction">
              <img src="./ShopPics/klient.svg" alt="" />
              <div styleName="display-block">
                <p>
                  <strong>99% Zadowolonych Klientów</strong>
                </p>
              </div>
            </div>
            <div styleName="price-guarantee">
              <img src="./ShopPics/gwarancja_ceny.svg" alt="" />
              <div styleName="display-block">
                <p>
                  <strong>Gwarancja Ceny</strong>
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div styleName="categories">
        <div styleName="row-1">
          <div
            onClick={() =>
              goToShop(
                "/Sklep/Glowna/Lakiery,%20farby,%20oleje",
                "Lakiery, farby, oleje"
              )
            }
          >
            <Button
              whileHover={1.2}
              whileTap={0.8}
              src="./CategoriesPics/shop_lakiery.jpg"
              id="input"
              type="image"
            />
            <p>Lakiery, farby, oleje</p>
          </div>
          <div onClick={() => goToShop("/Sklep/Glowna/Kleje", "Kleje")}>
            <Button
              whileHover={1.2}
              whileTap={0.8}
              src="./CategoriesPics/shop_kleje.jpg"
              id="input"
              type="image"
            />
            <p>Kleje</p>
          </div>
          <div onClick={() => goToShop("/Sklep/Glowna/Silikony", "Silikony")}>
            <Button
              whileHover={1.2}
              whileTap={0.8}
              src="./CategoriesPics/shop_silikony.jpg"
              id="input"
              type="image"
            />
            <p>Silikony</p>
          </div>
          <div
            onClick={() =>
              goToShop("/Sklep/Glowna/Bejce,%20patyny", "Bejce, patyny")
            }
          >
            <Button
              whileHover={1.2}
              whileTap={0.8}
              src="./CategoriesPics/shop_bejce.jpg"
              id="input"
              type="image"
            />
            <p>Bejce, patyny</p>
          </div>
          <div
            onClick={() =>
              goToShop(
                "/Sklep/Glowna/Uszczelki%20drzwiowe",
                "Uszczelki drzwiowe"
              )
            }
          >
            <Button
              whileHover={1.2}
              whileTap={0.8}
              src="./CategoriesPics/shop_uszczelki_drzwiowe.jpg"
              id="input"
              type="image"
            />
            <p>Uszczelki dzrzwiowe</p>
          </div>
          <div onClick={() => goToShop("/Sklep/Glowna/Narzędzia", "Narzędzia")}>
            <Button
              whileHover={1.2}
              whileTap={0.8}
              src="./CategoriesPics/shop_narzedzia.jpg"
              id="input"
              type="image"
            />
            <p>Narzędzia</p>
          </div>
          <div
            onClick={() =>
              goToShop("/Sklep/Glowna/Materiały%20ścierne", "Materiały ścierne")
            }
          >
            <Button
              whileHover={1.2}
              whileTap={0.8}
              src="./CategoriesPics/shop_materialy_scierne.jpg"
              id="input"
              type="image"
            />
            <p>Materiały ścierne</p>
          </div>
          <div
            onClick={() =>
              goToShop(
                "/Sklep/Glowna/Okleiny,%20obłogi,%20lamele",
                "Okleiny, obłogi, lamele"
              )
            }
          >
            <Button
              whileHover={1.2}
              whileTap={0.8}
              src="./CategoriesPics/shop_okleiny.jpg"
              id="input"
              type="image"
            />
            <p>Okleiny, obłogi, lamele</p>
          </div>
          <div
            onClick={() =>
              goToShop("/Sklep/Glowna/Obrzeża%20naturalne", "Obrzeża naturalne")
            }
          >
            <Button
              whileHover={1.2}
              whileTap={0.8}
              src="./CategoriesPics/shop_obrzeza_naturalne.jpg"
              id="input"
              type="image"
            />

            <p>Obrzeża naturalne</p>
          </div>
          <div
            onClick={() =>
              goToShop("/Sklep/Glowna/Elementy%20złączne", "Elementy złączne")
            }
          >
            <Button
              whileHover={1.2}
              whileTap={0.8}
              src="./CategoriesPics/shop_elementy_zlaczne.jpg"
              id="input"
              type="image"
            />

            <p>Elementy złączne</p>
          </div>
          <div onClick={() => goToShop("/Sklep/Glowna/Płyty", "Płyty")}>
            <Button
              whileHover={1.2}
              whileTap={0.8}
              src="./CategoriesPics/shop_plyty.jpg"
              id="input"
              type="image"
            />
            <p>Płyty</p>
          </div>
          <div
            onClick={() =>
              goToShop("/Sklep/Glowna/Okucia%20budowlane", "Okucia budowlane")
            }
          >
            <Button
              whileHover={1.2}
              whileTap={0.8}
              src="./CategoriesPics/shop_okucia.jpg"
              id="input"
              type="image"
            />
            <p>Okucia budowlane</p>
          </div>
          <div onClick={() => goToShop("/Sklep/Glowna/Krzesła", "Krzesła")}>
            <Button
              whileHover={1.2}
              whileTap={0.8}
              src="./CategoriesPics/shop_krzesla.jpg"
              id="input"
              type="image"
            />
            <p>Krzesła</p>
          </div>
          <div onClick={() => goToShop("/Sklep/Glowna/Podłogi", "Podłogi")}>
            <Button
              whileHover={1.2}
              whileTap={0.8}
              src="./CategoriesPics/shop_podlogi.jpg"
              id="input"
              type="image"
            />
            <p>Podłogi</p>
          </div>
        </div>
      </div>
      <FrequentlyBought />
      <div styleName="why-us">
        <h5>Dlaczego</h5>
        <p>warto kupować u nas?</p>
      </div>
      <div styleName="why-us-list">
        <div>
          <img src="./ShopPics/szybka_dostawa.svg" alt="" />
          <h5>Szybka dostawa</h5>
          <p>
            Odbiór osobisty na drugi dzień od zamówienia, przy opcji dostawy
            kurierem wysyłka do 3 dni roboczych od otrzymania zamówienia
          </p>
        </div>
        <div>
          <img src="./ShopPics/latwe_platnosci.svg" alt="" />
          <h5>Doradztwo na wysokim poziomie</h5>
          <p>
            Służymy pomocą przy doborze produktów. Zależy nam aby każdy Klient
            był w 100% zadowolony.
          </p>
        </div>
        <div>
          <img src="./ShopPics/produkt_dopasowany_do_klienta.svg" alt="" />
          <h5>Szeroki asortyment</h5>
          <p>95% oferowanych produktów na stanie magazynowym</p>
        </div>
        <div>
          <img src="./ShopPics/najlepsze_marki.png" alt="Brak zdjęcia" />
          <h5>Najlepsze marki</h5>
          <p>
            Proponujemy naszym Klientom Produkty o wysokiej jakości i od
            sprawdzonych Producentów.
          </p>
        </div>
      </div>
      <CheckOffer />
      <Newsletter />
      <RedirectSection />
      <Footer />
    </div>
  );
};

Shop.propTypes = {
  updateFilters: PropTypes.func.isRequired,
  filters: PropTypes.array.isRequired,
  removeFilters: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  filters: state.filterObj.filters,
});

export default connect(mapStateToProps, { removeFilters, updateFilters })(
  withRouter(
    CssModules(Shop, styles, {
      allowMultiple: true,
    })
  )
);
