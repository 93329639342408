import React, { useState } from "react";
import styles from "./Main.module.css";
import CssModules from "react-css-modules";
import { withRouter } from "react-router-dom";
import freq1 from "../images/freq1.png";
import freq2 from "../images/freq2.png";
import freq3 from "../images/freq3.png";
import freq4 from "../images/freq4.png";

const FrequentlyBought = (props) => {
  const nextPath = (path) => {
    props.history.push(path);
  };
  return (
    <div>
      <div styleName="frequently-bought">
        <h1>Najczęściej kupowane</h1>
        <button
          onClick={() => nextPath("/Sklep/Glowna")}
          styleName="orange-button"
        >
          ZOBACZ WIĘCEJ
        </button>
      </div>
      <div styleName="frequently-bought-gallery">
        <div
          onClick={() =>
            nextPath(
              "/Sklep/Produkt/Uszczelki%20drzwiowe/Deventer/Uszczelka%206512a%20szara/513"
            )
          }
        >
          <img src={freq1} alt="" />
          <h5>Uszczelka</h5>
          <p>2,00zł brutto</p>
        </div>
        <div
          onClick={() =>
            nextPath(
              "/Sklep/Produkt/Lakiery,%20farby,%20oleje/Hesse%20Lignal/Lakier%20poliakrylowy%20UV%205,5L%20komplet%20z%20katalizatorem/332"
            )
          }
        >
          <img src={freq2} alt="" />
          <h5>Lakier poliakrylowy UV 5,5L komplet z katalizatorem</h5>
          <p>203,50zł brutto</p>
        </div>
        <div
          onClick={() =>
            nextPath(
              "/Sklep/Produkt/Okucia%20budowlane/Simonswerk/Simonsverk%20TE%20340%20SH/226"
            )
          }
        >
          <img src={freq3} alt="" />
          <h5>Simonsverk</h5>
          <p>137,00zł brutto</p>
        </div>
        <div
          onClick={() =>
            nextPath(
              "/Sklep/Produkt/Lakiery,%20farby,%20oleje/Hesse%20Lignal/Olej%20parkietowy/117"
            )
          }
        >
          <img src={freq4} alt="" />
          <h5>Olej parkietowy</h5>
          <p>90zł brutto</p>
        </div>
      </div>
    </div>
  );
};
export default withRouter(
  CssModules(FrequentlyBought, styles, {
    allowMultiple: true,
  })
);
