import React from "react";
import firstCatAlcea from "../../images/alcea_logo.png";
import firstCatHesse from "../../images/hesse_logo.png";
import firstCatSivam from "../../images/logo_sivam.png";
import firstCatSikk from "../../images/sikkens_logo.png";
// import iMoveToShop from "../../images/btnZrobZakupy.png";
import parImg from "../../images/newlineText.png";
import styles from "./CategoriesDetails.module.scss";
import { withRouter } from "react-router-dom";
//mobile na 15
//{window.innerWidth >= 650 ? desktopImage : mobileImage}
export default withRouter(function FirstCategory(props) {
  return (
    <div>
      <div className={styles.text_box}>
        <h1 className={styles.text_box_title}>Lakiery, farby, oleje</h1>
        <h2 className={styles.text_box_subtitle}>Zakres sprzedaży:</h2>

        <div className={styles.text_box_item}>
          <p>
            <img src={parImg} className={styles.text_img} alt="img" />
            Lakiery i farby do wewnątrz i na zewnątrz na{" "}
            {window.innerWidth <= 651 ? "" : "drewno"}
          </p>
          {window.innerWidth <= 651 ? (
            <p className={styles.text_space}> drewno i na MDF</p>
          ) : (
            <p className={styles.text_space}>i na MDF</p>
          )}
          {window.innerWidth <= 651 ? (
            <div>
              <p>
                <img src={parImg} className={styles.text_img} alt="img" />
                Oleje na meble oraz podłogi w
              </p>
              <p className={styles.text_space}>w różnej gamie kolorystycznej</p>
            </div>
          ) : (
            <p>
              <img src={parImg} className={styles.text_img} alt="img" />
              Oleje na meble oraz podłogi w różnej gamie kolorystycznej
            </p>
          )}
          <p>
            <img src={parImg} className={styles.text_img} alt="img" />
            Systemy antykorozyjne na konstrukcje stalowe
          </p>
        </div>
        <h2 className={styles.text_box_subtitle}>
          Posiadamy własną mieszalnię farb:
        </h2>
        <div className={styles.text_box_item}>
          {window.innerWidth <= 651 ? (
            <div>
              <p>
                <img src={parImg} className={styles.text_img} alt="img" />
                Mieszalnia farb poliuretanowych w kolorystyce
              </p>
              <p className={styles.text_space}>RAL i NCS.</p>
            </div>
          ) : (
            <div>
              <p>
                <img src={parImg} className={styles.text_img} alt="img" />
                Mieszalnia farb poliuretanowych w kolorystyce
              </p>
              <p className={styles.text_space}> RAL i NCS.</p>
            </div>
          )}
          {window.innerWidth >= 651 ? (
            <p>
              <img src={parImg} className={styles.text_img} alt="img" />
              Mieszalnia farb wodnych w kolorystyce RAL i NCS.
            </p>
          ) : (
            <div>
              <p>
                <img src={parImg} className={styles.text_img} alt="img" />
                Mieszalnia farb wodnych w kolorystyce
              </p>
              <p className={styles.text_space}>RAL i NCS.</p>
            </div>
          )}
          {window.innerWidth <= 651 ? (
            <div>
              <p>
                <img src={parImg} className={styles.text_img} alt="img" />
                Możliwość dobrania koloru za pomocą
              </p>
              <p className={styles.text_space}>fotospektometru</p>
            </div>
          ) : (
            <p>
              <img src={parImg} className={styles.text_img} alt="img" />
              Możliwość dobrania koloru za pomocą fotospektometru
            </p>
          )}
        </div>

        {props.shpButton}

        {window.innerWidth >= 651 ? (
          <footer className={styles.entp_imgs}>
            <div>
              <img
                className={styles.entp_img_lakiery}
                src={firstCatHesse}
                alt="img"
              />
            </div>
            <div>
              <img
                className={styles.entp_img_lakiery}
                src={firstCatSivam}
                alt="img"
              />
            </div>
            <div>
              <img
                className={styles.entp_img_lakiery}
                src={firstCatSikk}
                alt="img"
              />
            </div>
            <div>
              <img
                className={styles.entp_img_lakiery}
                src={firstCatAlcea}
                alt="img"
              />
            </div>
          </footer>
        ) : null}
      </div>
    </div>
  );
});
