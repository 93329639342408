import React, { Component } from "react";
import PropTypes from "prop-types";
import "./style.scss";

class Checkbox extends Component {
  static propTypes = {
    objName: PropTypes.string.isRequired,
    handleFilter: PropTypes.func.isRequired,
    isFiltered: PropTypes.bool.isRequired,
    filterItems: PropTypes.number.isRequired,
    filters: PropTypes.array.isRequired,
  };

  state = {
    isChecked: false,
  };

  toggleCheckboxChange = () => {
    const { handleFilter, objName, isFiltered } = this.props;

    handleFilter(objName, !this.state.isChecked, true);
    this.setState(({ isChecked }) => ({
      isChecked: !isChecked,
    }));
  };

  showQuantity = () => {
    let toShow = "";
    if (this.props.filterItems > 0) {
      toShow = (
        <span className="filter-checkbox-text">
          {" "}
          ({this.props.filterItems}){" "}
        </span>
      );
    }
    return toShow;
  };
  render() {
    const { objName, classes } = this.props;
    const isFiltered = Array.isArray(
      this.props.filters.find((filt) => filt[0] === this.props.objName)
    );
    return (
      <div className="checkmark">
        <input
          key={objName}
          //instead of checkbox
          className="filter-checkbox"
          type="checkbox"
          value={objName}
          checked={isFiltered}
          onChange={this.toggleCheckboxChange}
        />
        <span className="filter-checkbox-label">{objName}</span>
        {this.showQuantity()}
      </div>
    );
  }
}

export default Checkbox;
