import React from "react";
import styles from "./MainPage.module.scss";
import CssModules from "react-css-modules";
import { withRouter } from "react-router-dom";
import Button from "../Animations/Button";

const Mainpage = (props) => {
  const nextPath = (path) => {
    props.history.push(path);
  };

  return (
    <div styleName="container">
      <div styleName="welcome">
        <input type="image" src="./MainPagePics/welcome.jpeg" alt="" />
      </div>
      <div styleName="blur">
        <h2>MIŁOŚĆ I PASJA</h2>
        <h1>DO DREWNA</h1>
        <p>Kliknij poniższy przycisk, żeby zobaczyć naszą ofertę.</p>
        <div onClick={() => nextPath("/Kategorie")} styleName="welcome-button">
          <Button
            whileHover={1.2}
            whileTap={0.8}
            src="./MainPagePics/welcome-button.png"
            id="input"
            type="image"
          />
        </div>
      </div>

      <div styleName="icons-bottom">
        <div styleName="shop-button" onClick={() => nextPath("/Sklep")}>
          <Button
            whileHover={1.2}
            whileTap={0.8}
            src="./MainPagePics/shop.png"
            id="input"
            type="image"
          />
        </div>
        <div
          styleName="stock-button"
          onClick={() => nextPath("/Kategoria/Lakiery,%20farby,%20oleje")}
        >
          <Button
            whileHover={1.2}
            whileTap={0.8}
            src="./MainPagePics/stock.png"
            id="input"
            type="image"
          />
        </div>
        <div styleName="contact-button" onClick={() => nextPath("/Kontakt")}>
          <Button
            whileHover={1.2}
            whileTap={0.8}
            src="./MainPagePics/contact.png"
            id="input"
            type="image"
          />
        </div>
      </div>
    </div>
  );
};
export default withRouter(
  CssModules(Mainpage, styles, {
    allowMultiple: true,
  })
);
