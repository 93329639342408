import React from "react";
import ContactSubPage from "./ContactSubPage";
import ShopSubPage from "./ShopSubPage";
import WelcomeSubPage from "./WelcomeSubPage";
import IntroPageDesktop from "./IntroPageDesktop";
import { Swipeable } from "react-swipeable";

class Carousel extends React.Component {
  state = {
    desktop: null,
    i: 1,
  };

  UNSAFE_componentWillMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  updateDimensions() {
    if (window.innerWidth > 550) {
      this.setState({ desktop: true });
    } else {
      this.setState({ desktop: false });
    }
  }

  onSwiped(direction) {
    let newI = this.state.i + direction;
    if (newI === 0) {
      newI = 3;
    } else if (newI === 4) {
      newI = 1;
    }
    this.setState({ i: newI });
  }

  listOfComponents() {
    if (this.state.i === 1) {
      return <WelcomeSubPage />;
    } else if (this.state.i === 2) {
      return <ShopSubPage />;
    } else if (this.state.i === 3) {
      return <ContactSubPage />;
    }
  }

  render() {
    return (
      <div>
        {this.state.desktop === false ? (
          <Swipeable
            trackMouse
            preventDefaultTouchmoveEvent
            onSwipedLeft={() => this.onSwiped(+1)}
            onSwipedRight={() => this.onSwiped(-1)}
          >
            {this.listOfComponents()}
          </Swipeable>
        ) : (
          <IntroPageDesktop />
        )}
      </div>
    );
  }
}

export default Carousel;
