import React from "react";
import iKovax from "../../images/kovax-logo.png";
import iSia from "../../images/Sia_Abrasives_logo.png";
import i3m from "../../images/3m_logo_2.png";
import iIndasa from "../../images/indasa.png";
// import iMoveToShop from "../../images/btnZrobZakupy.png";
import parImg from "../../images/newlineText.png";
import styles from "./CategoriesDetails.module.scss";
import { withRouter } from "react-router-dom";

export default withRouter(function SeventhCategory(props) {
  return (
    <div>
      <div className={styles.text_box}>
        <h1 className={styles.text_box_title}>Materiały ścierne</h1>
        <h2 className={styles.text_box_subtitle}>Zakres sprzedaży:</h2>

        <div className={styles.text_box_item}>
          <p>
            <img src={parImg} className={styles.text_img} alt="" />
            Krążki ścierne
          </p>
          <p>
            <img src={parImg} className={styles.text_img} alt="" />
            Papiery na sucho
          </p>
          <p>
            <img src={parImg} className={styles.text_img} alt="" />
            Taśmy lakiernicze
          </p>
          <p>
            <img src={parImg} className={styles.text_img} alt="" />
            Materiały polerskie
          </p>
          <p>
            <img src={parImg} className={styles.text_img} alt="" />
            Pasy bezkońcowe
          </p>
        </div>
        {props.shpButton}
        {/* <div className={styles.moveTo_sec}>
          <img
            className={styles.moveTo_img}
            src={iMoveToShop}
            alt=""
            onClick={() => nextPath("/shop")}
          ></img>
        </div> */}
        {window.innerWidth >= 651 ? (
          <footer className={styles.entp_imgs}>
            <div>
              <img className={styles.entp_img} src={iKovax} alt="" />
            </div>
            <div>
              <img className={styles.entp_img} src={iSia} alt="" />
            </div>
            <div>
              <img className={styles.entp_img} src={i3m} alt="" />
            </div>
            <div>
              <img className={styles.entp_img} src={iIndasa} alt="" />
            </div>
          </footer>
        ) : null}
      </div>
    </div>
  );
});
