import React from "react";
import "./product-form.css";
import AdminPanel from "./admin-panel";
import firebase from "../services/firebase/firebase";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      login: "",
      pass: "",
      validated: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.checkCurrentUser = this.checkCurrentUser.bind(this);
  }

  handleInputChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  handleSubmit() {
    firebase
      .auth()
      .signInWithEmailAndPassword(this.state.login, this.state.pass)
      .catch(function (error) {
        // ...
      });
    this.checkCurrentUser();
  }

  checkCurrentUser() {
    const user = firebase.auth().currentUser;
    if (user) {
      this.setState({ validated: true });
      document.cookie = "validated";
    } else {
      this.setState({ validated: false });
    }
  }

  render() {
    return (
      <div>
        {document.cookie !== "validated" ? (
          <div className="login-form">
            <label htmlFor="login">Login</label>
            <input
              type="text"
              name="login"
              onChange={this.handleInputChange}
            ></input>
            <label htmlFor="pass">Hasło</label>
            <input
              type="password"
              name="pass"
              onChange={this.handleInputChange}
            ></input>
            <button onClick={this.handleSubmit}>Zaloguj się</button>
          </div>
        ) : (
          <AdminPanel />
        )}
      </div>
    );
  }
}

export default Login;
