import { FETCH_PRODUCTS } from "./actionTypes";
import { GET_PRODUCTLIST } from "./actionTypes";
import { GET_PRODUCTSLIST_UNFILTERED } from "./actionTypes";
import axios from "axios";
import {
  groups,
  units,
  productsAPI,
  apply,
  prefixToAdd,
  subGroups,
} from "../util";
import PropTypes from "prop-types";

const compare = {
  lowestprice: (a, b) => {
    if (a.price < b.price) return -1;
    if (a.price > b.price) return 1;
    return 0;
  },
  highestprice: (a, b) => {
    if (a.price > b.price) return -1;
    if (a.price < b.price) return 1;
    return 0;
  },
};

function actionCreator(products, productsList, productsListUF) {
  return (dispatch) => {
    dispatch({
      type: FETCH_PRODUCTS,
      payload: products,
    });

    dispatch({
      type: GET_PRODUCTLIST,
      payload: productsList,
    });

    dispatch({
      type: GET_PRODUCTSLIST_UNFILTERED,
      payload: productsListUF,
    });
  };
}

function filterQuantity(products, quantity, subsiteNumber) {
  subsiteNumber -= 1;
  let j = Number(quantity) * Number(subsiteNumber);
  j = Number(j) + Number(quantity);

  if (products.length > quantity) {
    if (subsiteNumber !== undefined) {
      products = products.slice(quantity * subsiteNumber, j);
    } else {
      products = products.slice(0, quantity);
    }
  }
  return products;
}

export const fetchProducts = (
  filters,
  sortBy,
  callback,
  quantity,
  subsiteNumber,
  props
) => (dispatch) => {
  if (props !== undefined) {
    console.log("mamy dane");

    let products = props;

    let productsListUF = products;

    if (!!filters && filters.length > 0) {
      var priceFrom = filters.find((f) => f[0] === "priceFrom");
      if (priceFrom !== undefined) {
        priceFrom = parseFloat(priceFrom[1]);
        var priceTo = parseFloat(filters.find((f) => f[0] === "priceTo")[1]);

        products = products.filter(
          (p) => p.price >= priceFrom && p.price <= priceTo
        );
      }

      //change 20.10.26
      var productSearch = filters.find((f) =>
        f[0].includes("productSearchName")
      );
      if (productSearch !== undefined) {
        products = products.filter((p) =>
          p.name.toLowerCase().includes(productSearch[1].toLowerCase())
        );
      }

      var productSearch = filters.find((f) =>
        f[0].includes("productSearchCode")
      );
      if (productSearch !== undefined) {
        products = products.filter((p) =>
          p.code.toLowerCase().includes(productSearch[1].toLowerCase())
        );
      }

      var filterGroups = filters.filter((f) => groups.find((g) => f[0] === g));
      if (filterGroups !== undefined && filterGroups.length > 0) {
        products = products.filter((p) =>
          filters.find((f) => f[0] === p.group)
        );
      }

      var filterSubGroups = filters.filter((f) =>
        subGroups.find((g) => f[0] === g)
      );
      if (filterSubGroups !== undefined && filterSubGroups.length > 0) {
        products = products.filter((p) =>
          filters.find((f) => f[0] === p.subGroup)
        );
      }

      var filterUnits = filters.filter((f) => units.find((u) => f[0] === u));
      if (filterUnits !== undefined && filterUnits.length > 0) {
        products = products.filter((p) => filters.find((f) => f[0] === p.unit));
      }

      var filterApply = filters.filter((f) => apply.find((a) => f[0] === a));
      if (filterApply !== undefined && filterApply.length > 0) {
        products = products.filter((p) =>
          filters.find((f) => f[0] === p.application)
        );
      }

      var producer = filters.find((f) => f[1] === "producer");
      if (producer !== undefined) {
        products = products.filter((p) =>
          filters.find((f) => (f[0] === p.producer) & (f[1] === "producer"))
        );
      }
    }

    if (!!sortBy) {
      products = products.sort(compare[sortBy]);
    }

    //Array.from(
    let productsList = products;

    if (!!subsiteNumber && !!quantity) {
      products = filterQuantity(products, quantity, subsiteNumber);
    }

    if (!!callback) {
      callback();
    }

    return dispatch(actionCreator(products, productsList, productsListUF));
  } else {
    return axios
      .get(productsAPI)

      .then((tp) => {
        console.log("nowe pobranie");
        var tempProducts = Object.values(tp.data);
        var myKeys = Object.keys(tp.data);
        for (let i = 0; i < tempProducts.length; i++) {
          if (tempProducts[i] === null) {
            tempProducts.splice(i, 1);
            myKeys.splice(i, 1);
            i = i - 1;
          } else {
            tempProducts[i].docID = myKeys[i];

            tempProducts[i].mainImage = prefixToAdd + tempProducts[i].mainImage;

            let checkPrice =
              Math.round(
                (+(tempProducts[i].price * 0.23) +
                  +tempProducts[i].price +
                  Number.EPSILON) *
                  100
              ) / 100;

            //w razie jakby trzeba bylo wyciagnac do jsona i ponownie wrzucic do firebase
            // tempProducts[i].mainImage = tempProducts[i].mainImage;
            // let checkPrice =
            //   Math.round(
            //     (+(tempProducts[i].price / 1.23) + Number.EPSILON) * 100
            //   ) / 100;
            // console.log(JSON.stringify(tempProducts));

            tempProducts[i].price = isNaN(checkPrice) ? 0 : checkPrice;
            tempProducts[i].quantity = 1;
          }
        }

        console.log(tempProducts);
        let products = tempProducts;

        let productsListUF = tempProducts;

        if (!!filters && filters.length > 0) {
          var priceFrom = filters.find((f) => f[0] === "priceFrom");
          if (priceFrom !== undefined) {
            priceFrom = parseFloat(priceFrom[1]);
            var priceTo = parseFloat(
              filters.find((f) => f[0] === "priceTo")[1]
            );

            products = products.filter(
              (p) => p.price >= priceFrom && p.price <= priceTo
            );
          }

          var productSearch = filters.find((f) =>
            f[0].includes("productSearchName")
          );
          if (productSearch !== undefined) {
            products = products.filter((p) =>
              p.name.toLowerCase().includes(productSearch[1].toLowerCase())
            );
          }

          var productSearch = filters.find((f) =>
            f[0].includes("productSearchCode")
          );
          if (productSearch !== undefined) {
            products = products.filter((p) =>
              p.code.toLowerCase().includes(productSearch[1].toLowerCase())
            );
          }

          var filterGroups = filters.filter((f) =>
            groups.find((g) => f[0] === g)
          );
          if (filterGroups !== undefined && filterGroups.length > 0) {
            products = products.filter((p) =>
              filters.find((f) => f[0] === p.group)
            );
          }

          var filterSubGroups = filters.filter((f) =>
            subGroups.find((g) => f[0] === g)
          );
          if (filterSubGroups !== undefined && filterSubGroups.length > 0) {
            products = products.filter((p) =>
              filters.find((f) => f[0] === p.subGroup)
            );
          }

          var filterUnits = filters.filter((f) =>
            units.find((u) => f[0] === u)
          );
          if (filterUnits !== undefined && filterUnits.length > 0) {
            products = products.filter((p) =>
              filters.find((f) => f[0] === p.unit)
            );
          }

          var filterApply = filters.filter((f) =>
            apply.find((a) => f[0] === a)
          );
          if (filterApply !== undefined && filterApply.length > 0) {
            products = products.filter((p) =>
              filters.find((f) => f[0] === p.application)
            );
          }

          var producer = filters.find((f) => f[1] === "producer");
          if (producer !== undefined) {
            products = products.filter((p) =>
              filters.find((f) => (f[0] === p.producer) & (f[1] === "producer"))
            );
          }
        }

        if (!!sortBy) {
          products = products.sort(compare[sortBy]);
        }

        //Array.from(
        let productsList = products;

        if (!!subsiteNumber && !!quantity) {
          products = filterQuantity(products, quantity, subsiteNumber);
        }

        if (!!callback) {
          callback();
        }

        return dispatch(actionCreator(products, productsList, productsListUF));
      })

      .catch((error) => {
        alert(error);
      });
  }
};

fetchProducts.propTypes = {
  productsListUF: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  productsListUF: state.apiProductsUF.productsListUF,
});
