import React from "react";
import { Card, CardText } from "reactstrap";
import styles from "./CategoriesPage.module.css";
import CategoriesContainer from "../Categories/CategoriesContainer.js";
import Background from "../images/BG_Oferta.png";
import BackgroundMob from "../images/mob_kat_glowna.png";
import btnZapraszamy from "../images/btnZapraszamy.png";

//https://codesandbox.io/s/app-store-ui-using-react-and-framer-motion-ecgc2?file=/src/Card/index.tsx
//implementacja kart z framera

/*<Button variant="light" 
              className={styles.card_button}
              onClick={() => this.handleScrollToElem()}
              >       
              <span aria-hidden="true">ZAPRASZAMY –></span>                
            </Button>*/

class CategoryPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      srcMobile: BackgroundMob,
      pic: Background,
      imgPath: "",
    };
    this.handleScrollToElem = this.handleScrollToElem.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  updateDimensions() {
    this.forceUpdate();
    if (window.innerWidth <= 651) {
      this.setState({ imgPath: this.srcMobile });
    } else {
      this.setState({ imgPath: this.pic });
    }
  }

  handleScrollToElem() {
    var element = null;
    if (window.innerWidth >= 651) {
      element = document.getElementById("catContainer");
      element.scrollIntoView({ behavior: "smooth", block: "start", top: 100 });
    } else {
      element = document.getElementById("cards");
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
        top: 0,
      });
    }
  }

  componentDidMount() {
    this.updateDimensions();

    window.addEventListener("resize", this.updateDimensions());
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions());
  }

  getImgPath = () =>
    window.innerWidth >= 651 ? this.state.pic : this.state.srcMobile;

  render() {
    this.imgPath = this.getImgPath();
    return (
      <div>
        <div>
          <img
            className={styles.back_img}
            src={this.imgPath}
            alt="MainImg"
          ></img>
          <Card className={styles.card_page}>
            <CardText className={styles.card_text_main}>U nas liczy</CardText>
            <CardText className={styles.card_text_main_second}>
              się JAKOŚĆ
            </CardText>
            {window.innerWidth >= 651 ? (
              <CardText className={styles.card_text_section}>
                Poznaj naszą ofertę i zapytaj o szczegóły.
              </CardText>
            ) : null}
            {window.innerWidth <= 651 ? (
              <CardText className={styles.card_text_section}>
                Zapoznaj się z Naszym bogatym asortymentem. Tylko
                wyselekcjonowane produkty.
              </CardText>
            ) : null}
            <img
              src={btnZapraszamy}
              className={styles.card_button}
              onClick={() => this.handleScrollToElem()}
              alt="btnInv"
            ></img>
          </Card>
        </div>
        <div>
          <div className={styles.card_sec} id="catContainer">
            <CategoriesContainer />
          </div>
        </div>
      </div>
    );
  }
}

export default CategoryPage;
