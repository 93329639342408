import React from "react";
import Shelf from "./components/Shelf";
import Filter from "./components/Shelf/Filter";
import FloatCart from "./components/FloatCart";
import Newsletter from "./ShopPages/Newsletter";
import Footer from "./ShopPages/Footer";

function Cart() {
  return (
    <div>
      <main>
        <Filter />
        <Shelf />
      </main>
      <div style={{ marginTop: "5vh" }}>
        <Newsletter />
      </div>
      <div>
        <Footer />
      </div>
      <FloatCart />
    </div>
  );
}

export default Cart;
