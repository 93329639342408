import React from "react";
import "./product-form.css";
import { storage } from "../services/firebase/firebase";
import { v4 as uuidv4 } from "uuid";
import fileImage from "../../src/images/file.jpg";
import { prefixToAdd } from "../services/util";

class ProductModalForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      selectedFile: null,
      showModal: false,
      availability: ["czerwony", "pomarańczowy", "zielony"],
      currency: ["zł"],
      vat: ["23%"],
      groups: [
        "Wybierz...",
        "Lakiery, farby, oleje",
        "Kleje",
        "Silikony",
        "Bejce, patyny",
        "Uszczelki drzwiowe",
        "Narzędzia",
        "Materiały ścierne",
        "Okleiny, obłogi, lamele",
        "Obrzeża naturalne",
        "Elementy złączne",
        "Płyty",
        "Okucia budowlane",
        "Krzesła",
        "Podłogi",
      ],
      manufacturers: [
        "Wybierz...",
        "Hesse Lignal",
        "Sivam",
        "Sikkens",
        "Alcea",
        "Libra",
        "Lakma",
        "Sopur",
        "Deventer",
        "AiB",
        "Schlegel",
        "Ottensten",
        "3M",
        "Kuper",
        "CMT",
        "Kovax",
        "SIA",
        "Indasa",
        "Wkrętmet",
        "Gerda",
        "Simonswerk",
        "Koblenz",
        "Otlav",
      ],
      subGroups: [],
      applications: [],
      glossDegrees: [],
      types: [],
      uoms: [],
      options: {
        producer: true,
        subGroup: true,
        application: true,
        glossDegree: true,
        type: true,
        code: true,
        name: true,
        manufacturer: true,
        uom: true,
      },
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.fieldsMapping = this.fieldsMapping.bind(this);
    this.clearAllFields = this.clearAllFields.bind(this);
    this.renderDropdown = this.renderDropdown.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleGroupChange = this.handleGroupChange.bind(this);
    this.removeAttachment = this.removeAttachment.bind(this);
    this.addAttachment = this.addAttachment.bind(this);
    this.showFileInBrowser = this.showFileInBrowser.bind(this);
    this.onInputClick = this.onInputClick.bind(this);
  }

  componentDidMount() {
    //wypełniamy wszystkie dropdowny wartościamy na podstawie wybranej grupy
    if (this.state.mode === "edit") {
      this.fieldsMapping(this.state.document.group);
    } else if (this.state.mode === "new") {
      this.fieldsMapping("Wybierz...");
    }
  }

  onInputClick = (event) => {
    event.target.value = "";
  };

  showFileInBrowser(url) {
    window.open(url, "_blank");
  }

  clearAllFields(chosenGroup) {
    //when user changes group we have to clear all values in order to be in "initial state" of a form
    this.setState((prevState) => ({
      document: {
        ...prevState.document,
        id: this.props.document.id,
        group: chosenGroup,
        producer: "Wybierz...",
        application: "Wybierz...",
        glossDegree: "Wybierz...",
        subGroup: "Wybierz...",
        type: "Wybierz...",
        uom: "Wybierz...",
      },
    }));
  }

  fieldsMapping(group) {
    //set other fields according to chosen group
    if (group === "Lakiery, farby, oleje") {
      this.setState({
        options: {
          producer: false,
          subGroup: false,
          application: false,
          glossDegree: false,
          type: false,
        },
        subGroups: [
          "Wybierz...",
          "Lakier podkładowy",
          "Utwardzacz",
          "Lakier nawierzchniowy",
          "Farba nawierzchniowa",
          "Farba podkładowa",
          "Dodatek",
          "Impregnaty",
          "Lazury",
          "Oleje",
        ],
        applications: ["Wybierz...", "Wewnętrzny", "Zewnętrzny"],
        glossDegrees: [
          "Wybierz...",
          "Głęboki mat",
          "Mat",
          "Półmat",
          "Połysk",
          "Półpołysk",
        ],
        types: ["Wybierz...", "Schodowy", "Meblowy", "Parkietowy"],
        uoms: ["Wybierz...", "litr", "kg"],
      });
    } else if (group === "Kleje") {
      this.setState({
        options: {
          producer: false,
          subGroup: false,
          application: false,
          glossDegree: true,
          type: true,
        },
        subGroups: [
          "Wybierz...",
          "Klasa wodoodporności D3",
          "Klasa wodoodporności D4",
          "Kleje poliuretanowe",
          "Kleje mocznikowe",
          "Kleje kontaktowe",
          "Kleje do parkietu",
          "Kleje montażowe",
        ],
        applications: ["Wybierz...", "Wewnętrzny", "Zewnętrzny"],
        glossDegrees: [],
        types: [],
        uoms: ["Wybierz...", "kg", "szt"],
      });
    } else if (group === "Silikony") {
      this.setState({
        options: {
          producer: false,
          subGroup: true,
          application: true,
          glossDegree: true,
          type: true,
        },

        subGroups: [],
        // //zastosowanie
        // applications: [],

        // //stopień połysku
        // glossDegrees: [],

        // //rodzaj
        // types: [],

        //jednostka
        uoms: ["Wybierz...", "szt"],
      });
    } else if (group === "Bejce, patyny") {
      this.setState({
        options: {
          producer: false,
          subGroup: false,
          application: true,
          glossDegree: true,
          type: true,
        },
        subGroups: [
          "Wybierz...",
          "Nitro",
          "Rozcieńczalnikowe",
          "Wodne",
          "Pastelowe",
        ],
        // //zastosowanie
        // applications: [],

        // //stopień połysku
        // glossDegrees: [],

        // //rodzaj
        // types: [],

        //jednostka
        uoms: ["Wybierz...", "szt", "litr"],
      });
    } else if (group === "Uszczelki drzwiowe") {
      this.setState({
        options: {
          producer: false,
          subGroup: false,
          application: true,
          glossDegree: true,
          type: true,
        },
        subGroups: [
          "Wybierz...",
          "Uszczelki do drzwi wewnętrznych bez przylgi",
          "Uszczelki do drzwi wewnętrznych z przylgą",
          "Uszczelki do drzwi zewnętrznych",
        ],
        // //zastosowanie
        // applications: [],

        // //stopień połysku
        // glossDegrees: [],

        // //rodzaj
        // types: [],

        //jednostka
        uoms: ["Wybierz...", "mb"],
      });
    } else if (group === "Narzędzia") {
      this.setState({
        options: {
          producer: false,
          subGroup: false,
          application: true,
          glossDegree: true,
          type: true,
        },
        subGroups: [
          "Wybierz...",
          "Narzędzia do obróbki drewna",
          "Akcesoria lakiernicze",
          "Wałki do nanoszenia kleju",
          "Spajarki do forniru",
          "Sztyfty i gwoździe",
        ],
        // //zastosowanie
        // applications: [],

        // //stopień połysku
        // glossDegrees: [],

        // //rodzaj
        // types: [],

        //jednostka
        uoms: ["Wybierz...", "szt"],
      });
    } else if (group === "Materiały ścierne") {
      this.setState({
        options: {
          producer: false,
          subGroup: false,
          application: true,
          glossDegree: true,
          type: true,
        },
        subGroups: [
          "Wybierz...",
          "Krążki ścierne",
          "Papiery na sucho",
          "Taśmy lakiernicze",
          "Materiały polerskie",
          "Pasy bezkońcowe",
        ],
        //zastosowanie
        // applications: [],

        // //stopień połysku
        // glossDegrees: [],

        // //rodzaj
        // types: [],

        //jednostka
        uoms: ["Wybierz...", "szt", "mb"],
      });
    } else if (group === "Okleiny, obłogi, lamele") {
      this.setState({
        options: {
          producer: true,
          subGroup: true,
          application: true,
          glossDegree: true,
          type: true,
        },
        // subGroups: [],
        // //zastosowanie
        // applications: [],

        // //stopień połysku
        // glossDegrees: [],

        // //rodzaj
        // types: [],

        //jednostka
        uoms: ["Wybierz...", "m2"],
      });
    } else if (group === "Obrzeża naturalne") {
      this.setState({
        options: {
          producer: true,
          subGroup: true,
          application: true,
          glossDegree: true,
          type: true,
        },
        // subGroups: [],
        // //zastosowanie
        // applications: [],

        // //stopień połysku
        // glossDegrees: [],

        // //rodzaj
        // types: [],

        //jednostka
        uoms: ["Wybierz...", "szt"],
      });
    } else if (group === "Elementy złączne") {
      this.setState({
        options: {
          producer: true,
          subGroup: false,
          application: true,
          glossDegree: true,
          type: true,
        },
        subGroups: [
          "Wybierz...",
          "Wkręty do drewna",
          "Kołki rozporowe",
          "Kołki do montażu schodów",
          "Dible",
          "Lamelo",
        ],
        // //zastosowanie
        // applications: [],

        // //stopień połysku
        // glossDegrees: [],

        // //rodzaj
        // types: [],

        //jednostka
        uoms: ["Wybierz...", "kg,szt"],
      });
    } else if (group === "Płyty") {
      this.setState({
        options: {
          producer: true,
          subGroup: false,
          application: true,
          glossDegree: true,
          type: true,
        },
        subGroups: [
          "Wybierz...",
          "Płyty stolarskie surowe, fornirowane",
          "Sklejki wodoodporne od 4mm w wym. 2,5m x 1,25m",
          "Sklejki suchotrwałe od 3mm",
          "Sklejka antypoślizgowa",
          "Lite sklejki dębowe",
          "Klejonka sosnowa od 30mm do 60mm",
          "Klejonka dębowa 38mm",
          "Płyta kanałowa (otworowa)",
        ],
        // //zastosowanie
        // applications: [],

        // //stopień połysku
        // glossDegrees: [],

        // //rodzaj
        // types: [],

        //jednostka
        uoms: ["Wybierz...", "szt"],
      });
    } else if (group === "Okucia budowlane") {
      this.setState({
        options: {
          producer: false,
          subGroup: false,
          application: true,
          glossDegree: true,
          type: true,
        },
        subGroups: [
          "Wybierz...",
          "Zawiasy do drzwi przylgowych",
          "Zawiasy do drzwi bezprzylgowych",
          "Zawiasy do drzwi z odwróconą przylgą",
          "Zamki stolarskie",
          "Zamki magnetyczne",
          "Klamki",
          "Osłonki na zawiasy",
          "Systemy drzwiowe",
          "Zamknięcia ewakuacyjne",
          "Zamykacze drzwiowe",
        ],
        // //zastosowanie
        // applications: [],

        // //stopień połysku
        // glossDegrees: [],

        // //rodzaj
        // types: [],

        //jednostka
        uoms: ["Wybierz...", "szt"],
      });
    } else if (group === "Krzesła") {
      this.setState({
        options: {
          producer: true,
          subGroup: true,
          application: true,
          glossDegree: true,
          type: true,
        },
        // subGroups: [],
        // //zastosowanie
        // applications: [],

        // //stopień połysku
        // glossDegrees: [],

        // //rodzaj
        // types: [],

        //jednostka
        uoms: ["Wybierz...", "szt"],
      });
    } else if (group === "Podłogi") {
      this.setState({
        options: {
          producer: true,
          subGroup: true,
          application: true,
          glossDegree: true,
          type: true,
        },
        // subGroups: [],
        // //zastosowanie
        // applications: [],

        // //stopień połysku
        // glossDegrees: [],

        // //rodzaj
        // types: [],

        //jednostka
        uoms: ["Wybierz...", "m2"],
      });
    } else if (group === "Wybierz") {
      this.setState({
        options: {
          producer: true,
          subGroup: true,
          application: true,
          glossDegree: true,
          type: true,
          code: true,
          name: true,
          manufacturer: true,
          uom: true,
        },
      });
    }
  }

  handleInputChange(event) {
    const currentDocument = { ...this.state.document };
    currentDocument[event.target.name] = event.target.value;
    this.setState({
      document: currentDocument,
    });
  }

  handleGroupChange(event) {
    //we have to set form fields to initial state
    //because we have changed group and data stored in this.state.record won't not be correct for different groups
    this.clearAllFields(event.target.value);
    //fill in other dropdowns according to chosen group
    this.fieldsMapping(event.target.value);
  }

  renderDropdown(items) {
    if (items === "groups") {
      return this.state.groups.map((item) => (
        <option key={item} value={item}>
          {item}
        </option>
      ));
    } else if (items === "manufacturers") {
      return this.state.manufacturers.map((item) => (
        <option key={item} value={item}>
          {item}
        </option>
      ));
    } else if (items === "applications") {
      return this.state.applications.map((item) => (
        <option key={item} value={item}>
          {item}
        </option>
      ));
    } else if (items === "uoms") {
      return this.state.uoms.map((item) => (
        <option key={item} value={item}>
          {item}
        </option>
      ));
    } else if (items === "types") {
      return this.state.types.map((item) => (
        <option key={item} value={item}>
          {item}
        </option>
      ));
    } else if (items === "subGroups") {
      return this.state.subGroups.map((item) => (
        <option key={item} value={item}>
          {item}
        </option>
      ));
    } else if (items === "glossDegrees") {
      return this.state.glossDegrees.map((item) => (
        <option key={item} value={item}>
          {item}
        </option>
      ));
    } else if (items === "currency") {
      return this.state.currency.map((item) => (
        <option key={item} value={item}>
          {item}
        </option>
      ));
    } else if (items === "vat") {
      return this.state.vat.map((item) => (
        <option key={item} value={item}>
          {item}
        </option>
      ));
    } else if (items === "availability") {
      return this.state.availability.map((item) => (
        <option key={item} value={item}>
          {item}
        </option>
      ));
    }
  }

  handleSubmit(e) {
    // Wyłączamy defaultowe zachowanie formularza przy submit
    e.preventDefault();
    this.props.toggleModal();
    const currentDocument = { ...this.state.document };
    if (
      currentDocument.group !== "Wybierz..." &&
      currentDocument.group !== undefined
    ) {
      for (const property in currentDocument) {
        if (
          currentDocument[property] === "Wybierz..." ||
          currentDocument[property] === undefined
        ) {
          currentDocument[property] = "";
        }
      }
      this.setState({ document: currentDocument });
      this.props.firebaseAction(currentDocument, this.state.mode);
    }
  }

  addAttachment(e, typeOfAttachment) {
    const newImage = storage.child(uuidv4() + "_" + e.name);
    newImage
      .put(e)
      .then((snapshot) => {
        newImage.getDownloadURL().then((u) => {
          let url = u.split("/o/")[1];
          alert("Załącznik dodany pomyślnie.");
          if (typeOfAttachment === "mainImage") {
            this.setState((prevState) => ({
              document: {
                ...prevState.document,
                mainImage: url,
              },
            }));
          } else if (typeOfAttachment === "otherImages") {
            if (this.state.document.otherImages !== undefined) {
              const otherImages = [...this.state.document.otherImages];
              const updated = otherImages.concat(url);
              this.setState((prevState) => ({
                document: {
                  ...prevState.document,
                  otherImages: updated,
                },
              }));
            } else {
              const otherImages = [];
              const updated = otherImages.concat(url);
              this.setState((prevState) => ({
                document: {
                  ...prevState.document,
                  otherImages: updated,
                },
              }));
            }
          } else if (typeOfAttachment === "other") {
            if (this.state.document.other !== undefined) {
              const other = [...this.state.document.other];
              const updated = other.concat(url);
              this.setState((prevState) => ({
                document: {
                  ...prevState.document,
                  other: updated,
                },
              }));
            } else {
              const other = [];
              const updated = other.concat(url);
              this.setState((prevState) => ({
                document: {
                  ...prevState.document,
                  other: updated,
                },
              }));
            }
          }
        });
      })
      .catch(function (error) {
        alert(error);
      });
  }

  removeAttachment(e, typeOfAttachment) {
    const splitted = e.split("/o/")[1];
    const splitted2 = splitted.split("?alt")[0];

    if (typeOfAttachment === "mainImage") {
      this.setState((prevState) => ({
        document: {
          ...prevState.document,
          mainImage: "",
        },
      }));
    } else if (typeOfAttachment === "otherImages") {
      let filteredArray = this.state.document.otherImages.filter(
        (item) => item !== splitted
      );

      this.setState((prevState) => ({
        document: {
          ...prevState.document,
          otherImages: filteredArray,
        },
      }));
    } else if (typeOfAttachment === "other") {
      let filteredArray = this.state.document.other.filter(
        (item) => item !== splitted
      );

      this.setState((prevState) => ({
        document: {
          ...prevState.document,
          other: filteredArray,
        },
      }));
    }

    storage
      .child(splitted2)
      .delete()
      .then(alert("załącznik usunięty pomyślnie!"))
      .catch(function (error) {
        alert(error);
      });
  }
  render() {
    return (
      <div className="main-form">
        <div className="product-form-items">
          <form
            className="product-form"
            onSubmit={this.handleSubmit}
            value="Submit"
          >
            <button type="submit" className="modal-form-submit">
              Zapisz
            </button>
            <div id="label-value">
              <label className="form-label" htmlFor="group">
                Grupa produktu
              </label>
              <select
                type="text"
                id="group"
                name="group"
                placeholder="Wybierz grupę produktu"
                onChange={this.handleGroupChange}
                value={this.state.document.group}
              >
                {this.renderDropdown("groups")}
              </select>
            </div>
            <div id="label-value">
              <label className="form-label" htmlFor="code">
                Kod
              </label>
              <input
                type="text"
                name="code"
                className="form-obj"
                placeholder="Wpisz kod..."
                onChange={this.handleInputChange}
                disabled={this.state.options.code}
                value={this.state.document.code}
              ></input>
            </div>
            <div id="label-value">
              <label className="form-label" htmlFor="productName">
                Nazwa
              </label>
              <input
                type="text"
                name="name"
                className="form-obj"
                placeholder="Wpisz nazwę produktu..."
                onChange={this.handleInputChange}
                disabled={this.state.options.name}
                value={this.state.document.name}
              ></input>
            </div>
            <div id="label-value">
              <label className="form-label" htmlFor="manufacturer">
                Producent
              </label>
              <select
                type="text"
                name="producer"
                className="form-obj"
                placeholder="Wybierz producenta..."
                onChange={this.handleInputChange}
                disabled={this.state.options.producer}
                value={this.state.document.producer}
              >
                {this.renderDropdown("manufacturers")}
              </select>
            </div>
            <div id="label-value">
              <label className="form-label" htmlFor="subGroup">
                Podgrupa produktu
              </label>
              <select
                type="text"
                name="subGroup"
                placeholder="Wybierz podgrupę produktu"
                onChange={this.handleInputChange}
                disabled={this.state.options.subGroup}
                value={this.state.document.subGroup}
              >
                {this.renderDropdown("subGroups")}
              </select>
            </div>
            <div id="label-value">
              <label className="form-label" htmlFor="application">
                Zastosowanie
              </label>
              <select
                type="text"
                name="application"
                placeholder="Zastosowanie"
                onChange={this.handleInputChange}
                disabled={this.state.options.application}
                value={this.state.document.application}
              >
                {this.renderDropdown("applications")}
              </select>
            </div>
            <div id="label-value">
              <label className="form-label" htmlFor="glossDegree">
                Stopień połysku
              </label>
              <select
                type="text"
                name="glossDegree"
                placeholder="Stopień połysku"
                onChange={this.handleInputChange}
                disabled={this.state.options.glossDegree}
                value={this.state.document.glossDegree}
              >
                {this.renderDropdown("glossDegrees")}
              </select>
            </div>
            <div id="label-value">
              <label className="form-label" htmlFor="type">
                Rodzaj
              </label>

              <select
                type="text"
                name="type"
                placeholder="Rodzaj"
                onChange={this.handleInputChange}
                disabled={this.state.options.type}
                value={this.state.document.type}
              >
                {this.renderDropdown("types")}
              </select>
            </div>
            <div id="label-value">
              <label className="form-label" htmlFor="uom">
                Jednostka miary
              </label>

              <select
                type="text"
                name="uom"
                placeholder="Jednostka miary"
                onChange={this.handleInputChange}
                disabled={this.state.options.uom}
                value={this.state.document.uom}
              >
                {this.renderDropdown("uoms")}
              </select>
            </div>
            <div id="label-value">
              <label className="form-label" htmlFor="currency">
                Waluta
              </label>

              <select
                type="text"
                name="currencyFormat"
                placeholder="Waluta"
                onChange={this.handleInputChange}
                value={this.state.document.currencyFormat}
              >
                {this.renderDropdown("currency")}
              </select>
            </div>
            <div id="label-value">
              <label className="form-label" htmlFor="price">
                Cena netto
              </label>
              <input
                type="text"
                name="price"
                placeholder="Wpisz cenę..."
                onChange={this.handleInputChange}
                value={this.state.document.price}
                defaultValue="0"
              ></input>
            </div>

            <div id="label-value">
              <label className="form-label" htmlFor="vat">
                Stawka VAT
              </label>
              <select
                type="text"
                name="vat"
                placeholder="vat"
                onChange={this.handleInputChange}
                value={this.state.document.vat}
              >
                {this.renderDropdown("vat")}
              </select>
            </div>
            <div id="label-value">
              <label className="form-label" htmlFor="grossPrice">
                Cena brutto
              </label>
              <input
                type="text"
                name="grossPrice"
                onChange={this.handleInputChange}
                value={
                  Math.round(
                    (+(this.state.document.price * 0.23) +
                      +this.state.document.price +
                      Number.EPSILON) *
                      100
                  ) / 100
                }
              ></input>
            </div>

            <div id="label-value">
              <label className="form-label" htmlFor="availability">
                Stan magazynowy
              </label>
              <select
                className={"dropdown-color-" + this.state.document.availability}
                type="text"
                name="availability"
                placeholder="Stan magazynowy"
                onChange={this.handleInputChange}
                value={this.state.document.availability}
              >
                {this.renderDropdown("availability")}
              </select>
            </div>

            <div id="label-value">
              <label className="form-label" htmlFor="description">
                Opis produktu
              </label>
              <textarea
                style={{ width: "20vw" }}
                type="text"
                name="description"
                placeholder="Opis produktu..."
                onChange={this.handleInputChange}
                value={this.state.document.description}
              ></textarea>
            </div>

            <div id="photo-section">
              <p style={{ fontWeight: "bold" }}>Główne zdjęcie</p>
              <label
                htmlFor="filePicker"
                style={{ background: "lightgrey", padding: "5px 10px" }}
              >
                Wybierz...
              </label>

              <input
                id="filePicker"
                type="file"
                style={{ visibility: "hidden" }}
                onChange={(e) => {
                  this.addAttachment(e.target.files[0], "mainImage");
                }}
              />
              {
                <div id="images-list">
                  <img
                    src={prefixToAdd + this.state.document.mainImage}
                    style={{ width: "90px" }}
                    alt=""
                    onClick={() =>
                      this.showFileInBrowser(
                        prefixToAdd + this.state.document.mainImage
                      )
                    }
                  />
                  {this.state.document.mainImage !== "" &&
                  this.state.document.mainImage !== undefined ? (
                    <input
                      onClick={(e) =>
                        this.removeAttachment(
                          prefixToAdd + this.state.document.mainImage,
                          "mainImage"
                        )
                      }
                      type="button"
                      value="Usuń"
                    />
                  ) : null}
                </div>
              }

              <p style={{ fontWeight: "bold" }}>Dodatkowe zdjęcia</p>
              <label
                htmlFor="filePicker2"
                style={{ background: "lightgrey", padding: "5px 10px" }}
              >
                Wybierz...
              </label>
              <input
                id="filePicker2"
                type="file"
                onClick={(e) => this.onInputClick(e)}
                onChange={(e) => {
                  this.addAttachment(e.target.files[0], "otherImages");
                }}
                style={{ visibility: "hidden" }}
              />
              <div id="images-list">
                {this.state.document.otherImages !== undefined
                  ? this.state.document.otherImages.map((photo) => (
                      <div>
                        <img
                          src={prefixToAdd + photo}
                          style={{ width: "90px" }}
                          alt=""
                          onClick={() =>
                            this.showFileInBrowser(prefixToAdd + photo)
                          }
                        />
                        <input
                          onClick={(e) =>
                            this.removeAttachment(
                              prefixToAdd + photo,
                              "otherImages"
                            )
                          }
                          type="button"
                          value="Usuń"
                        />
                      </div>
                    ))
                  : null}
              </div>
              <p style={{ fontWeight: "bold" }}>Inne załączniki</p>
              <label
                htmlFor="filePicker3"
                style={{ background: "lightgrey", padding: "5px 10px" }}
              >
                Wybierz...
              </label>
              <input
                id="filePicker3"
                type="file"
                onClick={(e) => this.onInputClick(e)}
                onChange={(e) => {
                  this.addAttachment(e.target.files[0], "other");
                }}
                style={{ visibility: "hidden" }}
              />
              <div id="images-list">
                {this.state.document.other !== undefined
                  ? this.state.document.other.map((other) => (
                      <div>
                        <img
                          src={fileImage}
                          style={{ width: "90px" }}
                          alt=""
                          onClick={() =>
                            this.showFileInBrowser(prefixToAdd + other)
                          }
                        />
                        <p>{other.split("?alt")[0]}</p>
                        <input
                          onClick={(e) =>
                            this.removeAttachment(prefixToAdd + other, "other")
                          }
                          type="button"
                          value="Usuń"
                        />
                      </div>
                    ))
                  : null}
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default ProductModalForm;
