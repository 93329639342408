import React from "react";
import styles from "./IntroPage.module.css";
import { withRouter } from "react-router-dom";
import CssModules from "react-css-modules";
import Button from "../Animations/Button";

const WelcomeSubPage = (props) => {
  const nextPath = (path) => {
    props.history.push(path);
  };

  return (
    <div styleName="intro_page">
      <div styleName="welcome">
        <h5>
          Wejdź na stronę i zobacz co możemy<br></br> zaoferować Tobie i Twojej
          firmie.
        </h5>
        <img src="./IntroPagePics/welcome.jpg" alt="" />
        <div
          onClick={() => nextPath("/StronaGlowna")}
          styleName="welcome_button"
        >
          <Button
            whileHover={1.2}
            whileTap={0.8}
            id="input"
            type="image"
            src="./IntroPagePics/welcome_button.png"
          />
        </div>
        <h1>Przedstawiamy się</h1>
      </div>
    </div>
  );
};

export default withRouter(
  CssModules(WelcomeSubPage, styles, { allowMultiple: true })
);
