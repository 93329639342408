import React from "react";
import styles from "./IntroPage.module.css";
import { withRouter } from "react-router-dom";
import CssModules from "react-css-modules";
import Button from "../Animations/Button";

const ContactSubPage = (props) => {
  const nextPath = (path) => {
    props.history.push(path);
  };
  return (
    <div styleName="intro_page">
      <div styleName="contact">
        <h5>
          Skontaktuj się bezpośrednio z nami.<br></br> Wystarczy kliknąć w
          przycisk poniżej.
        </h5>
        <img src="./IntroPagePics/contact.jpg" alt="" />
        <div onClick={() => nextPath("/Kontakt")} styleName="contact_button">
          <Button
            whileHover={1.2}
            whileTap={0.8}
            id="input"
            type="image"
            src="./IntroPagePics/contact_button.png"
          />
        </div>
        <h1>Szybki kontakt</h1>
      </div>
    </div>
  );
};

export default withRouter(
  CssModules(ContactSubPage, styles, { allowMultiple: true })
);
