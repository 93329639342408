import React from "react";
import PropTypes from "prop-types";
import { fetchProducts } from "../../../../../services/shelf/actions";
import { connect } from "react-redux";
import "./style.scss";

class GetSites extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chosenPage: 1,
    };
    this.getQuantity = this.getQuantity.bind(this);
    this.handleFetchProducts = this.handleFetchProducts.bind(this);
    this.createSiteBtns = this.createSiteBtns.bind(this);
    this.changePageVal = this.changePageVal.bind(this);
    this.moveToPage = this.moveToPage.bind(this);
  }

  static propTypes = {
    products: PropTypes.array.isRequired,
    filters: PropTypes.array.isRequired,
    quantityToShow: PropTypes.number.isRequired,
    fetchProducts: PropTypes.func.isRequired,
    sort: PropTypes.string,
    productsListLength: PropTypes.number,
    handleFetchProducts: PropTypes.func,
    getQuantity: PropTypes.func,
    createSiteBtns: PropTypes.func,
  };

  getQuantity = (productsListLength, quantityToShow) => {
    if (productsListLength > 0) {
      return productsListLength / quantityToShow;
    }
  };

  changePageVal = (val) => {
    if (isNaN(val)) {
      alert("Wprowadz poprawnie numer strony!");
    } else {
      this.setState({ chosenPage: val });
    }
  };

  moveToPage = (value) => {
    var val = parseInt(value);
    let x = this.getQuantity(
      this.props.productsListLength,
      this.props.quantityToShow
    );
    // console.log("move to page site number", val);
    x = Math.ceil(x);
    if (val <= x && val >= 1) {
      this.setState({ chosenPage: val });
      this.handleFetchProducts(
        val,
        this.props.quantityToShow,
        this.props.filters,
        this.props.sort
      );
    }
  };

  handleFetchProducts = (pageNo, filterQuantity, filters, sort) => {
    //   this.setState({ isLoading: true });
    // console.log("site number", pageNo, this.state.chosenPage);
    this.props.fetchProducts(
      filters,
      sort,
      () => {
        //   this.setState({ isLoading: false });
      },
      filterQuantity,
      pageNo
    );
  };

  createSiteBtns = () => {
    let x = this.getQuantity(
      this.props.productsListLength,
      this.props.quantityToShow
    );
    let items = [];
    x = Math.ceil(x);

    if (x >= 8) {
      items.push(<span> </span>);
      for (var i = 1; i <= 3; i++) {
        items.push(
          <button
            className="quantity-filter-sites-button"
            key={i}
            value={i}
            onClick={(event) => this.moveToPage(event.target.value)}
          >
            {i}
          </button>
        );
      }

      items.push(
        <button
          className="quantity-filter-sites-button-switch"
          onClick={() => this.moveToPage(this.state.chosenPage - 1)}
        >
          {" "}
          Poprzednia
        </button>
      );

      items.push(
        <span className="quantity-filter-sites-desc">
          Strona{" "}
          <input
            className="quantity-filter-sites-input"
            min={i}
            max={x}
            pattern="[0-9]*"
            defaultValue={this.state.chosenPage}
            value={this.state.chosenPage}
            onChange={(event) => this.changePageVal(event.target.value)}
          />
        </span>
      );

      items.push(
        <button
          className="quantity-filter-sites-button-switch"
          key={"999"}
          onClick={(event) =>
            this.handleFetchProducts(
              this.state.chosenPage,
              this.props.quantityToShow,
              this.props.filters,
              this.props.sort
            )
          }
        >
          Przejdź
        </button>
      );
      items.push(
        <button
          className="quantity-filter-sites-button-switch"
          onClick={() => this.moveToPage(this.state.chosenPage + 1)}
        >
          {" "}
          Następna
        </button>
      );

      for (var i = x - 2; i <= x; i++) {
        items.push(
          <button
            className="quantity-filter-sites-button"
            key={i}
            value={i}
            onClick={(event) => this.moveToPage(event.target.value)}
          >
            {i}
          </button>
        );
      }
    } else {
      for (var i = 1; i <= x; i++) {
        items.push(
          <button
            className="quantity-filter-sites-button"
            key={i}
            value={i}
            onClick={(event) =>
              this.handleFetchProducts(
                event.target.value,
                this.props.quantityToShow,
                this.props.filters,
                this.props.sort
              )
            }
          >
            {i}
          </button>
        );
      }
    }

    return items;
  };

  render() {
    return <div className="quantity-filter-sites">{this.createSiteBtns()}</div>;
  }
}

const mapStateToProps = (state) => ({
  filterQuantity: state.filterQuantity.type,
  products: state.shelf.products,
  filters: state.filterObj.filters,
  sort: state.sort.type,
});

export default connect(mapStateToProps, { fetchProducts })(GetSites);
