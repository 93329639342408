import React from "react";
import styles from "./Main.module.css";
import CssModules from "react-css-modules";
import { withRouter } from "react-router-dom";
import { updateFilters, removeFilters } from "../services/filters/actions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import promocja from "../images/promocja.png";
import bejce from "../images/bejce.png";
import okucia from "../images/okucia.png";

const CheckOffer = (props) => {
  const nextPath = (path, group) => {
    const filters = props.filters;
    filters.length = 0;
    props.removeFilters(filters);
    filters.push([group, true]);
    props.updateFilters(filters);
    props.history.push(path);
  };

  return (
    <div styleName="check-offer">
      <div
        onClick={() =>
          nextPath(
            "/Sklep/Produkt/Okucia%20budowlane/Koblenz/Zawas%20wpuszczany%20KARAKTER%20K8080/602"
          )
        }
        styleName="check-offer-1"
      >
        <img src={promocja} alt="" />
        {/* <button>ZOBACZ TERAZ</button>
        <h3>Promocja miesiąca</h3> */}
      </div>
      <div styleName="check-offer-2">
        <img src={okucia} alt="" />
        <button
          onClick={() =>
            nextPath("/Sklep/Glowna/Okucia%20budowlane", "Okucia budowlane")
          }
        >
          SPRAWDŹ OFERTĘ
        </button>
        <h3>Okucia budowlane</h3>
      </div>
      <div styleName="check-offer-3">
        <img src={bejce} alt="" />
        <button
          onClick={() =>
            nextPath("/Sklep/Glowna/Bejce,%20patyny", "Bejce, patyny")
          }
        >
          SPRAWDŹ OFERTĘ
        </button>
        <h3>Bejce</h3>
        <h3>Patyny</h3>
      </div>
    </div>
  );
};

CheckOffer.propTypes = {
  updateFilters: PropTypes.func.isRequired,
  filters: PropTypes.array.isRequired,
  removeFilters: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  filters: state.filterObj.filters,
});

export default connect(mapStateToProps, { removeFilters, updateFilters })(
  withRouter(
    CssModules(CheckOffer, styles, {
      allowMultiple: true,
    })
  )
);
