import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

const CategoryLink = ({ history, to, staticContext, onClick, tag: Tag, ...rest }) => (
    <Tag
        {...rest}
        onClick={(event) => {
            onClick(event);
            history.push(to)
        }}
    />
);

CategoryLink.propTypes = {
    to: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired,
    onClick: PropTypes.func
};
CategoryLink.defaultProps = {
    onClick: () => {}
};
export default withRouter(CategoryLink);