import React from "react";
import "react-image-gallery/styles/scss/image-gallery.scss";
import ImageGallery from "react-image-gallery";
// import "react-responsive-carousel/lib/styles/carousel.min.css";
// import { Carousel } from "react-responsive-carousel";

const getItems = (props) => {
  var imgs = [];
  var images = props.props;

  for (var i = 0; i < images.length; i++) {
    imgs.push({
      original: images[i],
      thumbnail: images[i],
    });
  }
  return imgs;
};

const ProductCarousel = (props) => (
  <ImageGallery
    autoPlay={false}
    thumbnailPosition="left"
    showPlayButton={false}
    items={getItems(props)}
    showNav={true}
    showFullscreenButton={true}
  />

  // <Carousel dynamicHeight={true} centerMode={false} axis={"horizontal"}>
  //   {getItems(props)}
  // </Carousel>
);

export default ProductCarousel;
