import React from "react";
import styles from "./CategoriesDetailsPage.module.scss";
import rButton from "../Animations/RoundedButton.module.scss";
// import iNextCat from "../images/next_cat.jpg";
// import iPrevCat from "../images/prev_cat.jpg";
import iMobile from "../images/btnMobile.png";
import FirstCategory from "./CategoriesDetails/FirstCategory.js";
import SecCategory from "./CategoriesDetails/SecCategory.js";
import ThirdCategory from "./CategoriesDetails/ThirdCategory.js";
import FourthCategory from "./CategoriesDetails/FourthCategory.js";
import FifthCategory from "./CategoriesDetails/FifthCategory.js";
import SixthCategory from "./CategoriesDetails/SixthCategory.js";
import SeventhCategory from "./CategoriesDetails/SeventhCategory.js";
import EigthCategory from "./CategoriesDetails/EigthCategory.js";
import NinthCategory from "./CategoriesDetails/NinthCategory.js";
import TenthCategory from "./CategoriesDetails/TenthCategory.js";
import EleventhCategory from "./CategoriesDetails/EleventhCategory.js";
import TwelvethCategory from "./CategoriesDetails/TwelvethCategory.js";
import ThirthCategory from "./CategoriesDetails/ThirthCategory.js";
import FourteenthCategory from "./CategoriesDetails/FourteenthCategory.js";
import { GetCards } from "../Categories/CategoriesContainer";
import ReactScrollWheelHandler from "react-scroll-wheel-handler";
import { motion } from "framer-motion";
import blobbtn from "../Animations/BlobButton.module.scss";
import { Link } from "react-router-dom";
import { Swipeable } from "react-swipeable";

//import ImageView from 'react-native-image-view'; alternatywnie dla mobile

class CategoryDetailsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pic: this.props.location.detailSrc,
      cards: [this.props.location.cards],
      id: this.props.location.id,
      pathname: this.props.location.pathname,
      currentCard: this.props.location.currentCard,
      detailNo: this.props.location.detailNo,
      srcMobile: this.props.location.srcMobile,
      imgPath: "",
      windWidth: 0,
      scrollPos: 0,
    };

    this.checkProps = this.checkProps.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.getCategory = this.getCategory.bind(this);
    this.setCardProps = this.setCardProps.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  nextPath(path) {
    this.props.history.push(path);
  }

  updateDimensions() {
    if (window.innerWidth <= 651) {
      this.setState({ imgPath: this.srcMobile });
    } else if (this.state.detailNo === 4) {
      var modalForm = document.getElementById("modalForm");
      if (modalForm === null || modalForm === undefined) {
        this.setState({ imgPath: this.pic });
      }
    } else {
      this.setState({ imgPath: this.pic });
    }
    return this.imgPath;
  }

  UNSAFE_componentWillMount() {
    this.updateDimensions();
    this.checkProps();
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  checkProps() {
    let id = this.props.id;
    let card = [];
    let lesscards = [];

    window.scrollTo(0, 0);
    if (id === "" || id === undefined) {
      lesscards = GetCards();
      card = lesscards.filter(
        (card) => card.pathname === this.props.location.pathname
      )[0];
      //karty
      this.setState({
        pic: card.detailSrc,
        detailNo: card.detailNo,
        pathname: card.pathname,
        srcMobile: card.srcMobile,
        cards: [...lesscards],
      });
    } else if (this.props.location.cards !== undefined) {
      this.setState({
        cards: this.props.location.cards,
      });
    }
  }

  setCardProps(detailNo) {
    let card = this.state.cards.filter((card) => card.detailNo === detailNo)[0];

    this.setState({
      pic: card.detailSrc,
      detailNo: card.detailNo,
      pathname: card.pathname,
      srcMobile: card.srcMobile,
    });

    this.nextPath(card.pathname);
  }

  shoppingButton() {
    return (
      <div className={styles.buttons}>
        <button
          onClick={() => this.nextPath("/Sklep")}
          className={blobbtn.blob_btn}
        >
          SPRAWDŹ OFERTĘ W SKLEPIE
          <span className={blobbtn.blob_btn__inner}>
            <span className={blobbtn.blob_btn__blobs}>
              <span className={blobbtn.blob_btn__blob}></span>
              <span className={blobbtn.blob_btn__blob}></span>
              <span className={blobbtn.blob_btn__blob}></span>
              <span className={blobbtn.blob_btn__blob}></span>
            </span>
          </span>
        </button>
        <br />

        <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
          <defs>
            <filter id="goo">
              <feGaussianBlur
                in="SourceGraphic"
                result="blur"
                stdDeviation="10"
              ></feGaussianBlur>
              <feColorMatrix
                in="blur"
                mode="matrix"
                values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 21 -7"
                result="goo"
              ></feColorMatrix>
              <feBlend in2="goo" in="SourceGraphic" result="mix"></feBlend>
            </filter>
          </defs>
        </svg>
      </div>
    );
  }

  getCategory() {
    let detail = this.state.detailNo;

    if (detail === 1) {
      return <FirstCategory shpButton={this.shoppingButton()} />;
    } else if (detail === 2) {
      return <TenthCategory shpButton={this.shoppingButton()} />;
    } else if (detail === 3) {
      return <ThirthCategory shpButton={this.shoppingButton()} />;
    } else if (detail === 4) {
      return <ThirdCategory shpButton={this.shoppingButton()} />;
    } else if (detail === 5) {
      return <SecCategory shpButton={this.shoppingButton()} />;
    } else if (detail === 6) {
      return <FifthCategory shpButton={this.shoppingButton()} />;
    } else if (detail === 7) {
      return <SeventhCategory shpButton={this.shoppingButton()} />;
    } else if (detail === 8) {
      return <EigthCategory shpButton={this.shoppingButton()} />;
    } else if (detail === 9) {
      return <EleventhCategory shpButton={this.shoppingButton()} />;
    } else if (detail === 10) {
      return <FourteenthCategory shpButton={this.shoppingButton()} />;
    } else if (detail === 11) {
      return <FourthCategory shpButton={this.shoppingButton()} />;
    } else if (detail === 12) {
      return <SixthCategory shpButton={this.shoppingButton()} />;
    } else if (detail === 13) {
      return <NinthCategory shpButton={this.shoppingButton()} />;
    } else if (detail === 14) {
      return <TwelvethCategory shpButton={this.shoppingButton()} />;
    }
  }

  handlePageChange(moveUp) {
    let detail = this.state.detailNo;

    if (moveUp === true) {
      if (detail < 14) {
        detail = detail + 1;
      } else {
        detail = 1;
      }
    } else if (moveUp === false) {
      if (detail > 1) {
        detail = detail - 1;
      } else {
        detail = 14;
      }
    }

    this.setCardProps(detail);

    let currCard = this.state.cards.filter(
      (card) => card.detailNo === detail
    )[0];

    this.setState((prevState) => ({
      detailNo: currCard.detailNo,
    }));
  }

  getImgPath = () =>
    window.innerWidth >= 651 ? this.state.pic : this.state.srcMobile;

  render() {
    const imgPath = this.getImgPath();

    return (
      <div className={styles.welcome}>
        {window.innerWidth >= 651 ? (
          <ReactScrollWheelHandler
            upHandler={() => this.handlePageChange(true)}
            downHandler={() => this.handlePageChange(false)}
          >
            <div key={this.state.detailNo} style={{ backgroundColor: "white" }}>
              <motion.img
                initial={{ x: 300, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: -300, opacity: 0 }}
                className={styles.category_img}
                src={`../CategoriesPics/${imgPath}`}
                alt="mainImg"
              ></motion.img>

              <div>{this.getCategory()}</div>
              <div
                onClick={() => this.handlePageChange(true)}
                className={styles.button_next_page}
              >
                <Link className={rButton.rounded_button_next} alt="">
                  <p>Następna strona</p>
                </Link>
              </div>
              <div
                onClick={() => this.handlePageChange(false)}
                className={styles.button_previous_page}
              >
                <Link className={rButton.rounded_button_prev} alt="">
                  <p>Poprzednia strona</p>
                </Link>
              </div>
            </div>
          </ReactScrollWheelHandler>
        ) : (
          <div>
            <div key={this.state.detailNo}>
              <Swipeable
                trackMouse
                preventDefaultTouchmoveEvent
                onSwipedLeft={() => this.handlePageChange(true)}
                onSwipedRight={() => this.handlePageChange(false)}
              >
                <motion.img
                  initial={{ x: 300, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  exit={{ x: -300, opacity: 0 }}
                  className={styles.category_img}
                  src={`../CategoriesPics/${imgPath}`}
                  alt="mainImgMobile"
                ></motion.img>
                {this.getCategory()}
              </Swipeable>
              {/* <img
                src={iMobile}
                className={styles.button_previous_page_mobile}
                onClick={() => this.handlePageChange(true)}
                alt="btnMobile"
              ></img>
              <img
                src={iMobile}
                className={styles.button_next_page_mobile}
                onClick={() => this.handlePageChange(false)}
                alt="btnMobile"
              ></img> */}
            </div>
          </div>
        )}
      </div>
    );
  }
}

CategoryDetailsPage.defaultProps = {
  pic: "",
  cards: [],
  id: "",
  pathname: "",
  currentCard: "",
  detailNo: "",
};

export default CategoryDetailsPage;
