import * as React from "react";
import { motion } from "framer-motion";

const Button = (props) => {
  if (props.id === "other") {
    return (
      <motion.div
        whileHover={{ scale: props.whileHover }}
        whileTap={{ scale: props.whileTap }}
      ></motion.div>
    );
  } else if (props.id === "input") {
    return (
      <motion.div
        whileHover={{ scale: props.whileHover }}
        whileTap={{ scale: props.whileTap }}
        onClick={props.onClick}
        style={props.style}
      >
        <input type={props.type} src={props.src} alt="" value={props.value} />
      </motion.div>
    );
  } else if (props.id === "img") {
    return (
      <motion.div
        whileHover={{ scale: props.whileHover }}
        whileTap={{ scale: props.whileTap }}
        onClick={props.onClick}
      >
        <img src={props.src} alt="" />
      </motion.div>
    );
  }
};

export default Button;
