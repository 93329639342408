import React from "react";
import iGerda from "../../images/gerda logo.png";
import iSimon from "../../images/simonsverk.png";
import iKoblenz from "../../images/Koblenz-verbindet-Logo.svg.png";
import iOtlav from "../../images/otlav.png";
import iNovet from "../../images/novert.png";
// import iMoveToShop from "../../images/btnZrobZakupy.png";
import parImg from "../../images/newlineText.png";
import styles from "./CategoriesDetails.module.scss";
import { withRouter } from "react-router-dom";

export default withRouter(function SixthCategory(props) {
  return (
    <div>
      <div className={styles.text_box_okucia}>
        <h1 className={styles.text_box_title}>Okucia budowlane</h1>
        <h2 className={styles.text_box_subtitle}>Zakres sprzedaży:</h2>

        <div className={styles.text_box_item_okucia}>
          <div>
            <div>
              <img src={parImg} className={styles.text_img} alt="" />
              Zawiasy do drzwi przylgowych
            </div>
            {window.innerWidth >= 651 ? (
              <div>
                <img src={parImg} className={styles.text_img} alt="" />
                Zawiasy do drzwi bezprzylgowych, z odwróconą przylgą
              </div>
            ) : (
              <div>
                <div>
                  <img src={parImg} className={styles.text_img} alt="" />
                  Zawiasy do drzwi bezprzylgowych, z odwróconą
                </div>
                <div className={styles.text_div_down}>przylgą</div>
              </div>
            )}
            <div>
              <img src={parImg} className={styles.text_img} alt="" />
              Zamki stolarskie
            </div>
            <div>
              <img src={parImg} className={styles.text_img} alt="" />
              Zamki magnetyczne
            </div>
            <div>
              <img src={parImg} className={styles.text_img} alt="" />
              Wkładki do drzwi (również atestowane)
            </div>
            <div>
              <img src={parImg} className={styles.text_img} alt="" />
              Klamki
            </div>
            <div>
              <img src={parImg} className={styles.text_img} alt="" />
              Osłonki na zawiasy
            </div>
            <div>
              <img src={parImg} className={styles.text_img} alt="" />
              Systemy drzwiowe
            </div>
            <div>
              <img src={parImg} className={styles.text_img} alt="" />
              Zamknięcia ewakuacyjne
            </div>
            <div>
              <img src={parImg} className={styles.text_img} alt="" />
              Zamykacze drzwiowe
            </div>
          </div>
        </div>
        {props.shpButton}
        {/* <div className={styles.moveTo_sec}>
          <img
            className={styles.moveTo_img}
            src={iMoveToShop}
            onClick={() => nextPath("/shop")}
            alt="Move to Shop"
          ></img>
        </div> */}
        {window.innerWidth >= 651 ? (
          <footer className={styles.entp_imgs_okucia}>
            <div>
              <img className={styles.entp_img} src={iGerda} alt="" />
            </div>
            <div>
              <img className={styles.entp_img} src={iSimon} alt="" />
            </div>
            <div>
              <img className={styles.entp_img} src={iKoblenz} alt="" />
            </div>
          </footer>
        ) : null}
        {window.innerWidth >= 651 ? (
          <div className={styles.entp_imgs_down_okucia}>
            <div>
              <img
                className={styles.entp_img_down_okucia}
                src={iOtlav}
                alt=""
              />
            </div>
            <div>
              <img
                className={styles.entp_img_down_okucia}
                src={iNovet}
                alt=""
              />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
});
