import React from "react";
// import iMoveToShop from "../../images/btnZrobZakupy.png";
import parImg from "../../images/newlineText.png";
import styles from "./CategoriesDetails.module.scss";
import { withRouter } from "react-router-dom";

export default withRouter(function NinthCategory(props) {
  return (
    <div>
      <div className={styles.text_box}>
        <h1 className={styles.text_box_title}>Krzesła</h1>
        <h2 className={styles.text_box_subtitle}>
          <div>
            Nasza oferta w zakresie sprzedaży krzeseł głównie skierowana jest do
            takich odbiorców jak:
          </div>
        </h2>

        <div className={styles.text_box_item}>
          <p>
            <img src={parImg} className={styles.text_img} alt="" />
            Restauracje
          </p>
          <p>
            <img src={parImg} className={styles.text_img} alt="" />
            Hotele
          </p>
          <p>
            <img src={parImg} className={styles.text_img} alt="" />
            Sale konferencyjne
          </p>
          <p>
            <img src={parImg} className={styles.text_img} alt="" />
            Sale katechetyczne
          </p>
          <p>
            <img src={parImg} className={styles.text_img} alt="" />
            Osoby prywatne
          </p>
        </div>
        <h3 className={styles.text_box_subtitle}>
          <div>
            W celu zapoznania się z naszą ofertą zapraszamy do naszego sklepu
            internetowego.
          </div>
        </h3>
        {props.shpButton}
        {/* <div className={styles.moveTo_sec}>
          <img
            className={styles.moveTo_img}
            src={iMoveToShop}
            onClick={() => nextPath("/shop")}
            alt="Move to Shop"
          ></img>
        </div> */}
      </div>
    </div>
  );
});
