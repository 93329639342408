import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { fetchProducts } from "../../../../services/shelf/actions";
import {
  updateFilters,
  removeFilter,
} from "../../../../services/filters/actions";
//import { propTypes } from 'react-bootstrap/esm/Image';
import "./style.scss";
import imgSearch from "./../../../../images/search.png";

class NameFilter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dimension: "productSearchName",
      productSearch: "",
    };
    this.getFilter = this.getFilter.bind(this);
    this.getDimVal = this.getDimVal.bind(this);
    this.changeDim = this.changeDim.bind(this);
    this.getDim = this.getDim.bind(this);
    this.changeProductSearch = this.changeProductSearch.bind(this);
  }

  static propTypes = {
    updateFilters: PropTypes.func.isRequired,
    filters: PropTypes.array.isRequired,
    fetchProducts: PropTypes.func.isRequired,
    sort: PropTypes.string,
    filterQuantity: PropTypes.number,
  };

  removeFilter = (objName) => {
    const { filters, updateFilters } = this.props;
    const index = filters.findIndex((f) => f[0] === objName);
    if (index >= 0) {
      filters.splice(index, 1);
      updateFilters(filters);
    }
  };

  handleFilter = (objName, filterVal, doFetch) => {
    const { filters } = this.props;
    const index = filters.findIndex((f) => f[0].includes("productSearch"));
    if (index >= 0) {
      filters.splice(index, 1);
      this.removeFilter(objName);
    }

    if (objName !== "" && filterVal !== "") {
      filters.push([objName, filterVal]);
      this.props.updateFilters(filters);
    }

    if (doFetch === true) {
      this.handleFetchProducts();
    }
  };

  componentDidMount() {
    var myVal = "productSearchName";
    var productSearch = this.props.filters.find(
      (f) =>
        f[0].includes("productSearchName") || f[0].includes("productSearchCode")
    );
    if (productSearch !== undefined) {
      myVal = productSearch[0];
    }
    this.setState({ dimension: myVal });
  }

  handleFetchProducts = (
    filters = this.props.filters,
    sort = this.props.sort,
    filterQuantity = this.props.filterQuantity
  ) => {
    this.setState({ isLoading: true });
    this.props.fetchProducts(
      filters,
      sort,
      () => {
        this.setState({ isLoading: false });
      },
      filterQuantity,
      1
    );
  };

  changeDim(val) {
    this.setState({ dimension: val });
  }

  changeProductSearch(val) {
    // console.log(val);
    this.setState({ productSearch: val });

    if (val === "" || val === undefined) {
      // console.log("remove");
      const { filters } = this.props;
      const index = filters.findIndex((f) => f[0].includes("productSearch"));
      if (index >= 0) {
        filters.splice(index, 1);
        this.removeFilter(this.state.dimension);
      }
      this.handleFetchProducts();
    }
  }

  getFilter(dimVal, dim) {
    return (
      <div className="quantity-filter">
        <select
          className="quantity-filter-select-name"
          defaultValue={dim}
          onChange={(event) => this.changeDim(event.target.value)}
        >
          <option value="productSearchName" key={1}>
            Nazwa produktu
          </option>
          <option value="productSearchCode" key={2}>
            Kod produktu
          </option>
        </select>
        <input
          className="quantity-filter-txt-box"
          onChange={(event) => this.changeProductSearch(event.target.value)}
          type="text"
          name="nameFilter"
          defaultValue={dimVal}
        />
        <img
          className="img-search"
          onClick={(event) =>
            this.handleFilter(
              this.state.dimension,
              this.state.productSearch,
              true
            )
          }
          src={imgSearch}
        />
      </div>
    );
  }

  getDim() {
    var myVal = "productSearchName";
    var productSearch = this.props.filters.find(
      (f) =>
        f[0].includes("productSearchName") || f[0].includes("productSearchCode")
    );
    if (productSearch !== undefined) {
      myVal = productSearch[0];
    }
    return myVal;
  }

  getDimVal() {
    var myVal = "";
    var productSearch = this.props.filters.find(
      (f) =>
        f[0].includes("productSearchName") || f[0].includes("productSearchCode")
    );
    if (productSearch !== undefined) {
      myVal = productSearch[1];
    }
    return myVal;
  }

  render() {
    var dimVal = this.getDimVal();
    var dimFil = this.getDim();
    return <div>{this.getFilter(dimVal, dimFil)}</div>;
  }
}

const mapStateToProps = (state) => ({
  filterQuantity: state.filterQuantity.type,
  products: state.shelf.products,
  filters: state.filterObj.filters,
  sort: state.sort.type,
});

export default connect(mapStateToProps, {
  updateFilters,
  removeFilter,
  fetchProducts,
})(NameFilter);
