import React, { Component } from "react";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";
import { connect } from "react-redux";
import {
  loadCart,
  removeProduct,
  changeProductQuantity,
} from "../../services/cart/actions";
import { updateCart } from "../../services/total/actions";
import CartProduct from "./CartProduct";
import { formatPrice } from "../../services/util";

import "./style.scss";
//karta
class FloatCart extends Component {
  static propTypes = {
    loadCart: PropTypes.func.isRequired,
    updateCart: PropTypes.func.isRequired,
    cartProducts: PropTypes.array.isRequired,
    newProduct: PropTypes.object,
    removeProduct: PropTypes.func,
    productToRemove: PropTypes.object,
    changeProductQuantity: PropTypes.func,
    productToChange: PropTypes.object,
  };

  state = {
    isOpen: false,
  };

  componentWillReceiveProps(nextProps) {
    //changed dla mariusza 20.11.17

    if (
      (nextProps.cartTotal.productQuantity > 0 ||
        nextProps.cartTotal.productQuantity !=
          this.props.cartTotal.productQuantity) &&
      this.state.isOpen != true
    ) {
      this.setState({ isOpen: true });
    }

    if (nextProps.newProduct !== this.props.newProduct) {
      this.addProduct(nextProps.newProduct);
    }

    if (nextProps.productToRemove !== this.props.productToRemove) {
      this.removeProduct(nextProps.productToRemove);
    }

    if (nextProps.productToChange !== this.props.productToChange) {
      this.changeProductQuantity(nextProps.productToChange);
    }
  }

  openFloatCart = () => {
    this.setState({ isOpen: true });
  };

  closeFloatCart = () => {
    this.setState({ isOpen: false });
  };

  addProduct = (product) => {
    const { cartProducts, updateCart } = this.props;
    let productAlreadyInCart = false;

    cartProducts.forEach((cp) => {
      if (cp.docID === product.docID) {
        cp.quantity += product.quantity;
        productAlreadyInCart = true;
      }
    });

    if (!productAlreadyInCart) {
      cartProducts.push(product);
    }

    updateCart(cartProducts);
    this.openFloatCart();
  };

  removeProduct = (product) => {
    const { cartProducts, updateCart } = this.props;

    const index = cartProducts.findIndex((p) => p.docID === product.docID);
    if (index >= 0) {
      cartProducts.splice(index, 1);
      updateCart(cartProducts);
    }
  };

  proceedToCheckout = (history) => {
    const { productQuantity } = this.props.cartTotal;

    if (!productQuantity) {
      alert("Twój koszyk jest pusty!");
    } else {
      history.push("/Sklep/Wysylka");
    }
  };

  changeProductQuantity = (changedProduct) => {
    const { cartProducts, updateCart } = this.props;

    const product = cartProducts.find((p) => p.docID === changedProduct.docID);
    product.quantity = changedProduct.quantity;
    if (product.quantity <= 0) {
      this.removeProduct(product);
    }
    updateCart(cartProducts);
  };

  render() {
    const {
      cartTotal,
      cartProducts,
      removeProduct,
      changeProductQuantity,
    } = this.props;

    const products = cartProducts.map((p) => {
      return (
        <CartProduct
          product={p}
          removeProduct={removeProduct}
          changeProductQuantity={changeProductQuantity}
          key={p.docID}
        />
      );
    });

    let classes = ["float-cart"];

    if (!!this.state.isOpen) {
      classes.push("float-cart--open");
    }

    return (
      <div className={classes.join(" ")}>
        {/* If cart open, show close (x) button */}
        {this.state.isOpen && (
          <div
            onClick={() => this.closeFloatCart()}
            className="float-cart__close-btn"
          >
            ukryj
            {/*X*/}
          </div>
        )}

        {/* If cart is closed, show bag with quantity of product and open cart action */}
        {!this.state.isOpen && (
          <span
            onClick={() => this.openFloatCart()}
            className="bag bag--float-cart-closed"
          >
            <span className="bag__quantity">{cartTotal.productQuantity}</span>
          </span>
        )}

        <div className="float-cart__content">
          <div className="float-cart__header">
            <span className="bag">
              <span className="bag__quantity">{cartTotal.productQuantity}</span>
            </span>
            <span className="header-title">Koszyk</span>
          </div>

          <div className="float-cart__shelf-container">
            {products}
            {!products.length && (
              <p className="shelf-empty">
                Dodaj produkty do koszyka <br />
                :)
              </p>
            )}
          </div>

          <div className="float-cart__footer">
            <div className="sub">Kwota do zapłaty:</div>
            <div className="sub-price">
              <p className="sub-price__val">
                {`${cartTotal.currencyFormat} ${formatPrice(
                  cartTotal.totalPrice,
                  cartTotal.currencyId
                )}`}
              </p>
              <small className="sub-price__installment">
                {!!cartTotal.installments && (
                  <span>
                    {`lub ${cartTotal.installments} x ${
                      cartTotal.currencyFormat
                    } ${formatPrice(
                      cartTotal.totalPrice / cartTotal.installments,
                      cartTotal.currencyId
                    )}`}
                  </span>
                )}
              </small>
            </div>
            {window.innerWidth > 650 ? (
              <Route
                render={({ history }) => (
                  <div
                    onClick={() => this.proceedToCheckout(history)}
                    className="buy-btn"
                  >
                    Przejdz do podsumowania
                  </div>
                )}
              />
            ) : (
              <Route
                render={({ history }) => (
                  <div
                    onClick={() => this.proceedToCheckout(history)}
                    className="buy-btn"
                  >
                    Przejdz do podsumowania
                  </div>
                )}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  cartProducts: state.cart.products,
  newProduct: state.cart.productToAdd,
  productToRemove: state.cart.productToRemove,
  productToChange: state.cart.productToChange,
  cartTotal: state.total.data,
});

export default connect(mapStateToProps, {
  loadCart,
  updateCart,
  removeProduct,
  changeProductQuantity,
})(FloatCart);
